




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ProgressBar extends Vue {
  @Prop({ default: true }) active: boolean
  @Prop({ default: false }) striped: boolean
  @Prop({ default: false }) indeterminate: boolean
  @Prop({ default: false }) showPercentageValue: boolean
  @Prop({ default: '4px' }) height: string
  @Prop({ default: '0' }) value: string

  get bgStyle() {
    if (this.indeterminate) {
      return { left: '0%', width: '100%' }
    }
    return { left: `${this.parsedValue}%`, width: `${100 - this.parsedValue}%` }
  }

  get formattedHeight() {
    return `${parseInt(this.height)}px`
  }

  get parsedValue() {
    return parseInt(this.value)
  }
}
