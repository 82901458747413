










































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import { IProposalV2 } from '@/shared/services/api.interface'
import { getModule } from 'vuex-module-decorators'
import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'

const agreementState = getModule(AgreementsV2)

@Component({
  components: {}
})
export default class AgreementSetup extends Vue {
  @Prop({ required: true }) agreement: IAgreementV2 | IProposalV2
  @Prop({ required: true }) category: string
  agreementState = agreementState
  errorLoading = false

  get createdAt() {
    return (this.agreement as IAgreementV2).created || this.agreement.revisions[0].createdAt
  }

  get allCategories() {
    return this.agreementState.allCategories
  }

  get serviceArea() {
    return this.agreement.currency === 'DKK' ? 'CPH' : 'LON'
  }

  get selectedCategory() {
    if (this.allCategories.length) {
      let category = this.allCategories.filter(category => category.slug === this.category)
      return category[0] && category[0].title
    } else return this.category
  }

  async mounted() {
    await this.loadCategories()
  }

  async loadCategories() {
    try {
      await this.agreementState.getCategories(this.serviceArea)
    } catch (e) {
      this.errorLoading = true
    }
  }
}
