

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelSmall from '@/shared_legacy/components/atoms/LabelSmall.vue'
import LabelField from '@/shared_legacy/components/atoms/LabelField.vue'
// @ts-ignore
import Switches from 'vue-switches'

@Component({
  components: { LabelSmall, LabelField, Switches }
})
export default class SwitchInputDynamic extends Vue {
  @Prop({ default: '' }) label: string
  @Prop({ default: '' }) description: string
  @Prop({ default: false }) value: boolean

  get enabled() {
    return this.value
  }

  set enabled(value: boolean) {
    this.$emit('input', value, this.label)
  }

  handleClick() {
    this.enabled = !this.enabled
  }
}
