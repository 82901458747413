import { IAgreementRevisionV2 } from '../../components/interfaces/agreements.interface'
import moment from 'moment'

/**
 * This is a model working over the IAgreementRevisionV2 interface
 */

export abstract class AgreementRevisionV2Model {
  public static startDate(revision: IAgreementRevisionV2): moment.Moment {
    return moment(revision.startDate!)
  }
}
