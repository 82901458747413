import API from '@/shared/billie/api'
import { IClientAccount } from '@/shared/components/interfaces/clients.interface'
import { IClientData } from '../../components/interfaces/clients.interface'

export default class Clients extends API {
    public async get(id: string): Promise<IClientAccount> {
        return this._get(`/api/clients/${id}`)
    }

    public async list(): Promise<IClientData[]> {
        return this._get('/api/clients')
    }
}
