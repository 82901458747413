










import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({ components: {} })
export default class ElementTotalPrice extends Vue {
  @Prop() title: string
  @Prop() price: number
  @Prop() currency: string
}
