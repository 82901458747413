






















































import { IOverdueClient } from '@/shared/components/interfaces/finance.interface'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'
//@ts-ignore

@Component({ components: {} })
export default class PaymentRemindersModal extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) selectedClient: IOverdueClient
  @Prop({ required: true }) sendReminderCallback: (n: number) => void
  @Prop({ required: true }) closeModalCallback: () => void

  notificationSections = [
    {
      image: '1stNotification.png',
      title: 'First reminder',
      description: 'A gentle reminder. We all forget things. No permissions change for the client.'
    },
    {
      image: '2ndNotification.png',
      title: 'Second reminder',
      description:
        "A stronger reminder warning the client that if they don't pay they will no longer be able to make requests or accept proposals."
    },
    {
      image: '3rdNotification.png',
      title: 'Third reminder',
      description:
        'The client no longer has the ability to make new requests or accept proposals until the invoice is paid. They will be reported to a debt collection agency if they do not pay.'
    }
    //   {
    //     image: '4thNotification.png',
    //     title: 'Final reminder',
    //     description:
    //       'Client has been reported to a debt collection agency and must contact customer care for more information.',
    //   },
  ]

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }

  get clientNotification() {
    if (this.selectedClient.paymentBalance.overdueNotifications) {
      this.selectedClient.paymentBalance.overdueNotifications.sort((a: any, b: any) => {
        //@ts-ignore
        return moment(b.date) - moment(a.date)
      })
    }
    return this.selectedClient.paymentBalance.overdueNotifications?.[0]?.level ?? 0
  }

  notificationTime(level: number) {
    const foundNotification = this.selectedClient.paymentBalance?.overdueNotifications?.find(notification => {
      return notification.level === level
    })
    return foundNotification?.date ?? ''
  }

  sendNotification(number: number) {
    this.sendReminderCallback(number)
  }

  resetNotifications() {
    this.sendReminderCallback(0)
  }
}
