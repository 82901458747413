import moment from 'moment'
import { Subscription, IClientData, IClientAccount, Currency } from '../components/interfaces/clients.interface'

export default abstract class ClientDetailsModel {
  public static currency(clientDetails: IClientData): Currency {
    switch (clientDetails.address.countryCode) {
      case 'UK':
        return 'GBP'
      case 'DK':
        return 'DKK'
      default:
        throw new Error('Cannot return currency as no country code was provided')
    }
  }

  public static isInTrialPeriod(clientDetails: IClientData): boolean {
    return clientDetails.trialExpirationDate != null && moment(clientDetails.trialExpirationDate).isAfter(moment())
  }

  public static subscriptionType(clientDetails: IClientData): Subscription {
    if (clientDetails.status === 'terminated') {
      return 'Terminated'
    }
    if (!clientDetails.platformFeeSubscriptionId) {
      return 'Free'
    } else if (moment().isBefore(moment(clientDetails.trialExpirationDate))) {
      return 'Trial'
    } else {
      return 'Paying'
    }
  }

  public static clientTypeBadge(clientDetails: IClientData): {
    text: string
    color: string
  } | null {
    if (!clientDetails?.id) {
      return null
    }
    let color = 'success'
    const status = ClientDetailsModel.subscriptionType(clientDetails)
    if (status === 'Free' || status === 'Terminated') {
      color = 'danger'
    } else if (status === 'Trial') {
      color = 'secondary'
    }
    return {
      text: status,
      color: color,
    }
  }

  public static statusType(status: string): Subscription {
    if (status === 'under_termination') {
      return 'Under termination'
    } else {
      return (status[0].toUpperCase() + status.substring(1).toLowerCase()) as Subscription
    }
  }

  public static clientStatusBadge(status: string): {
    text: string
    color: string
  } | null {
    if (!status) {
      return null
    }
    let color = 'success'
    const text = ClientDetailsModel.statusType(status)
    switch (status) {
      case 'free':
        color = 'warning'
        break
      case 'trial':
        color = 'primary'
        break
      case 'paying':
        color = 'success'
        break
      case 'terminated':
        color = 'secondary'
        break
      case 'under_termination':
        color = 'danger'
        break
    }
    return {
      text: text,
      color: color,
    }
  }

  public static mostRecentNotification(clientDetails: IClientAccount): number | null {
    if (clientDetails?.overdueNotifications.length > 0) {
      clientDetails.overdueNotifications.sort((a: any, b: any) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0))
      return clientDetails.overdueNotifications[0].level > 0 ? clientDetails.overdueNotifications[0].level : null
    }
    return null
  }

  public static paymentConditionType(clientDetails: IClientData): string {
    return clientDetails.paymentConditionForSubscription === 30 && clientDetails.paymentConditionForAdhoc === 14
      ? 'Standard'
      : 'Special'
  }
}
