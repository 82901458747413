






























































































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DocumentElementV2 from './element/DocumentElementV2.vue'
import uuid from 'uuid-random'
import { IAgreementElementV2 } from '@/shared/components/interfaces/agreements.interface'
import { IElement } from '@/shared/services/api.interface'
import RevisionElementMixin from '../../mixins/RevisionElementMixin'
import DeleteButton from '../agreement/element/DeleteButton.vue'
import AgreementAttachments from '@/components/agreement/AgreementAttachments.vue'

@Component({
  components: { DocumentElementV2, DeleteButton, AgreementAttachments },
  mixins: [RevisionElementMixin]
})
export default class DocumentWhatSection extends Vue {
  @Prop() description: string
  @Prop() prepaid: boolean
  @Prop() isProposal: boolean
  @Prop() countryCode: string
  @Prop() language: string
  @Prop() currency: string
  @Prop() startDate: string
  @Prop() agreementType: string
  @Prop() attachments: string[] | null
  @Prop() initialPattern: (IAgreementElementV2 | IElement)[]
  filteredElementsArray: (IAgreementElementV2 | IElement)[][]

  elements: string[] = []
  elementsMap: { [key: string]: IAgreementElementV2 | IElement } = {}
  availablePatterns: (IAgreementElementV2 | IElement)[] = []
  newElementId: string | null = null

  get elementKey() {
    return (element: IAgreementElementV2 | IElement) => {
      return (element as IAgreementElementV2).productId || (element as IElement).id
    }
  }

  get isNewAgreementOrProposal() {
    return this.$route.path.includes('/new')
  }

  shouldChoosePricing(element: IAgreementElementV2 | IElement) {
    if (this.agreementType === 'adhoc') return false
    if (element.pricing) return false
    if ((element as any).pricingType) return false
    return this.$route.params.type !== 'adhoc'
  }

  get shouldHaveCardClass() {
    const elements = this.filteredElementsArray.flat()
    return elements.every(e => this.newElementId !== this.elementKey(e) || !this.shouldChoosePricing(e))
  }

  setPricingType(type: string) {
    const element = this.availablePatterns.find((p: any) => p.productId === this.newElementId)
    if (element) {
      element.pricing = type
      this.updateElement(element)
    }
  }

  get shouldShowDefaultAddButton() {
    if (
      !this.availablePatterns.length ||
      this.agreementType === 'adhoc' ||
      (this.filteredElementsArray.length &&
        !(this.filteredElementsArray[0].length && this.filteredElementsArray[1].length) &&
        !this.filteredElementsArray[2].length)
    ) {
      return true
    }
    return false
  }

  get shouldShowPricingAddButton() {
    return this.filteredElementsArray[0].length && this.filteredElementsArray[1].length
  }

  elementLabel(index: number) {
    switch (index) {
      case 0:
        return 'Per delivery'
      case 1:
        return 'Per month'
      default:
        return 'One time purchase'
    }
  }

  addFile(file: string) {
    this.$emit('addFile', file)
  }

  removeFile(index: number) {
    this.$emit('removeFile', index)
  }

  updateDescription(e: any) {
    this.$emit('changeDescription', e.target.value)
  }

  updateElement(element: IAgreementElementV2 | IElement) {
    const key = ((element as IAgreementElementV2).productId || (element as IElement).id) as string
    this.elementsMap[key] = element
    this.updatePrepaymentPatterns()
  }

  updatePrepaymentPatterns() {
    let prepaymentPatterns = []
    for (var id in this.elementsMap) {
      prepaymentPatterns.push(this.elementsMap[id])
    }
    this.availablePatterns = prepaymentPatterns
    this.$emit('updatePrepaymentPatterns', prepaymentPatterns)
  }

  addProduct() {
    const id = uuid()
    this.elements.push(id)
  }

  addProductV2(pricing: string) {
    const id = uuid()
    this.elements.push(id)
    const newProduct: IAgreementElementV2 = {
      clientPrice: 1,
      description: '',
      pricing: pricing ? pricing : null,
      productId: id,
      quantity: 1,
      schedule: '',
      supplierPrice: 1,
      unit: '',
      vatPercentage: -1
    }
    this.newElementId = id
    this.elementsMap[id] = newProduct
    this.availablePatterns.push(newProduct)
  }

  removeElement(id: string) {
    const index = this.availablePatterns.findIndex((p: any) => p.productId === id)
    if (index >= 0) this.availablePatterns.splice(index, 1)
    const newArr = this.elements.filter(e => e !== id)
    this.elements = newArr
    delete this.elementsMap[id]
    this.updatePrepaymentPatterns()
  }

  created() {
    if (this.initialPattern) {
      this.availablePatterns = JSON.parse(JSON.stringify(this.initialPattern))
      this.populatePattern()
    }
  }

  populatePattern() {
    for (let element of this.initialPattern) {
      const key = ((element as IAgreementElementV2).productId || (element as IElement).id) as string
      this.elements.push(key)
      this.elementsMap[key] = element
    }
    this.updatePrepaymentPatterns()
  }
}
