































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import {
  IAgreementElement,
  IAgreementElementV2,
  IAgreementRevision,
  IAgreementRevisionV2,
  IOfferingCategory
} from '@/shared/components/interfaces/agreements.interface'
import DocumentWhatSection from './DocumentWhatSection.vue'
import DocumentWhenSection from './DocumentWhenSection.vue'
import uuid from 'uuid-random'
import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'
import Multiselect from 'vue-multiselect'
import ConfirmationModal from '../common/ConfirmationModal.vue'
import RevisionElementMixin from '@/mixins/RevisionElementMixin'
type Nullable<T> = T | null

const agreementState = getModule(AgreementsV2)
@Component({
  components: {
    DocumentWhatSection,
    DocumentWhenSection,
    Multiselect,
    ConfirmationModal
  },
  mixins: [RevisionElementMixin]
})
export default class UpdateDocument extends Vue {
  @Prop() language: string
  @Prop() country: string
  @Prop() agreementType: string
  @Prop() editMode: boolean
  @Prop() isProposal: boolean
  @Prop({ default: false }) isV1: boolean
  @Prop() totalRevisions: number
  @Prop() prepaid: boolean
  @Prop() revision: IAgreementRevisionV2 | IAgreementRevision

  revisionId = ''
  docTitle = ''
  description = ''
  currency = ''
  invoiceSchedule = ''
  invoiceScheduleSelected = false
  isPrepaid = false
  startDate: Nullable<string> = null
  categoryId = ''
  attachments: string[] | null = null
  prepaymentPatterns: Nullable<IAgreementElementV2[]> | IAgreementElement[] = []
  agreementState = agreementState
  loadingCategories = true
  errorLoadingCategories = false
  deleteRevision = false
  showModal = false
  validateRevisionElementFrequency: () => { valid: boolean; errors: string[] }[]

  get clientId() {
    return this.$route.params.id
  }

  get docType() {
    return this.agreementTypeFromSchedule === 'subscription' ? 'Subscription' : 'Ad hoc'
  }

  get agreementTypeFromSchedule() {
    return this.invoiceSchedule === 'once' ? 'adhoc' : 'subscription'
  }

  get allCategories() {
    return this.agreementState.allCategories
  }

  get filteredCategories() {
    return this.agreementState.allCategories.filter((category: IOfferingCategory) =>
      this.agreementType === 'subscription' ? category.enableSubscriptions : category.enableAdhocs
    )
  }

  get category() {
    return this.allCategories.filter((category: IOfferingCategory) => category.slug === this.categoryId)
  }

  get serviceArea() {
    return this.country === 'DK' ? 'CPH' : 'LON'
  }

  handleModalCancel() {
    this.showModal = false
  }

  get modalText() {
    let text = '<p>Are you sure you want to delete this revision?</p>'
    if (this.totalRevisions === 1) {
      const context = this.isProposal ? 'proposal' : 'agreement'
      text += `<p>Deleting will also terminate this ${context} as this is the only revision within the ${context}</p>`
    }
    return text
  }

  updateCategory(category: IOfferingCategory) {
    this.categoryId = category.slug
  }

  created() {
    this.currency = this.getCurrencyFromCountry()
    this.invoiceSchedule = this.getInvoiceSchedule()
    this.isPrepaid = this.prepaid
    this.populateWithExistingData()
  }
  async mounted() {
    try {
      await this.agreementState.getCategories(this.serviceArea)
      this.loadingCategories = false
    } catch (e) {
      this.loadingCategories = false
      this.errorLoadingCategories = true
    }
  }

  populateWithExistingData() {
    this.docTitle = this.revision.title
    this.description = this.revision.description
    if ((this.revision as IAgreementRevisionV2).attachments) {
      this.attachments = [...(this.revision as IAgreementRevisionV2).attachments!]
    }
    this.prepaymentPatterns = (this.revision as IAgreementRevisionV2).prepaymentPatterns
    this.categoryId = this.revision.category as string
    if (this.editMode)
      this.revisionId = this.isProposal ? (this.revision as IAgreementRevision).id : (this.revision as IAgreementRevisionV2).revisionId
    else this.revisionId = uuid()
    if (this.invoiceSchedule === 'monthly') {
      this.startDate = this.revision.startDate
    }
  }

  getCurrencyFromCountry() {
    if (this.country === 'UK') return 'GBP'
    else return 'DKK'
  }

  getInvoiceSchedule() {
    if (this.agreementType === 'subscription') return 'monthly'
    return 'once'
  }

  async setInvoiceSchedule(type: string) {
    this.invoiceSchedule = type
    this.invoiceScheduleSelected = true
    try {
      await this.agreementState.getCategories(this.serviceArea)
      this.loadingCategories = false
    } catch (e) {
      this.loadingCategories = false
      this.errorLoadingCategories = true
    }
  }

  changeDescription(description: string) {
    this.description = description
  }

  updateStartDate(date: string) {
    this.startDate = date
  }

  updatePrepaymentPatterns(pattern: IAgreementElementV2[]) {
    this.prepaymentPatterns = pattern
  }

  handleRevisionDeletion() {
    this.deleteRevision = true
    this.save()
  }

  addFile(file: string) {
    this.attachments ? this.attachments.push(file) : (this.attachments = [file])
  }

  removeFile(index: number) {
    if (this.attachments) this.attachments.splice(index, 1)
  }

  async save() {
    const validatedResults = await this.validateRevisionElementFrequency()
    if (validatedResults.every((value) => value.valid)) {
      const payload = this.generatePayload()
      if (this.isProposal) {
        this.$emit('saveProposal', payload)
      } else {
        this.$emit('save', this.$route.params.agreementId, payload.revisionId, payload)
      }
    } else {
      this.$toasted.error('Please fill in the required fields', { icon: 'ion ion-ios-warning' })
    }
  }

  cancel() {
    this.$emit('cancel')
  }

  generatePayload() {
    let payload: any = {
      title: this.docTitle,
      description: this.description,
      category: this.categoryId,
      startDate: null,
      attachments: this.attachments,
      prepaymentPatterns: null,
      revisionId: this.revisionId,
      invoiceSchedule: this.invoiceSchedule,
      prepaid: this.isPrepaid
    }

    if (this.deleteRevision) {
      payload.deleted = true
    }
    if (this.prepaymentPatterns && JSON.parse(JSON.stringify(this.prepaymentPatterns))) {
      payload.prepaymentPatterns = JSON.parse(JSON.stringify(this.prepaymentPatterns))
    }
    if (payload.prepaymentPatterns) {
      for (var element of payload.prepaymentPatterns) {
        if (this.isProposal) {
          element.pricingType = element.pricingType || element.pricing
          if (element.pricing) delete element.pricing
          if (this.isPrepaid && this.invoiceSchedule == 'once') {
            element.pricingType = null
          }
        }
      }
    }
    if (this.invoiceSchedule === 'monthly') {
      payload.startDate = this.startDate
    }

    return payload
  }
}
