




import ErrorToast from '@/shared/storeModules/errorToast'
import { Vue, Component } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

const toastError = getModule(ErrorToast)
@Component({
  components: {},
})
export default class ToastError extends Vue {
  toastErrorState = toastError
  mounted() {
    let text = this.toastErrorState.errMessage
    this.$toasted.error(text)
  }
}
