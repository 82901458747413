import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import moment from 'moment'
class StatusBadgeHelper {
  public static statusMessages = {
    planned: 'Planned',
    active: 'Active',
    underTermination: 'Under termination',
    terminated: 'Terminated',
    cancelled: 'Cancelled',
    invoiced: 'Invoiced'
  }
  public static mapAgreementsWithStatus(agreements: IAgreementV2[]) {
    return agreements.map((agreement) => {
      const item = { ...agreement }
      item.created = moment(agreement.created, moment.ISO_8601).format('ll')
      if (agreement.invoiceSchedule === 'once') {
        item.status = this.adhocAgreementStatus(item)
        return item
      } else {
        item.status = this.subscriptionAgreementStatus(item)
        return item
      }
    })
  }
  public static adhocAgreementStatus(agreement: IAgreementV2) {
    if (agreement.agreementEnd && this.agreementHasEnded(agreement)) {
      return this.statusMessages.cancelled
    }
    if (this.isAdHocTerminated(agreement)) {
      return this.statusMessages.cancelled
    }
    if (this.agreementHasAlreadyStarted(agreement)) return this.statusMessages.active
    if (agreement.invoices.length > 0) {
      return this.statusMessages.invoiced
    }
    return this.statusMessages.planned
  }

  public static isAdHocTerminated(agreement: IAgreementV2) {
    if (agreement.activeRevisionId && agreement.revisions) {
      const activeRevision = agreement.revisions.find((r) => r.revisionId === agreement.activeRevisionId)
      return activeRevision && activeRevision.type === 'termination'
    }
    return agreement.revisions?.[0]?.type === 'termination'
  }

  //
  public static subscriptionAgreementStatus(agreement: IAgreementV2) {
    if (agreement.agreementEnd) {
      if (this.underTermination(agreement)) {
        return this.statusMessages.underTermination
      }
      return this.statusMessages.terminated
    }
    if (this.agreementHasAlreadyStarted(agreement)) {
      return this.statusMessages.active
    }
    return this.statusMessages.planned
  }
  //
  public static underTermination(agreement: IAgreementV2): boolean {
    return moment().isBefore(moment(agreement.agreementEnd!), 'days')
  }
  public static agreementHasEnded(agreement: IAgreementV2) {
    return moment(agreement.agreementEnd!).isBefore(moment(), 'days')
  }
  public static agreementHasAlreadyStarted(agreement: IAgreementV2): boolean {
    return moment(agreement.agreementStart).isBefore(moment().add(1, 'days'), 'days')
  }

  public static getStatusBadgeColor(agreementStatus: string) {
    switch (agreementStatus) {
      case 'Cancelled':
      case 'Terminated':
      case 'Not ready':
        return 'danger'
      case 'Active':
      case 'Completed':
      case 'Ready for invoicing':
        return 'success'
      case 'Under termination':
        return 'secondary'
      case 'Planned':
        return 'primary'
      default:
        return 'success'
    }
  }
}

// Export a singleton instance in the global namespace
export default StatusBadgeHelper
