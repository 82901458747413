




















































































import { Sidenav, SidenavRouterLink } from '@/vendor/libs/sidenav'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LayoutHelper from './helpers'
import FeatureFlag from '@/shared_legacy/components/FeatureFlag.vue'

@Component({
  components: {
    Sidenav,
    SidenavRouterLink,
    FeatureFlag
  }
})
export default class AppLayoutSidenav extends Vue {
  @Prop({ default: 'vertical' }) orientation: string
  layoutHelpers = LayoutHelper

  get curClasses() {
    //@ts-ignore
    let bg = this.layoutSidenavBg

    if (
      this.orientation === 'horizontal' &&
      (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)
    ) {
      bg = bg.replace(' sidenav-dark', '').replace(' sidenav-light', '').replace('-darker', '').replace('-dark', '')
    }

    return (
      `bg-${bg} ` +
      (this.orientation !== 'horizontal' ? 'layout-sidenav' : 'layout-sidenav-horizontal container-p-x flex-grow-0')
    )
  }

  isMenuActive(url: string) {
    return this.$route.path.indexOf(url) === 0
  }

  isMenuOpen(url: string) {
    return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
  }

  toggleSidenav() {
    this.layoutHelpers.toggleCollapsed()
  }
}
