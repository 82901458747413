import axios from './interceptor'
import { IInvoice, IInvoiceApproval } from '@/shared/components/interfaces/invoices.interface'

class InvoiceApi {
  static async getAllInvoices(): Promise<IInvoiceApproval[]> {
    return axios.get(`/api/invoices/approval`).then((resp) => resp.data)
  }

  static async approveInvoice(invoiceNumber: string, country: string): Promise<IInvoiceApproval> {
    return axios.post(`/api/invoices/${country}/${invoiceNumber}/approve`).then((resp) => resp.data)
  }
  static async undiscardInvoice(invoiceNumber: string, country: string): Promise<IInvoiceApproval> {
    return axios.post(`/api/invoices/${country}/${invoiceNumber}/undiscard`).then((resp) => resp.data)
  }
  static async unapproveInvoice(invoiceNumber: string, country: string): Promise<IInvoiceApproval> {
    return axios.post(`/api/invoices/${country}/${invoiceNumber}/unapprove`).then((resp) => resp.data)
  }
  static async rejectInvoice(invoiceNumber: string, country: string): Promise<IInvoiceApproval> {
    return axios.post(`/api/invoices/${country}/${invoiceNumber}/reject`).then((resp) => resp.data)
  }
  static async getInvoices(clientId: string, includeTentative = false): Promise<IInvoice[]> {
    return axios.get(`/api/clients/${clientId}/invoices`, { params: { includeTentative } }).then((resp) => resp.data)
  }

  static async getInvoicePdf(invoiceId: string): Promise<any> {
    return axios
      .get(`/api/invoices/view/${invoiceId}`, {
        responseType: 'blob',
        transformRequest: [
          (data, headers) => {
            headers.accept = 'application/pdf'
            return data
          }
        ]
      })
      .then((resp) => resp.data)
  }

  static async sendApprovedInvoices(country: string): Promise<void> {
    return axios.post(`/api/invoices/${country}/send`).then((resp) => resp.data)
  }

  static async generateCreditNote(country: string, invoiceNo: string): Promise<IInvoice> {
    return axios.post(`api/invoices/${country}/credit_note/${invoiceNo}`)
  }

  static async generateAdhoc(country: string): Promise<void> {
    return axios.post(`api/invoices/${country}/run-adhoc-invoices`)
  }

  static async generateMonthlyAdhoc(country: string): Promise<void> {
    return axios.post(`api/invoices/${country}/run-adhoc-monthly-invoices`)
  }

  static async generateFixedSubsInvoices(country: string): Promise<void> {
    return axios.post(`api/invoices/${country}/run-fixed-invoices`)
  }

  static async generateConsumptionInvoices(country: string): Promise<void> {
    return axios.post(`api/invoices/${country}/run-consumption-invoices`)
  }
}
export default InvoiceApi
