


















































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import auth from '../shared/storeModules/auth'
import jwt from 'jsonwebtoken'

const authstate = getModule(auth)

@Component({
  metaInfo: {
    title: 'Login | Good Monday',
  },
})
export default class LoginPage extends Vue {
  loading = false
  email = ''
  password = ''
  rememberMe = false
  authstate = authstate
  $axios: { defaults: { headers: { common: { [key: string]: string } } } }

  get errorMessage() {
    return (this.$route.query.error as string) || ''
  }

  mounted() {
    if (localStorage.rememberMe) {
      this.rememberMe = true
      localStorage.email && (this.email = localStorage.email)
      const passwordEl = this.$refs.password as HTMLElement
      passwordEl.focus()
    } else {
      const emailEl = this.$refs.email as HTMLElement
      emailEl.focus()
    }
  }

  login() {
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', 'true')
      this.email !== '' && localStorage.setItem('email', this.email)
    } else {
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('email')
    }
    this.loading = true
    this.authstate
      .authenticate({ email: this.email.trim(), password: this.password, strategy: 'local' })
      .then((token) => {
        if (token) {
          const tokenDecoded = jwt.decode(token) as { [key: string]: any }
          if (tokenDecoded?.gm_role !== 'superadmin') {
            localStorage.clear()
            const url = `/login?${
              this.$route.query.redirect ? 'redirect=' + this.$route.query.redirect + '&' : ''
            }error=unauthorized`
            this.$router.replace(url)
            window.location.reload(false)
          } else {
            window.location.href = (this.$route.query.redirect as string) || '/'
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
