


















import { Vue, Component, Prop } from 'vue-property-decorator'
import ImagePreview from '@/components/ImagePreview.vue'

@Component({
  components: { ImagePreview }
})
export default class AttachmentItem extends Vue {
  @Prop({ required: true }) src: string
  @Prop({ required: true }) isEditMode: boolean
  @Prop({ required: true }) index: number
  @Prop({ default: true }) canPreview: boolean

  showModal = false

  openModal() {
    this.showModal = true
  }

  closeModal() {
    this.showModal = false
  }

  get fileToken() {
    return localStorage.getItem('backoffice_file_token')
  }

  get fileExtension() {
    return this.src.split('.')[this.src.split('.').length - 1].toUpperCase()
  }

  get isImage() {
    return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic'].includes(this.fileExtension.toLowerCase())
  }

  removeFile(index: number) {
    this.$emit('removeFile', index)
  }
}
