





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class NotReadyForInvoiceModal extends Vue {
  @Prop({ required: true }) showModal: boolean
  @Prop() clientId: string
  @Prop() errorMessage: string

  toClientDetailsPage() {
    this.$router.push({ path: `/clients/${this.clientId}`, query: { clientDetails: 'exp' } })
  }
}
