















import ShortcutStore, { IShortcutElement } from '@/store/modules/shortcutStore'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
const shortcutState = getModule(ShortcutStore)
@Component({
  components: {}
})
export default class ShortcutOverlay extends Vue {
  shortcutState = shortcutState
  created() {
    window.addEventListener('keydown', this.keydownListener)
    window.addEventListener('keyup', this.keyUpListener)
    this.prioritiseVisible()
  }

  makeUpperCase(trigger?: string): string {
    return trigger ? trigger.toUpperCase() : ''
  }

  keydownListener(e: any) {
    if (shortcutState.usedShortcuts.indexOf(e.key) !== -1 && shortcutState.isVisible) {
      e.preventDefault()
    }
  }

  keyUpListener(e: any) {
    if (shortcutState.usedShortcuts.indexOf(e.key) !== -1) {
      e.preventDefault()
      this.triggerShorcut(e.key)
    }
  }

  prioritiseVisible() {
    const visibleElements: IShortcutElement[] = []
    const hiddenElements: IShortcutElement[] = []
    for (const el of shortcutState.elements) {
      if (this.isVisible(el.element) || el.originalKey !== 'any') {
        visibleElements.push(el)
      } else {
        hiddenElements.push(el)
      }
    }

    for (const visible of visibleElements) {
      if (!visible.trigger && hiddenElements.length) {
        shortcutState.switchShortcuts({ from: hiddenElements[0], to: visible })
        hiddenElements.splice(0, 1)
      }
    }
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownListener)
    window.removeEventListener('keyup', this.keyUpListener)
  }
  isElementInViewport(el: HTMLElement) {
    var rect = el.getBoundingClientRect()

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    )
  }

  triggerShorcut(letter: string) {
    for (const element of this.shortcutState.elements) {
      if (letter === element.trigger) {
        if (element.elementType === 'input') {
          this.executeInputTrigger(element)
        } else if (element.elementType === 'button') {
          this.executeButtonTrigger(element)
        } else if (element.elementType === 'select') {
          this.executeSelectTrigger(element)
        } else if (element.elementType === 'routerLink') {
          this.executeRouterTrigger(element)
        }
      }
    }
  }

  executeInputTrigger(element: IShortcutElement) {
    //   element.element.show()
    this.shortcutState.closeOverlay()
    element.element.focus()
  }

  executeSelectTrigger(element: IShortcutElement) {
    this.shortcutState.closeOverlay()
    element.element.focus()
  }
  executeRouterTrigger(element: IShortcutElement) {
    this.shortcutState.closeOverlay()
    if (element.element.children) {
      //@ts-ignore
      element.element.children[0].click()
    }
  }

  executeButtonTrigger(element: IShortcutElement) {
    this.shortcutState.closeOverlay()
    setTimeout(() => {
      if (document.activeElement) {
        //@ts-ignore
        document.activeElement.blur()
      }
      element.element.click()
    }, 0)
  }

  calculateBubbleTopOffset(el: HTMLElement) {
    var rect = el.getBoundingClientRect()
    return rect.top
  }

  calculateBubbleleftOffset(el: HTMLElement) {
    var rect = el.getBoundingClientRect()
    return rect.left
  }

  isVisibleWithinScroll(_element: IShortcutElement) {
    return true
  }

  isVisible(element: HTMLElement) {
    return this.isElementInViewport(element)
  }
}
