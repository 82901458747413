import {Component, Vue} from 'vue-property-decorator';
import { IAgreementElementV2 } from '../shared/components/interfaces/agreements.interface';
import { IElement } from '../shared/services/api.interface';

@Component
export default class RevisionElementMixin extends Vue {
  availablePatterns: (IAgreementElementV2 | IElement)[]

  get filteredElements() {
    if (!this.availablePatterns) return null
    return this.availablePatterns.reduce(
      (acc, pattern: any) => {
        let key = 'adhoc'
        if (
          (pattern.pricing || pattern.pricingType) &&
          ['per_delivery', 'per_month'].includes(pattern.pricing || pattern.pricingType)
        ) key = pattern.pricing || pattern.pricingType
        if (acc[key]) acc[key].push(pattern)
        else acc[key] = [pattern]
        
        return acc
      },
      { per_delivery: [], per_month: [], adhoc: [] } as { [key: string]: (IAgreementElementV2 | IElement)[] }
    )
  }
  
  get filteredElementsArray() {
    const elements = []
    if (this.filteredElements) {
      elements.push(this.filteredElements.per_delivery)
      elements.push(this.filteredElements.per_month)
      elements.push(this.filteredElements.adhoc)
    }
    return elements
  }

  async validateFrequencyDays(element: any) {
    if (!element.$refs.documentRow.$refs.documentFrequency?.$refs.frequencyDays) return { valid: true }
    return element.$refs.documentRow.$refs.documentFrequency.$refs.frequencyDays.validate()
  }

  async validateInterval(element: any) {
    if (
      !element.$refs.documentRow.$refs.documentFrequency ||
      element.$refs.documentRow.$refs.documentFrequency.$refs.interval.value
    ) return { valid: true }
    return { valid: false }
  }

  async validateRevisionElementFrequency() {
    const elementLength = (this.$refs.whatSection as any).$refs.documentElement?.length ?? 0
    if (elementLength === 0) return [{ valid: true }]

    const promises = []
    for (let i = 0; i < elementLength; i++) {
      promises.push(
        this.validateInterval((this.$refs.whatSection as any).$refs.documentElement[i]),
        this.validateFrequencyDays((this.$refs.whatSection as any).$refs.documentElement[i])
      )
    }
    return  Promise.all(promises)
  }
}