

























import { Vue, Prop, Component } from 'vue-property-decorator'
import PlaceholderShimmer from '@/shared_legacy/components/molecules/PlaceholderShimmer.vue'

@Component({
  components: { PlaceholderShimmer }
})
export default class PreviewImage extends Vue {
  @Prop() file: File
  @Prop() uploadInProgress: boolean
  @Prop() isImage: boolean
  @Prop() previewUrl: string
  @Prop() removeFile: () => void
}
