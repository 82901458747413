

































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Country from '@/shared_legacy/storeModules/country'
import InvoiceApi from '@/shared/services/api/invoiceApi'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import { IInvoiceApproval, IInvoiceItem } from '@/shared/components/interfaces/invoices.interface'
import InvoiceItems from '@/components/invoices/InvoiceItems.vue'
import uuid from 'uuid-random'
import moment from 'moment'
import ConfirmationModal from '../components/common/ConfirmationModal.vue'

const countryState = getModule(Country)

interface InvoiceRow {
  'invoice_#': number
  invoiceDate: string
  client: {
    id: string
    name: string
  }
  status: string
  invoiceTitle: string
  amountDue: string
  items: IInvoiceItem[]
}

enum InvoiceStatus {
  UnderVerification = 'underVerification',
  Accepted = 'accepted',
  Discarded = 'discarded'
}
@Component({ components: { InvoiceItems, ConfirmationModal } })
export default class Invoices extends Vue {
  countryState = countryState
  filterBy = ''
  allInvoices: IInvoiceApproval[] = []
  allClients: IClientData[]
  clientsMap: { [key: string]: IClientData } = {}
  fetchingInvoices = true
  errorFetchingInvoices = false
  loading = false
  sending = false
  invoiceLoading = 0
  tableKey = uuid()
  selectedTab: InvoiceStatus = InvoiceStatus.UnderVerification
  showSendInvoicesModal = false
  generatingAdhoc = false
  generatingMonthlyAdhoc = false
  generatingFixedSubs = false
  generatingConsumptionSubs = false
  generatingInvoices = false

  defaultFields = [
    { key: 'show_details', sortable: false },
    { key: 'invoice_#', sortable: true },
    { key: 'invoiceDate', sortable: true },
    { key: 'client', sortable: true },
    { key: 'invoiceTitle', sortable: true },
    { key: 'amountDue', sortable: true },
    { key: 'actions' }
  ]

  get fields() {
    return this.defaultFields
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }

  updateTable() {
    this.tableKey = uuid()
  }

  async reverseInvoiceStatus(invoiceRow: any, actionType: 'approve' | 'discard') {
    this.invoiceLoading = invoiceRow['invoice_#']
    this.loading = true
    try {
      const country = invoiceRow.items[0].currency === 'GBP' ? 'UK' : 'DK'
      let resp =
        actionType === 'approve'
          ? await InvoiceApi.unapproveInvoice(invoiceRow['invoice_#'], country)
          : await InvoiceApi.undiscardInvoice(invoiceRow['invoice_#'], country)
      this.allInvoices = this.updateInvoicesStatus(invoiceRow['invoice_#'], resp, InvoiceStatus.UnderVerification)
      this.loading = false
      this.updateTable()
      this.$toasted.success(actionType === 'approve' ? 'Invoice unapproved' : 'Invoice undiscarded')
    } catch (e) {
      console.log(e)
      this.loading = false
      this.$toasted.error(actionType === 'approve' ? 'Error unapproving Invoice' : 'Error undiscarding Invoice', {
        icon: 'ion ion-ios-warning'
      })
    }
  }

  removeInvoiceFromTable(invoiceNumber: number) {
    return this.allInvoices.filter((invoice: IInvoiceApproval) => invoice.invoiceNumber !== invoiceNumber)
  }

  updateInvoicesStatus(invoiceNumber: number, _resp: IInvoiceApproval, status: InvoiceStatus) {
    let allInvoicesCopy: IInvoiceApproval[] = [...this.allInvoices]
    for (var invoice of allInvoicesCopy) {
      if (invoice.invoiceNumber === invoiceNumber) {
        invoice.verificationStatus = status
      }
    }
    return allInvoicesCopy
  }

  async changeInvoiceStatus(invoiceRow: any, action: 'approve' | 'discard') {
    this.invoiceLoading = invoiceRow['invoice_#']
    this.loading = true
    try {
      const country = invoiceRow.items[0].currency === 'GBP' ? 'UK' : 'DK'
      let resp =
        action === 'approve'
          ? await InvoiceApi.approveInvoice(invoiceRow['invoice_#'], country)
          : await InvoiceApi.rejectInvoice(invoiceRow['invoice_#'], country)
      this.allInvoices = this.updateInvoicesStatus(
        invoiceRow['invoice_#'],
        resp,
        action === 'approve' ? InvoiceStatus.Accepted : InvoiceStatus.Discarded
      )
      this.loading = false
      this.updateTable()
      this.$toasted.success(action === 'approve' ? 'Invoice Approved' : 'Invoice discarded')
    } catch (e) {
      this.loading = false
      this.$toasted.error(action === 'approve' ? 'Error approving Invoice' : 'Error discarding Invoice', {
        icon: 'ion ion-ios-warning'
      })
    }
  }

  async generateInvoices(invoiceType: string) {
    try {
      switch (invoiceType) {
        case 'adHoc':
          this.generatingAdhoc = true
          await InvoiceApi.generateAdhoc(this.selectedCountry ? this.selectedCountry : 'all')
          this.generatingAdhoc = false
          break
        case 'monthlyAdHoc':
          this.generatingMonthlyAdhoc = true
          await InvoiceApi.generateMonthlyAdhoc('DK')
          this.generatingMonthlyAdhoc = false
          break
        case 'fixedSubs':
          this.generatingFixedSubs = true
          await InvoiceApi.generateFixedSubsInvoices(this.selectedCountry ? this.selectedCountry : 'all')
          this.generatingFixedSubs = false
          break
        case 'consumptionSubs':
          this.generatingConsumptionSubs = true
          await InvoiceApi.generateConsumptionInvoices(this.selectedCountry ? this.selectedCountry : 'all')
          this.generatingConsumptionSubs = false
          break
        default:
          break
      }
    } catch (e) {
      this.generatingAdhoc = false
      this.generatingFixedSubs = false
      this.generatingConsumptionSubs = false
      this.$toasted.error('Error generating invoices', { icon: 'ion ion-ios-warning' })
    }
  }

  async sendApprovedInvoices() {
    this.showSendInvoicesModal = false
    this.sending = true
    try {
      await InvoiceApi.sendApprovedInvoices(this.selectedCountry ? this.selectedCountry : 'all')
    } catch (e) {
      this.sending = false
      this.$toasted.error('Error sending invoices', { icon: 'ion ion-ios-warning' })
    }
  }

  get modalText() {
    if (this.filteredInvoices.length === 1) {
      return '<p>Are you sure you want to send 1 invoice?</p>'
    }
    return `<p>Are you sure you want to send ${this.filteredInvoices.length} invoices?</p>`
  }

  handleModalCancel() {
    this.showSendInvoicesModal = false
  }

  get invoices() {
    return this.allInvoices.map((invoice: IInvoiceApproval) => {
      let row = {
        'invoice_#': invoice.invoiceNumber,
        invoiceDate: moment(invoice.date).utc().format('DD/MM/YYYY'),
        client: { name: invoice.clientName, id: invoice.clientId },
        invoiceTitle: invoice.details,
        amountDue: this.totalAmount(invoice),
        items: invoice.items,
        status: invoice.verificationStatus,
        _showDetails: invoice.verificationStatus !== InvoiceStatus.Accepted,
        _rowVariant: ''
      }

      if (invoice.verificationStatus !== InvoiceStatus.UnderVerification) {
        row['_rowVariant'] = invoice.verificationStatus === InvoiceStatus.Accepted ? 'success' : 'danger'
      }
      return row
    })
  }

  get filteredInvoices() {
    const filteredByClientOrTitle = this.filterByName(this.invoices)
    const filteredByCountry = this.filterByCountry(filteredByClientOrTitle)
    const filteredByStatus = filteredByCountry.filter((item) => {
      return item.status === this.selectedTab
    })
    if (filteredByStatus.length === 0 && this.selectedTab === InvoiceStatus.Accepted) {
      this.sending = false
    }
    const sortedByStatus = this.sortInvoices(filteredByStatus)
    return sortedByStatus
  }

  sortInvoices(invoices: InvoiceRow[]): InvoiceRow[] {
    let discarded: InvoiceRow[] = []
    let defaultInvoices: InvoiceRow[] = []
    invoices.map((invoice: InvoiceRow) => {
      if (invoice.status === 'discarded') {
        discarded.push(invoice)
      } else {
        defaultInvoices.push(invoice)
      }
    })
    return defaultInvoices.concat(discarded)
  }

  filterByName(invoices: InvoiceRow[]) {
    let filteredByName = []
    if (this.filterBy === '') {
      return invoices
    }
    for (const invoice of invoices) {
      if (invoice.client.name.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(invoice)
      } else if (invoice.invoiceTitle.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(invoice)
      }
    }
    return filteredByName
  }

  filterByCountry(invoices: InvoiceRow[]) {
    let filteredByCountry = []
    if (!this.selectedCountry) return invoices
    for (const invoice of invoices) {
      if (invoice.items[0].currency === 'DKK' && this.selectedCountry === 'DK') filteredByCountry.push(invoice)
      if (invoice.items[0].currency === 'GBP' && this.selectedCountry === 'UK') filteredByCountry.push(invoice)
    }
    return filteredByCountry
  }

  totalAmount(invoice: IInvoiceApproval) {
    let total = 0
    for (var item of invoice.items) {
      let partialSum = item.unitPrice * item.quantity
      let vat = (partialSum * item.vatPercentage) / 100
      let subtotal = partialSum + vat
      total += subtotal
    }
    return this.formatAmount((invoice.items[0] || {}).currency, total.toFixed(2))
  }

  formatAmount(currency: string, amount: string) {
    let formattedAmount = ''
    let price = parseFloat(amount).toFixed(2)
    if (currency === 'GBP') {
      formattedAmount = `£ ${price}`
    } else {
      formattedAmount = `${price} DKK`
    }
    return formattedAmount
  }

  goToUser(_invoice: IInvoiceApproval) {
    console.log('row clicked')
  }

  async mounted() {
    try {
      this.allInvoices = await InvoiceApi.getAllInvoices()
      this.fetchingInvoices = false
    } catch (e) {
      this.fetchingInvoices = false
      this.errorFetchingInvoices = true
    }
  }
}
