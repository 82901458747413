


















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import uuid from 'uuid-random'

import { RequestPayload } from '@/shared/billie/api/serviceRequests'
import AgreementAttachments from '@/components/agreement/AgreementAttachments.vue'
import moment from 'moment'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ITimeframe } from '../../shared/billie/api/serviceRequests'
import Multiselect from 'vue-multiselect'

@Component({
  components: { AgreementAttachments, DatePicker, Multiselect }
})
export default class NewEditRequest extends Vue {
  @Prop({}) requestPayload: RequestPayload | null
  people: { min: null | number; max: null | number; type: null | 'absolute' | 'range'; isInvalidMax: boolean } = {
    min: null,
    max: null,
    type: null,
    isInvalidMax: false
  }
  typeOptions = [
    { text: 'Range', value: 'between' },
    { text: 'As soon as possible', value: 'asap' },
    { text: 'Before date', value: 'before' },
    { text: 'After date', value: 'after' },
    { text: 'Date', value: 'date' }
  ]
  budgetOptions = [
    { text: 'Total', value: 'total' },
    { text: 'Per person', value: 'perPerson' },
    { text: 'Per hour', value: 'perHour' },
    { text: 'Per Week', value: 'perWeek' },
    { text: 'Per Month', value: 'perMonth' }
  ]
  frequencyOptions = [
    { value: 'once', text: 'Just once' },
    { value: 'everyDay', text: 'Each day' },
    { value: 'perWeek', text: 'One or more times per week' },
    { value: 'perMonth', text: 'One or more times per month' },
    { value: 'perQuarter', text: 'One or more times per quarter' },
    { value: 'perYear', text: 'One or more times per year' },
    { value: 'outOfStock', text: 'Just before we run out of stock ' },
    { value: 'other', text: 'Other' }
  ]
  times = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' }
  ]
  weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

  editMode = false
  localRequestPayload: RequestPayload = {
    id: uuid(),
    title: '',
    description: '',
    location: '',
    attachments: [],
    people: null,
    timeframe: {
      type: 'between'
    },
    budget: {
      type: 'total',
      priceFrom: 0,
      priceTo: 0
    },
    frequency: {
      type: 'once',
      value: null,
      days: null
    }
  }
  datepickerConfig = {
    type: 'Date'
  }

  resetFrequencyValue() {
    this.localRequestPayload.frequency!.value = this.localRequestPayload.frequency!.type === 'other' ? '' : null
    this.localRequestPayload.frequency!.days = null
  }

  async created() {
    if (this.requestPayload) {
      this.editMode = true
      this.localRequestPayload = JSON.parse(JSON.stringify(this.requestPayload))
      if (
        this.requestPayload.timeframe.type == 'before' ||
        this.requestPayload.timeframe.type == 'after' ||
        this.requestPayload.timeframe.type == 'date'
      ) {
        this.localRequestPayload.timeframe.from = this.requestPayload.timeframe.date
      }
    }
    this.populateRequestDetails()
  }

  validateStartDateInput(date: Date) {
    if (moment(date).isAfter(this.localRequestPayload.timeframe.to)) {
      this.localRequestPayload.timeframe.to = this.localRequestPayload.timeframe.from
    }
  }

  validateEndDateInput(date: Date) {
    if (!moment(date).isAfter(this.localRequestPayload.timeframe.from)) {
      this.localRequestPayload.timeframe.from = this.localRequestPayload.timeframe.to
    }
  }

  timeframe() {
    if (
      !this.localRequestPayload.timeframe.from &&
      !this.localRequestPayload.timeframe.date &&
      this.localRequestPayload.timeframe.type !== 'asap'
    ) {
      return null
    }
    const timeframe: ITimeframe = { type: this.localRequestPayload.timeframe.type }
    if (this.localRequestPayload.timeframe.time) timeframe.time = this.localRequestPayload.timeframe.time
    switch (this.localRequestPayload.timeframe.type) {
      case 'between':
        timeframe.from = moment(this.localRequestPayload.timeframe.from).format('YYYY-MM-DD')
        timeframe.to = moment(this.localRequestPayload.timeframe.to).format('YYYY-MM-DD')
        break
      case 'before':
      case 'after':
      case 'date':
        timeframe.date = moment(this.localRequestPayload.timeframe.from).format('YYYY-MM-DD')
        break
      default:
        break
    }
    return timeframe
  }

  get invalidMax() {
    if (this.people.isInvalidMax === true) return 'Min cannot be greater than mx'
    else return ''
  }

  get frequencySecondChoice() {
    if (this.localRequestPayload.frequency) {
      switch (this.localRequestPayload.frequency.type) {
        case 'perMonth':
        case 'perQuarter':
        case 'perYear':
          return 'number'
        case 'perWeek':
          return 'days'
        case 'other':
          return 'input'
        default:
          return null
      }
    } else {
      return null
    }
  }

  getFrequencyObject() {
    if (this.localRequestPayload.frequency!.type) {
      if (this.localRequestPayload.frequency!.days && this.localRequestPayload.frequency!.days.length === 0) {
        this.localRequestPayload.frequency!.days = null
      }
      return this.localRequestPayload.frequency
    } else return null
  }

  populateRequestDetails() {
    if (this.localRequestPayload.people) {
      this.people.type = this.localRequestPayload.people.type
      if (this.localRequestPayload.people.type === 'absolute') {
        this.people.max = this.localRequestPayload.people.value
      } else {
        this.people = {
          ...this.people,
          min: this.localRequestPayload.people.min,
          max: this.localRequestPayload.people.max,
          type: this.localRequestPayload.people.type
        }
      }
    }
    if (!this.localRequestPayload.frequency) {
      this.localRequestPayload.frequency = { type: null, value: null, days: null }
    } else {
      this.localRequestPayload.frequency = {
        type: this.localRequestPayload.frequency.type,
        days: this.localRequestPayload.frequency.days ? this.localRequestPayload.frequency.days : null,
        value: this.localRequestPayload.frequency.value ? this.localRequestPayload.frequency.value : null
      }
    }
  }

  isNumberKey(e: KeyboardEvent) {
    if (!/^[0-9]$/i.test(e.key)) {
      e.preventDefault()
    }
  }

  isValidMax(minMax: { min: number | null; max: number | null }) {
    if (minMax.min && minMax.max) return minMax.min >= minMax.max && minMax.min >= 0 && minMax.max >= 0
    if (minMax.min && !minMax.max) return true
    return false
  }

  onMinMaxChange() {
    this.people.isInvalidMax = this.isValidMax({ min: this.people.min, max: this.people.max })
    if (this.people.isInvalidMax === true) {
      return (this.localRequestPayload.people = null)
    }
    if (this.people.min && this.people.max) {
      return (this.localRequestPayload.people = {
        ...this.localRequestPayload.people,
        min: this.people.min,
        max: this.people.max,
        type: 'range'
      })
    }
    if (!this.people.min && this.people.max) {
      return (this.localRequestPayload.people = {
        ...this.localRequestPayload.people,
        value: this.people.max,
        type: 'absolute'
      })
    } else return (this.localRequestPayload.people = null)
  }

  addFile(file: string) {
    this.localRequestPayload.attachments.push(file)
  }

  removeFile(index: number) {
    if (this.localRequestPayload.attachments.length) this.localRequestPayload.attachments.splice(index, 1)
  }

  saveRequest() {
    const budget = this.localRequestPayload.budget.priceTo ? this.localRequestPayload.budget : null
    if (budget && !this.localRequestPayload.budget.priceFrom) {
      this.localRequestPayload.budget.priceFrom = 0
    }
    this.$emit('save', {
      ...this.localRequestPayload,
      timeframe: this.timeframe(),
      budget,
      frequency: this.getFrequencyObject()
    })
  }
}
