




















import globals from '@/globals'
import VersionApi from '@/shared/services/api/versionApi'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class NewReleaseVersion extends Vue {
  isNewReleaseAvailable = false
  timerId: null | number = null

  reload() {
    if ('caches' in window) {
      console.log(caches)
      caches.keys().then(names => {
        names.forEach(async name => {
          await caches.delete(name)
        })
      })
    }
    window.location.reload()
  }

  created() {
    this.checkAppVersion()
  }

  checkAppVersion() {
    if (location.hostname !== 'localhost') {
      this.timerId = window.setInterval(async () => {
        const version = await VersionApi.getAppVersion(globals().publicUrl as string)
        const oldCommitId = localStorage.getItem(globals().commitId)
        const el = document.getElementById('app') as HTMLDivElement

        if (version.commitId === el.dataset.version && oldCommitId !== version.commitId) {
          this.isNewReleaseAvailable = false
          localStorage.setItem(globals().commitId, version.commitId)
        } else {
          this.isNewReleaseAvailable = !!oldCommitId && oldCommitId !== version.commitId
        }
      }, 300000)
    }
  }

  beforeDestroy() {
    if (this.timerId) window.clearInterval(this.timerId)
  }
}
