import axios from './interceptor'

import { IFileResponseData } from '../../components/interfaces/fileHandler.interface'

class FileHandlerApi {
  uploadFile(formData: FormData): Promise<IFileResponseData> {
    return axios
      .post('/api/file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => {
        return resp.data
      })
  }
}
export default new FileHandlerApi()
