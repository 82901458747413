import API from "@/shared/billie/api";
import { IUser } from "@/shared/storeModules/auth";
export interface UserResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  position: string;
  mobile: string;
  gdprLastAcceptedAt: string;
  lastActivityAt: string;
  client: {
    id: string;
    name: string;
    countryCode: string;
    phone: string;
    status: "active" | "under_termination" | "terminated";
  };
}

export default class Users extends API {
  public async get(id: string): Promise<UserResponse> {
    return this._get(`/api/users/${id}`);
  }

  public async listByClient(clientId: string): Promise<IUser[]> {
    return this._get(`/api/clients/${clientId}/users`);
  }

  public async delete(clientId: string, userId: string) {
    return this._delete(`/api/clients/${clientId}/users/${userId}`);
  }

  public async deleteByAdmin(userId: string) {
    return this._delete(`/api/users/${userId}`);
  }
}
