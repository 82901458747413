






















































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import ChatWidgetV2 from '@/shared_legacy/components/chat/ChatWidgetV2.vue'
import DeadlineBar from '../agreement/DeadlineBar.vue'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
import RemindMeMenu from '@/shared_legacy/components/chat/RemindMeMenu.vue'
import ClientAgreementTicketIcon from '../clients/ClientAgreementTicketIcon.vue'
import { IUser } from '@/shared/storeModules/auth'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'

@Component({
  components: { ChatWidgetV2, DeadlineBar, RemindMeMenu, ClientAgreementTicketIcon }
})
export default class ChatWithDeadlineV2 extends Vue {
  @Prop() agreementId: string
  @Prop() chatContext: string
  @Prop() language: string
  @Prop({ default: 'agreement' }) subjectType: string
  @Prop() tasks: ITicket[]
  @Prop() user: IUser
  @Prop({ default: () => [] }) careUsers: ICareUser[]

  isClosingTicket = false
  toggleMenu = false

  get chatTitle() {
    if (this.subjectType === 'client') return 'Concierge'
    return 'Conversation'
  }

  @Watch('language', { immediate: true })
  onLanguageChange(_value: string) {
    this.$i18n.locale = 'en-UK'
  }

  mounted() {
    window.addEventListener('keydown', this.onKeydown)
  }

  onKeydown(_e: KeyboardEvent) {
    if (this.toggleMenu) {
      this.toggleMenu = false
    }
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown)
  }

  closeTicket() {
    if (this.filterMessageTask && this.filterMessageTask.expiresAt) {
      this.isClosingTicket = true
    }
  }

  async selectedDropdownValue(value: number) {
    await TasksApi.snoozeTicket(this.ticket!.id, value * 60)
  }

  async assignUser(user: ICareUser, e: MouseEvent) {
    if (!(e.target as HTMLDivElement).classList.contains('muted')) {
      e.stopPropagation()
      await TasksApi.assignTicket(this.ticket!.id, user.id!)
      this.$forceUpdate()
    }
  }

  get filterMessageTask() {
    for (const task of this.tasks) {
      if (
        (task.type === 'ExpectCustomerResponse' || task.type === 'AnswerCustomerChat') &&
        task.subjectId === this.agreementId
      ) {
        return task
      }
    }
    return null
  }
  get ticket() {
    for (const task of this.tasks) {
      if (task.subjectId === this.agreementId) {
        return task
      }
    }
    return null
  }
}
