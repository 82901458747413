





























import { Vue, Prop, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class AgreementUnitSelect extends Vue {
  @Prop({ required: true }) invoiceLanguage: string
  @Prop({ default: '' }) value: string
  @Prop({ default: 'sm' }) size: string
  @Prop({ default: false }) disabled: boolean
  units: { [key: string]: string[] } = {
    boxes: ['Kasser', 'Boxes'],
    cases: ['Æsker', 'Cases'],
    days: ['Dage', 'Days'],
    frames: ['Rammer', 'Frames'],
    hours: ['Timer', 'Hours'],
    kg: ['Kg', 'Kg'],
    liter: ['Liter', 'Litre'],
    m2: ['m2', 'm2'],
    months: ['Måned', 'Months'],
    packages: ['Pakker', 'Packages'],
    pcs: ['Stk', 'Pcs'],
    sessions: ['Sessioner', 'Sessions'],
    times: ['Gange', 'Times'],
    weeks: ['Uger', 'Weeks']
  }

  get selectValue() {
    if (this.value) return this.getSlug()
    this.selectValue = 'pcs'
    return 'pcs'
  }

  set selectValue(value: string) {
    this.$emit('input', this.unitMapping(value))
  }

  unitMapping(slug: string) {
    if (['en-UK', 'en-US'].includes(this.invoiceLanguage)) {
      return this.units[slug][1]
    } else {
      return this.units[slug][0]
    }
  }

  getSlug() {
    const unitKeys = Object.keys(this.units)
    for (let i = 0; i < unitKeys.length; i++) {
      const key = unitKeys[i]
      if (this.units[key].includes(this.value.replace(/\./g, ''))) return key
    }
    return ''
  }

  mounted() {
    if (this.units[this.value.toLowerCase()]) {
      this.selectValue = this.value.toLowerCase()
    }
  }
}
