


































































import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'
import TicketIcon from '../ticket/TicketIcon.vue'
import DeadlineBar from '../agreement/DeadlineBar.vue'
import ClientPicture from '@/shared_legacy/components/ClientPicture.vue'
import { IUser } from '@/shared/storeModules/auth'
import ClientAgreementTicketIcon from './ClientAgreementTicketIcon.vue'
import RemindMeMenu from '@/shared_legacy/components/chat/RemindMeMenu.vue'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'

@Component({
  components: { TicketIcon, DeadlineBar, ClientPicture, ClientAgreementTicketIcon, RemindMeMenu }
})
export default class ClientAgreementTicket extends Vue {
  @Prop() tasks: ITicket[]
  @Prop() user: IUser
  @Prop() requestId: string | string[]
  @Prop({ default: false }) showShortcuts: boolean
  @Prop() updateDataCallback: () => void
  @Prop({ default: [] }) careUsers: ICareUser[]
  ticket: ITicket | null = null
  toggleMenu = false

  created() {
    moment.locale('en')
    this.setTicket()
  }

  @Watch('requestId')
  onIdChange() {
    this.setTicket()
  }

  setTicket() {
    for (const task of this.tasks) {
      if (Array.isArray(this.requestId)) {
        if (this.requestId.indexOf(task.subjectId) !== -1) {
          this.ticket = task
        }
      } else {
        if (task.subjectId === this.requestId) {
          this.ticket = task
        }
      }
    }
  }

  remindTimes = [
    { text: '30 minutes', value: 60 * 30 },
    { text: '2 hours', value: 60 * 60 * 2 },
    { text: '6 hours', value: 60 * 60 * 6 },
    { text: '1 day', value: 60 * 60 * 24 },
    { text: '3 days', value: 60 * 60 * 24 * 3 },
    { text: '1 week', value: 60 * 60 * 24 * 7 },
    { text: '1 month', value: 60 * 60 * 24 * 7 * 4 },
    { text: '3 months', value: 60 * 60 * 24 * 7 * 4 * 3 }
  ]

  async setReminder(time: number) {
    await TasksApi.snoozeTicket(this.ticket!.id, time * 60)
    this.updateDataCallback()
  }

  setToggle(e: MouseEvent) {
    this.toggleMenu = !this.toggleMenu
    this.setRemindMePosition(e)
  }

  setRemindMePosition(_e: MouseEvent) {
    this.$nextTick(() => {
      const reminder = this.$refs.remindMeMenu as any
      if (reminder) {
        setTimeout(() => {
          const offsetWidth = (this.$refs.deadlineBar as HTMLDivElement).clientWidth - 32
          reminder.$el.style.setProperty('left', `${reminder.$el.offsetLeft - offsetWidth}px`)
          reminder.$el.style.setProperty('right', 'auto')
          reminder.$el.style.setProperty('bottom', 'auto')
        }, 1)
      }
    })
  }

  async assignUser(user: ICareUser, e: MouseEvent) {
    if (!(e.target as HTMLDivElement).classList.contains('muted')) {
      e.stopPropagation()
      await TasksApi.assignTicket(this.ticket!.id, user.id!)
      this.$forceUpdate()
    }
  }

  async closeTicket() {
    await TasksApi.closeTicket(this.ticket!.id)
    this.$forceUpdate()
  }
  get due() {
    return moment(this.ticket!.expiresAt).fromNow()
  }
}
