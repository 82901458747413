import API, { Payload } from '@/shared/billie/api'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'

export interface InviteePayload extends Payload {
    email: string
    phone: string
    clientId: string
}

export default class Invitees extends API {
    public async list(): Promise<IInvitee[]> {
        return this._get(`api/invitees`)
    }

    public async listByClient(clientId: string): Promise<IInvitee[]> {
        return this._get(`api/clients/${clientId}/invitees`)
    }
    
    /**
     * Creates a new Invitee without sending an invitation email
     */
    public async create(invitee: InviteePayload): Promise<IInvitee> {
        return this._post(`/api/invitees`, invitee)
    }

    /**
     * Creates a new Invitee and sends an invitation email
     */
    public async createForClient(clientId: string, invitee: InviteePayload): Promise<IInvitee> {
        return this._post(`/api/clients/${clientId}/invitees`, invitee)
    }

    public async delete(clientId: string, id: string): Promise<any> {
        return this._delete(`/api/clients/${clientId}/invitees/${id}`)
    }
}