import { render, staticRenderFns } from "./TicketIcon.vue?vue&type=template&id=475ea82a&scoped=true&"
import script from "./TicketIcon.vue?vue&type=script&lang=ts&"
export * from "./TicketIcon.vue?vue&type=script&lang=ts&"
import style0 from "./TicketIcon.vue?vue&type=style&index=0&id=475ea82a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475ea82a",
  null
  
)

export default component.exports