

































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import AgreementHelper from '@/helpers/AgreementHelper'

@Component({ components: {} })
export default class DocumentElementVAT extends Vue {
  @Prop() currency: string
  @Prop() existingVat: number
  @Prop() existingElement: boolean
  @Prop({ default: true }) showLabel: string
  value = 0

  get vatPercentage() {
    return this.value
  }

  set vatPercentage(value: number) {
    this.value = value
    this.$emit('update', parseFloat(value.toString()), 'vatPercentage')
  }

  created() {
    if (this.existingElement) {
      this.value = this.existingVat
      this.$emit('update', this.existingVat, 'vatPercentage')
    } else {
      const defaultVat = AgreementHelper.defaultVatPercentageFromCurrency(this.currency)
      this.value = defaultVat
      this.$emit('update', defaultVat, 'vatPercentage')
    }
  }
}
