






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Component({
  components: {},
})
export default class SectionTitle extends Vue {
  @Prop({ required: true }) title: string
  @Prop({ required: false, default: false }) clip: boolean
}
