
































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'
import { IRequest } from '@/shared/components/interfaces/request.interface'
import ClientAgreementTicket from './ClientAgreementTicket.vue'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
import { IUser } from '@/shared/storeModules/auth'
import { ICareUser } from '@/shared/services/api/tasksApi'
import ClientTicketHelper from '@/shared_legacy/helpers/clientTicketHelper'
import LabelField from '@/shared_legacy/components/atoms/LabelField.vue'
// @ts-ignore
import Switches from 'vue-switches'
import ShortcutStore from '@/store/modules/shortcutStore'
import { getModule } from 'vuex-module-decorators'
import ShortcutsHelperHelper from '@/shared_legacy/helpers/shortcutsHelper'
import { IProposalV2 } from '@/shared/services/api.interface'
const shortcutState = getModule(ShortcutStore)

@Component({
  components: { ClientAgreementTicket, Switches, LabelField }
})
export default class ClientRequests extends Vue {
  @Prop() tasks: ITicket[]
  @Prop() user: IUser
  @Prop({ default: () => [] }) careUsers: ICareUser[]
  requests: IRequest[] = []
  searchValue = ''
  sortBy = 'createdAt'
  sortDesc = true
  loading = true
  isExpanded = false
  showCancelled = false
  fields = [
    { key: 'title', label: 'Title', sortable: true },
    { key: 'createdAt', label: 'Created', sortable: true },
    { key: 'createdBy.name', label: 'Requested by', sortable: true },
    { key: 'proposals', lable: 'Proposals', sortable: false },
    { key: 'Ticket', sortable: false }
  ]
  shortcutState = shortcutState
  shortcutsHelperHelper = new ShortcutsHelperHelper(this.mappedAndFilteredRequests)

  get clientId() {
    return this.$route.params.id
  }

  get tableHeight() {
    return this.isExpanded ? '750px' : '295px'
  }

  proposalTickets(request: IRequest) {
    const ids = []
    for (const proposal of request.proposals) {
      ids.push(proposal.id)
    }
    return ids
  }

  rowAttribute(item: any, _type: string) {
    const props: { [key: string]: string } = {
      'data-cy': `proposal-row-item--${item.id}`
    }
    if (item.id === shortcutState.activeTableItem) {
      props.class = 'active-row'
    }

    return props
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.shortcutsHelperHelper.clickHandler!)
  }

  formatedDate(date: string) {
    return moment(date).format('ll')
  }

  getProposalStates(proposals: IProposalV2[]) {
    let [accepted, awaiting, rejected] = [0, 0, 0]
    proposals.forEach(proposal => {
      if (proposal.accepted) {
        accepted++
      } else if (proposal.accepted === false) {
        rejected++
      } else {
        awaiting++
      }
    })
    return `<i class="fas fa-file-alt"></i> ${awaiting} - <i class="fas fa-check-circle"></i> ${accepted} - <i class="fas fa-times-circle"></i> ${rejected}`
  }

  async created() {
    try {
      this.requests = await this.$billie.serviceRequests.listByClient(this.clientId)
      this.loading = false
    } catch {
      //this.$toasted.error('Something went wrong while getting requests', { icon: 'ion ion-ios-warning' })
      throw new Error(`Something went wrong while getting requests`)
    }
    moment.locale('en')
  }

  get mappedAndFilteredRequests() {
    const requests = this.requests.map(request => {
      const returnRequest: any = {
        ...request,
        createdBy: { ...request.createdBy },
        key: ClientTicketHelper.ticketUniqueId(this.tasks, request.id)
      }
      returnRequest.createdBy.name = `${returnRequest.createdBy.firstName} ${returnRequest.createdBy.lastName}`
      return returnRequest
    })
    if (this.showCancelled) {
      return requests
    } else {
      return requests.filter(req => req.status !== 'cancelled' && req.status !== 'completed')
    }
  }

  //   if (this.$refs.table) {
  //     //@ts-ignore
  //     this.$refs.table.refresh()
  //   }

  get searchResults() {
    if (this.searchValue === '') {
      return this.mappedAndFilteredRequests
    } else {
      return this.mappedAndFilteredRequests.filter(request => {
        return request.title.toLowerCase().includes(this.searchValue.toLowerCase())
      })
    }
  }

  toggleShowCancelled() {
    this.showCancelled = !this.showCancelled
  }

  rowHovered(item: any) {
    this.shortcutsHelperHelper.activateItem(item.id)
  }
}
