import axios from "./interceptor";
import { ITopUpStockLevels } from "../../components/interfaces/topUp.interface";

class TopUpApi {
  static async getStockLevels(
    page: number,
    search: string
  ): Promise<ITopUpStockLevels> {
    return axios
      .get(`/api/topup/v2/stocklevels?page=${page}&search=${search}`)
      .then((res) => {
        return res.data;
      });
  }
}
export default TopUpApi;
