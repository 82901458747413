import { IProposalOverview } from './../api.interface'
import axios from './interceptor'
import { IAgreement, IAgreementInvoiceDataV2 } from '@/shared/components/interfaces/agreements.interface'
import { IAgreementRevision, IAgreementAmendment, ISupplier } from '../../components/interfaces/agreements.interface'
import {
  IAgreementV2CreatePayload,
  IAgreementV2,
  IDeliveryResponse,
} from '../../components/interfaces/agreements.interface'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import {
  IAgreementScheduleV2,
  IAgreementRevisionV2,
  IActiveAgreementSubscription,
} from '../../components/interfaces/agreements.interface'

const userState = getModule(Auth)

export interface IOrder {
  id: string
  status: 'current' | 'invoiced' | 'cancelled'
  title: string
  supplier: ISupplier
}
class AgreementsApi {
  static async getDraftAgreementById(agreementId: string) {
    return axios.get('/api/draft-agreements/' + agreementId).then((resp) => resp.data)
  }

  static async getDraftAgreements() {
    return axios.get('/api/draft-agreements').then((resp) => resp.data)
  }

  static async getOrders(clientId: string): Promise<IOrder[]> {
    return axios.get(`/api/v2/clients/${clientId}/orders`).then((resp) => resp.data)
  }

  static async markSubscriptionAsReady(id: string, month: string) {
    return axios.post(`/api/v3/agreements/${id}/invoice-marker/${month}`).then((resp) => resp.data)
  }
  static async markAdhocAsReady(id: string) {
    return axios.post(`/api/v3/agreements/${id}/invoice-marker`).then((resp) => resp.data)
  }
  static async unmarkSubscriptionAsReady(id: string, month: string) {
    return axios.delete(`/api/v3/agreements/${id}/invoice-marker/${month}`).then((resp) => resp.data)
  }
  static async unmarkAdhocAsReady(id: string) {
    return axios.delete(`/api/v3/agreements/${id}/invoice-marker`).then((resp) => resp.data)
  }

  static async createAgnosticRequest(
    requestId: string,
    featureId: string,
    featureType: string,
    document: { [key: string]: any }
  ) {
    return axios.post(`api/${featureType}/${featureId}/requests/${requestId}`, { document }).then((resp) => resp.data)
  }

  static async createServicePageRequest(requestId: string, document: { [key: string]: any }, title: string) {
    return axios.post(`api/requests/${requestId}`, { document, title }).then((resp) => resp.data)
  }

  static async createAgreement(agreementId: string, agreement: IAgreement) {
    return axios.post('/api/agreements/' + agreementId, agreement).then((resp) => resp.data)
  }

  static async createAgreementV2(agreementId: string, agreement: IAgreementV2CreatePayload) {
    return axios.post(`/api/v2/agreements/${agreementId}`, agreement).then((resp) => resp.data)
  }

  static async getAgreementCategories(isSubscription: boolean) {
    return axios.get(`/api/v2/agreements/categories?subscriptions=${isSubscription}`).then((resp) => resp.data)
  }

  static async getOfferingCategories() {
    return axios.get(`/api/area/${userState.user.serviceArea}/offerings/categories`).then((res) => res.data)
  }

  static async getCategories(serviceArea: string) {
    return axios.get(`/api/area/${serviceArea}/offerings/categories`).then((res) => res.data)
  }

  static async setAgreementOwnership(agreementId: string, userId: string) {
    const id = userId ?? null
    return axios.put(`/api/agreements/${agreementId}/ownerships`, { userId: id }).then((res) => res.data)
  }

  static async createRevision(agreementId: string, revision: IAgreementRevision) {
    return axios.post(`/api/agreements/${agreementId}/revisions`, revision).then((res) => res.data)
  }

  static async getAgreementsByClientId(clientId: string) {
    return axios.get(`/api/clients/${clientId}/agreementsv2`).then((resp) => resp.data)
  }

  static async getClientCalendarEntries(clientId: string, startDate: string, endDate: string) {
    return axios.get(`/api/clients/${clientId}/calendar?from=${startDate}&to=${endDate}`).then((resp) => resp.data)
  }

  static async getAgreementsByStartAndEndDate(clientId: string, startDate: string, endDate: string) {
    return axios
      .get(`/api/clients/${clientId}/agreements/startDate/${startDate}/endDate/${endDate}`)
      .then((resp) => resp.data)
  }

  static async getAgreementByIdV2(id: string): Promise<IAgreementV2> {
    return axios.get(`/api/v2/agreements/${id}`).then((resp: { data: IAgreementV2 }) => {
      return resp.data
    })
  }

  static async getAgreementByIdV2ClientViewgetAgreementByIdV2ClientView(id: string): Promise<IAgreementV2> {
    return axios.get(`/api/v2/agreements/${id}/client_view`).then((resp: { data: IAgreementV2 }) => {
      return resp.data
    })
  }

  static async getAgreementsV2(): Promise<IAgreementV2[]> {
    return axios.get(`/api/v2/agreements`).then((resp) => resp.data)
  }

  static async getAgreementsV2NotInvoiced(): Promise<IAgreementV2[]> {
    return axios.get(`/api/v2/agreements/orders_in_progress`).then((resp) => resp.data)
  }

  static async getProposalsV2(): Promise<IProposalOverview[]> {
    return axios.get(`/api/v2/proposals/list`).then((res) => res.data)
  }

  // TODO: Ask Michael about difference between this and next call
  static async getAgreementsV2ByClient(id: string): Promise<IAgreementV2[]> {
    return axios.get(`/api/v2/agreements/?clientId=${id}`).then((resp: { data: IAgreementV2[] }) => {
      return resp.data
    })
  }

  static async getAgreementsV2ByClientBillie(id: string): Promise<IAgreementV2[]> {
    return axios.get(`/api/clients/${id}/agreementsv2`).then((resp) => resp.data)
  }

  static async getAgreementsV2ByFilters(params: any): Promise<IAgreementV2[]> {
    const query = []
    for (const p in params) query.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
    return axios.get(`/api/v2/agreements/filtered?` + query.join('&')).then((resp: { data: IAgreementV2[] }) => {
      return resp.data
    })
  }

  static async AgreementAddNewRevisionV2(agreementId: string, revisionId: string, payload: IAgreementRevisionV2) {
    return axios
      .post(`/api/v2/agreements/${agreementId}/revisions/${revisionId}`, payload)
      .then((resp: { data: IAgreementV2 }) => {
        return resp.data
      })
  }

  static async getAgreementRevisionEdits(agreementId: string, revisionId: string): Promise<IAgreementRevisionV2[]> {
    return axios.get(`/api/v2/agreements/${agreementId}/revision-edits/${revisionId}`).then((resp: { data: IAgreementRevisionV2[] }) => {
      return resp.data
    })
  }

  static async getAgreementByIdV2ClientView(id: string): Promise<IAgreementV2> {
    return axios.get(`/api/v2/agreements/${id}/client_view`).then((resp: { data: IAgreementV2 }) => {
      return resp.data
    })
  }

  static async updateAgreementTitle(id: string, title: string) {
    return axios.patch(`/api/agreements/${id}`, { title }).then((res) => res.data)
  }

  static async getAgreementById(id: string) {
    return axios.get(`/api/agreements/${id}`).then((resp) => resp.data)
  }

  static async getListOfDeliveriesV2(agreementId: string, month: string) {
    const queryMonth = month ? `&month=${month}` : ''
    return axios
      .get(`/api/v2/agreements/${agreementId}/deliveries?groupBy=true${queryMonth}`)
      .then((res: { data: IAgreementScheduleV2 }) => {
        return res.data
      })
  }

  static async createAmendmentV2(agreementId: string, items: IAgreementInvoiceDataV2[] | IAgreementAmendment[]) {
    return axios.post(`/api/v2/agreements/${agreementId}/amendment`, items).then((res) => res.data)
  }
  static async getAgreementDeliveryStatus(id: string, month?: string): Promise<IDeliveryResponse> {
    const queryMonth = month ? `/${month}` : ''
    return axios.get(`/api/v3/agreements/${id}/deliveries${queryMonth}`).then((resp) => {
      return resp.data
    })
  }

  static async getActiveSubscriptions(month: string): Promise<IActiveAgreementSubscription[]> {
    return axios.get(`/api/v3/agreements/active-subscriptions?month=${month}`).then((resp) => resp.data)
  }

  static async convertClientToPaid(clientId: string, trialExpirationDate: string, platformFee: string | number) {
    return axios
      .post(`/api/clients/${clientId}/paid-platform-transition`, { trialExpirationDate, platformFee })
      .then((res) => res.data)
  }
}
export default AgreementsApi
