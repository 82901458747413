







































































































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IInvoice } from '@/shared/components/interfaces/invoices.interface'
import InvoiceApi from '@/shared/services/api/invoiceApi'
import SortByHelper from '@/shared/helpers/sortByHelper'
import ProgressBar from '@/shared_legacy/components/atoms/ProgressBar.vue'

@Component({
  components: { ProgressBar }
})
export default class ClientsInvoices extends Vue {
  @Prop() loading: boolean
  @Prop() invoices: IInvoice[]
  key = ''
  searchValue = ''
  sortBy = 'date'
  sortDesc = false
  ascending = true
  touched = false
  clickedRowId = ''

  fields = [
    { key: 'invoiceNumber', sortable: true, title: 'ID' },
    { key: 'date', sortable: true, title: 'Date' },
    { key: 'dueDate', sortable: true, title: 'Due date' },
    { key: 'details', sortable: true, title: 'Title' },
    { key: 'amount', sortable: true, title: 'Total price' },
    { key: 'verificationStatus', sortable: true, title: 'Verified' },
    { key: 'action', sortable: false, title: 'Action' }
  ]

  get invoicesSortedByDueDate() {
    return this.invoices.sort(function (a, b) {
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
    })
  }

  get sortedInvoices() {
    if (this.key === '') {
      return this.invoicesSortedByDueDate
    }
    const isDate = this.key === 'date' || this.key === 'dueDate' ? true : false
    return SortByHelper.sortByText(this.filterInvoices, this.key, this.ascending, isDate)
  }

  get filterInvoices() {
    return this.invoicesSortedByDueDate.filter(invoice => {
      return (
        this.searchValue === '' ||
        (invoice.invoiceNumber.toString() !== '' && invoice.invoiceNumber.toString() == this.searchValue)
      )
    })
  }

  getStatus(verificationStatus: string) {
    let statusMessage = ''
    switch (verificationStatus) {
      case 'PENDING':
        statusMessage = 'Pending'
        break
      case 'CREDIT':
        statusMessage = 'Credit'
        break
      case 'PAID':
        statusMessage = 'Paid'
        break
      case 'OVERDUE':
        statusMessage = 'Overdue'
        break
      case 'underVerification':
        statusMessage = 'Under Verification'
        break
      case 'accepted':
        statusMessage = 'Accepted'
        break
      case 'discarded':
        statusMessage = 'Discarded'
        break
      default:
        statusMessage = 'Unverified'
    }
    return statusMessage
  }

  shouldDisableGenerateCreditNote(verificationStatus: string) {
    const valid_status = ['PENDING', 'PAID', 'OVERDUE']
    return !valid_status.includes(verificationStatus)
  }

  formatAmount(currency: string, amount: string) {
    let formattedAmount = ''
    if (currency === 'GBP') {
      formattedAmount = `£${amount}`
    } else {
      formattedAmount = `${amount} DKK`
    }
    return formattedAmount
  }
  sortClicked(key: string) {
    this.ascending = !this.ascending
    this.key = key
    this.touched = true
  }

  async getPDF(id: string) {
    this.clickedRowId = id
    const pdfData = await InvoiceApi.getInvoicePdf(id)
    if (pdfData) {
      const blob = new Blob([pdfData], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
    this.clickedRowId = ''
  }

  async generateCreditNote(currency: string, invoiceNo: string) {
    try {
      const country = currency === 'GBP' ? 'UK' : 'DK'
      await InvoiceApi.generateCreditNote(country, invoiceNo)
      this.$toasted.success('Credit note generated')
    } catch (e) {
      this.$toasted.error('Error generating credit note', {
        icon: 'ion ion-ios-warning'
      })
    }
  }
}
