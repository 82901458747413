import { IAgreementScheduleV2, IAgreementInvoiceDataV2 } from '@/shared/components/interfaces/agreements.interface'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from './../../store/store'
import AgreementsApi from '@/shared/services/api/agreementsApi'

@Module({
  name: 'AgreementScheduleStore',
  dynamic: true,
  store: store,
})
export default class AgreementScheduleStore extends VuexModule {
  agreementSchedule: IAgreementScheduleV2 | null = null
  isMarked = false

  get getAgreementScheduleTotalPrice() {
    if (!this.agreementSchedule) return 0
    const flatArray = Object.values(this.agreementSchedule).reduce((acc, cur) => {
      return acc.concat(cur)
    }, [])
    return flatArray.reduce((acc: number, cur: IAgreementInvoiceDataV2) => {
      return acc + cur.clientPrice * cur.quantity
    }, 0)
  }

  get markedForInvoicing() {
    return this.isMarked
  }

  get agreementScheduleData() {
    return this.agreementSchedule && JSON.stringify(this.agreementSchedule) !== JSON.stringify({})
      ? this.agreementSchedule
      : null
  }

  @Mutation
  public setAgreementSchedule(payload: IAgreementScheduleV2 | null) {
    this.agreementSchedule = payload
  }

  @Mutation
  public setMarked(payload: boolean) {
    this.isMarked = payload
  }

  @Action({ commit: 'setAgreementSchedule' })
  public async getAgreementSchedule(payload: { agreementId: string; month: string; type: string }) {
    const isMarkedObj = await AgreementsApi.getAgreementDeliveryStatus(
      payload.agreementId,
      payload.type === 'once' ? '' : payload.month
    )
    this.setMarked(isMarkedObj?.markedReady ?? false)
    this.setAgreementSchedule(null)
    return isMarkedObj.deliveries
  }
}
