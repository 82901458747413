


















































import { Vue, Prop, Component } from 'vue-property-decorator'
import { IProposalRequest, IProposalV2 } from '@/shared/services/api.interface'
import AgreementSetup from '@/components/agreement/AgreementSetup.vue'
import AgreementContentDetails from '@/components/agreement/AgreementContentDetails.vue'
import ConfirmationModal from '../../components/common/ConfirmationModal.vue'
import uuid from 'uuid-random'
import { IAgreementRevision } from '@/shared/components/interfaces/agreements.interface'

@Component({
  components: {
    AgreementSetup,
    AgreementContentDetails,
    ConfirmationModal
  }
})
export default class ProposalDetails extends Vue {
  @Prop({ required: true }) proposal: IProposalV2
  @Prop({ required: true }) language: string
  @Prop({ required: true }) saveProposal: (proposal: IProposalRequest, isExistingProposal: boolean) => void
  componentKey = uuid()
  modalShow = false
  action = ''
  editMode = false
  editRevisionIndex = 0
  activeRevision: IAgreementRevision | null = null

  get proposalStatus() {
    if (this.proposal?.accepted === null) return 'Awaiting Approval'
    if (this.proposal?.accepted === false) return 'Rejected'
    return 'Accepted'
  }

  get isPendingProposal() {
    return this.proposal && this.proposal.accepted === null
  }

  get proposalTitle() {
    if (this.editMode) {
      const startDate = this.proposal.startDate
      return `Edit ${this.isAdhoc ? 'ad hoc' : 'subscription'} proposal ${
        this.isAdhoc ? '' : `(revision ${startDate})`
      }`
    }
    return `${this.isAdhoc ? 'Ad hoc' : 'Subscription'} proposal details`
  }

  get currentProposal() {
    let proposal = JSON.parse(JSON.stringify(this.proposal))
    proposal = {
      ...proposal,
      revisions: [
        {
          ...proposal.revisions[0],
          title: proposal.title,
          category: proposal.category,
          description: proposal.description,
          startDate: proposal.startDate,
          prepaymentPatterns: [...proposal.revisions[0].elements],
          attachments: proposal.attachments
        }
      ]
    }
    return proposal
  }

  get isAdhoc() {
    return this.proposal?.invoiceSchedule === 'once'
  }

  get hasRevisions() {
    return this.proposal.revisions.length
  }

  get modalText() {
    return `Are you sure you want to ${this.action} this proposal?`
  }

  openCloseModal() {
    this.modalShow = !this.modalShow
  }

  handleCancel() {
    this.action = ''
    this.openCloseModal()
  }

  handleConfirm() {
    if (this.action === 'approve') {
      this.approveProposal()
    }
    if (this.action === 'reject') {
      this.rejectProposal()
    }
    this.openCloseModal()
  }

  approve() {
    this.action = 'approve'
    this.openCloseModal()
  }

  reject() {
    this.action = 'reject'
    this.openCloseModal()
  }
  approveProposal() {
    return this.$billie.proposals
      .accept(this.proposal.id)
      .then(() => {
        //this.$router.push(`/clients/${this.$route.params.id}`)
        this.proposal.accepted = true
      })
      .catch(_e => {
        this.$toasted.show('Error: Proposal not approved', { duration: 3000 })
      })
  }

  rejectProposal() {
    return this.$billie.proposals
      .reject(this.proposal.id)
      .then(() => {
        //this.$router.push(`/clients/${this.$route.params.id}`)
        this.proposal.accepted = false
      })
      .catch(_e => {
        this.$toasted.show('Error: Proposal not rejected', { duration: 3000 })
      })
  }

  cancel() {
    this.editMode = false
  }

  edit(index: number) {
    this.editMode = true
    this.editRevisionIndex = index
  }

  shouldUpdate() {
    this.componentKey = uuid()
    this.editMode = false

    this.$emit('loadAgreement')
  }

  setActiveRevision(revision: IAgreementRevision) {
    this.activeRevision = { ...revision }
  }

  transformAndSaveProposal(payload: any) {
    const proposal: IProposalRequest = {
      id: this.proposal.id,
      title: payload.title,
      description: payload.description,
      currency: this.proposal.currency,
      startDate: payload.startDate,
      clientId: this.proposal.client.id,
      supplierId: this.proposal.supplier.id,
      creatorId: this.proposal.creator.id,
      requestId: this.proposal.requestId,
      category: payload.category,
      invoiceSchedule: payload.invoiceSchedule,
      prepaid: payload.prepaid,
      requestedBy: '',
      offeringId: '',
      elements: payload.prepaymentPatterns,
      attachments: payload.attachments
    }
    if (payload.deleted) {
      proposal.deleted = true
    }
    this.editMode = false
    this.saveProposal(proposal, true)
  }
}
