import { AxiosInstance } from 'axios'
import Agreements from '@/shared/billie/api/agreements'
import Auth from '@/shared/billie/api/auth'
import Calendar from '@/shared/billie/api/calendar'
import Chat from '@/shared/billie/api/chat'
import Clients from '@/shared/billie/api/clients'
import Favorites from '@/shared/billie/api/favorites'
import FileUpload from '@/shared/billie/api/fileUpload'
import Invitations from '@/shared/billie/api/invitations'
import Invitees from '@/shared/billie/api/invitees'
import Offerings from '@/shared/billie/api/offerings'
import Proposals from '@/shared/billie/api/proposals'
import Requests from '@/shared/billie/api/requests'
import ServiceRequests from '@/shared/billie/api/serviceRequests'
import Users from '@/shared/billie/api/users'

export default class Billie {
  private http: AxiosInstance

  /**
   * Registered API's
   */
  public agreements: Agreements
  public auth: Auth
  public calendar: Calendar
  public chat: Chat
  public clients: Clients
  public favorites: Favorites
  public fileUpload: FileUpload
  public invitations: Invitations
  public invitees: Invitees
  public offerings: Offerings
  public proposals: Proposals
  public requests: Requests
  public serviceRequests: ServiceRequests
  public users: Users

  constructor(http: AxiosInstance) {
    this.http = http
    this.agreements = new Agreements(http)
    this.auth = new Auth(http)
    this.calendar = new Calendar(http)
    this.chat = new Chat(http)
    this.clients = new Clients(http)
    this.favorites = new Favorites(http)
    this.fileUpload = new FileUpload(http)
    this.invitations = new Invitations(http)
    this.invitees = new Invitees(http)
    this.offerings = new Offerings(http)
    this.proposals = new Proposals(http)
    this.requests = new Requests(http)
    this.serviceRequests = new ServiceRequests(http)
    this.users = new Users(http)
  }

  public setToken(token: string) {
    this.http.defaults.headers.common['Authorization'] = 'Bearer ' + token
    return new Billie(this.http)
  }
}
