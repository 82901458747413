import axios from './interceptor'
import { IUser } from '@/shared/storeModules/auth'

export default {
  getUser(userId: string): Promise<IUser> {
    return axios.get(`/api/users/${userId}`).then((resp) => {
      return Object.assign(resp.data, { shouldConsent: resp.headers['x-term-should-consent'] })
    })
  },
}
