import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'

@Module({
  name: 'ChatStateStore',
  dynamic: true,
  store: store,
})
export default class ChatStateStore extends VuexModule {
  data = {
    tabFocused: true,
    inputFocused: false,
  }

  get isTabFocused() {
    return this.data.tabFocused
  }

  get isInputFocused() {
    return this.data.inputFocused
  }

  @Mutation
  public mutateTabFocused(payload: boolean) {
    this.data.tabFocused = payload
  }

  @Mutation
  public mutateInputFocused(payload: boolean) {
    this.data.inputFocused = payload
  }

  @Action({ commit: 'mutateInputFocused' })
  public setInputFocused(data: boolean) {
    return data
  }

  @Action({ commit: 'mutateTabFocused' })
  public setTabFocused(data: boolean) {
    return data
  }
}
