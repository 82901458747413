import API from '@/shared/billie/api'

export interface OfferingResponse {
    id: string
    name: string
    shortDescription: string
    longDescription: string
    category: string
    ctaLabel: string
    image: string
    tags: string[]
    supplier: {
        id: string
        name: string
        about: string
        description: string
        currency: string
        logo: {
            url: string
        }
        address: {
            addressLine1: string
            addressLine2: string
            city: string
            country: string
            countryCode: string
            zipcode: string
        }
        serviceHours: {
            monday: string
            tuesday: string
            wednesday: string
            thursday: string
            friday: string
            saturday: string
            sunday: string
        }
    }
}

export default class Offerings extends API {
  public async get(id: string): Promise<OfferingResponse> {
    return this._get(`/api/offerings/${id}`)
  }
}
