

















import moment from 'moment'
import Message from './Message.vue'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IConversationMessage } from '@/shared/components/interfaces/messages.interface'
import GrayMessageDateLine from '@/shared_legacy/components/atoms/GrayMessageDateLine.vue'

@Component({
  components: { Message, GrayMessageDateLine }
})
export default class Messages extends Vue {
  @Prop({ required: true }) messages: IConversationMessage[]
  @Prop({ required: true, default: 0 }) force: number
  $t: (str: string) => ''
  $appName: 'back-office' | 'platform'

  get groupedMessages() {
    this.force
    return this.groupMessages(this.messages)
  }
  fullDate(message: IConversationMessage) {
    return moment(message.createdAt).format('ddd D MMM YYYY') === moment().format('ddd D MMM YYYY')
      ? this.$t('labels.calendar.today')
      : moment(message.createdAt).format('ddd D MMM YYYY')
  }
  groupMessages(messages: IConversationMessage[]) {
    let groupedAll: IConversationMessage[] = []
    let lastMessage: IConversationMessage = { user: {} } as IConversationMessage
    const messagesTmp = [...messages]
    let messagesCopy = messages
      ? messagesTmp.sort((a: IConversationMessage, b: IConversationMessage) => {
        //@ts-ignore
        return moment(a.createdAt) - moment(b.createdAt)
      })
      : []
    messagesCopy.sort(function (a, b) {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
    // let messagesCopy = messages ? messages.slice().reverse() : []
    for (let message of messagesCopy) {
      if (
        lastMessage &&
        this.areMessagesFromSameUser(lastMessage, message) &&
        this.areMessagesOnSameDay(lastMessage, message) &&
        this.areMessagesWithinSameMinute(message, lastMessage)
      ) {
        groupedAll![groupedAll.length - 1]!.messages!.push({
          message: message.text,
          messageTime: message.createdAt,
          attachment: message.attachment,
          unread: message.unread
        })
      } else {
        message.messages = [
          {
            message: message.text,
            messageTime: message.createdAt,
            attachment: message.attachment,
            unread: message.unread
          }
        ]
        groupedAll.push(message)
      }
      lastMessage = message
    }
    return groupedAll
  }

  areMessagesFromSameUser(message1: IConversationMessage, message2: IConversationMessage) {
    return message1.user.email === message2.user.email
  }

  areMessagesOnSameDay(message1: IConversationMessage, message2: IConversationMessage) {
    return this.fullDate(message1) === this.fullDate(message2)
  }

  areMessagesWithinSameMinute(message1: IConversationMessage, message2: IConversationMessage) {
    const firstMessageTime = moment(message1.createdAt)
    const secondMessageTime = moment(message2.createdAt)
    return firstMessageTime.format('HH:mm') === secondMessageTime.format('HH:mm')
  }

  shouldRenderDate(index: number) {
    return (
      !this.groupedMessages[index - 1] ||
      !this.areMessagesOnSameDay(this.groupedMessages[index - 1], this.groupedMessages[index])
    )
  }
}
