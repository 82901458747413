import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '../../store/store'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'

@Module({
  name: 'Tickets',
  dynamic: true,
  store: store,
})
export default class Tickets extends VuexModule {
  ticketsData: ITicket[] = []

  get tickets() {
    return this.ticketsData
  }

  get ticketsById() {
    return (clientId: string) => {
      return this.ticketsData.filter((ticket) => ticket.client.id === clientId)
    }
  }

  @Mutation
  public setTickets(tickets: ITicket[]) {
    this.ticketsData = tickets
  }
}
