import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from './../../store/store'

@Module({
  name: 'ErrorToast',
  dynamic: true,
  store: store,
})
export default class ErrorToast extends VuexModule {
  error = false
  errorMessage = 'Error'

  get errorMode() {
    return this.error
  }
  get errMessage() {
    return this.errorMessage
  }

  @Mutation
  public setToastErrorMutation(payload: { value: boolean; message: string }) {
    this.error = payload.value
    this.errorMessage = payload.message
  }

  @Action({ commit: 'setToastErrorMutation' })
  public async setToastError(payload: { value: boolean; message: string }) {
    return payload
  }
}
