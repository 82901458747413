








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class DragComponent extends Vue {
  @Prop() show: boolean
  @Prop() callback: (e: Event) => void
  @Prop({ default: '' }) text: string

  onDrop(e: Event) {
    e.preventDefault()
    this.callback(e)
  }
}
