

























































































































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IUser } from '@/shared/storeModules/auth'
import CreateUser from './CreateUser.vue'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'
import SortByHelper from '@/shared/helpers/sortByHelper'
import ConfirmationModal from '../common/ConfirmationModal.vue'

@Component({
  components: { CreateUser, ConfirmationModal }
})
export default class ClientsUsers extends Vue {
  @Prop() loading: boolean
  @Prop() users: IUser[]
  @Prop() invitees: IInvitee[]
  @Prop() disableSendInvitationButtons: boolean
  showDeleteUserModal = false
  showCreateUserModal = false
  selectedUserIDtoDelete = ''
  searchValue = ''
  key = ''
  ascending = true
  touched = false
  $platformURL: string
  fields = [
    { key: 'firstName', sortable: true, title: 'Name' },
    { key: 'position', sortable: true, title: 'Title' },
    { key: 'email', sortable: true, title: 'Email' },
    //   { key: 'roles', sortable: true, title: 'Role' },
    { key: 'mobile', sortable: true, title: 'Phone' },
    { key: 'gdprLastAcceptedAt', sortable: false, title: 'Terms Accepted' },
    { key: 'deleteUser', sortable: false, title: 'Delete User' }
  ]

  get sortedUsers() {
    if (this.key === '') {
      return this.usersAndInviteesCombined
    }
    return SortByHelper.sortByText(this.usersAndInviteesCombined, this.key, this.ascending)
  }

  openCloseModal() {
    this.showCreateUserModal = !this.showCreateUserModal
  }

  handleDeleteUserModalCancel() {
    this.showDeleteUserModal = false
    this.selectedUserIDtoDelete = ''
  }
  get deleteClientText() {
    const selectedUser = this.usersAndInviteesCombined.find(user => {
      return user.id === this.selectedUserIDtoDelete
    })
    if (!selectedUser) {
      return ''
    }
    return (
      'Do you really want to delete ' +
      selectedUser?.firstName +
      ' ' +
      selectedUser?.lastName +
      ' ' +
      selectedUser?.email +
      '?'
    )
  }
  get usersAndInviteesCombined() {
    var emptyUser: IUser = {} as IUser
    let convertedInvetees: IUser[] = []
    for (let invitee of this.invitees) {
      convertedInvetees.unshift({
        ...emptyUser,
        email: invitee.email,
        id: invitee.id,
        position: null,
        mobile: invitee.phone,
        lastEmailSent: invitee.lastEmailSent,
        invitedAt: invitee.invitedAt,
        clientId: invitee.clientId,
        roles: ['invited']
      })
    }
    return this.filterUsers(convertedInvetees.concat(this.users))
  }

  sortClicked(key: string) {
    this.ascending = !this.ascending
    this.key = key
    this.touched = true
  }

  openDeleteUserModal(id: string) {
    this.selectedUserIDtoDelete = id
    this.showDeleteUserModal = false
    //TODO: figure out why this is needed when closing modal via background mouse click
    setTimeout(() => {
      this.showDeleteUserModal = true
    }, 0)
  }

  async handleUserDelete() {
    await this.$billie.users.deleteByAdmin(this.selectedUserIDtoDelete)
    this.showDeleteUserModal = false
    const index = this.users.findIndex(user => {
      return user.id === this.selectedUserIDtoDelete
    })
    if (index !== -1) {
      this.users.splice(index, 1)
    }
    this.selectedUserIDtoDelete = ''
  }

  sendInvitation(id: string) {
    this.$billie.invitations.sendEmail(id).then(data => {
      for (let invitee in this.invitees) {
        if (this.invitees[invitee].id === data.id) {
          this.invitees[invitee].lastEmailSent = data.lastEmailSent
          this.invitees[invitee].invitedAt = data.invitedAt
          break
        }
      }
    })
  }
  copyLinkToClipboard(id: string) {
    let tmpInput: HTMLInputElement = document.createElement('INPUT') as HTMLInputElement
    tmpInput.value = `${this.$platformURL}invitations/${id}`
    tmpInput.style.position = 'absolute'
    tmpInput.style.left = '-10000px'
    document.body.appendChild(tmpInput)
    tmpInput.select()
    tmpInput.setSelectionRange(0, 99999) /* For mobile devices */
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log('Oops, unable to copy')
    }
    document.body.removeChild(tmpInput)
  }

  addInviteeToAllInvitees(invitee: IInvitee) {
    this.invitees.push(invitee)
  }
  filterUsers(users: IUser[]) {
    return users.filter(user => {
      return (
        this.searchValue === '' ||
        user.mobile?.includes(this.searchValue) ||
        user.position?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        user.email?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        user.firstName?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(this.searchValue.toLowerCase())
      )
    })
  }
}
