



















































































































































































































































































































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IClientData, Subscription, Currency } from '@/shared/components/interfaces/clients.interface'
import Price from '@/components/Price.vue'
import ClientDetailsModel from '@/shared/models/ClientDetailsModel'
import moment from 'moment'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import ConfirmationModal from '../common/ConfirmationModal.vue'
import ClientsApi from '@/shared/services/api/ClientsApi'
import ClientVatModal from './ClientVatModal.vue'

@Component({
  components: { Price, DatePicker, ConfirmationModal, ClientVatModal }
})
export default class ClientDetails extends Vue {
  @Prop() loading: boolean
  @Prop() clientDetails: IClientData
  @Prop() openModalCallback: () => void
  showModal = false
  form: { platformFee: number | string; trialExpirationDate: string } = {
    platformFee: '',
    trialExpirationDate: moment().add(1, 'month').format('YYYY-MM-DD')
  }
  error = false
  errorText = ''
  isSaving = false
  showTerminateUserModal = false
  showVATModal = false
  selectedVATOption = null
  $locale: any

  get getCurrency() {
    const currency = this.clientDetails.address.countryCode
    switch (currency) {
      case 'UK': {
        return 'GBP'
      }
      default: {
        return 'DKK'
      }
    }
  }

  get termsAcceptedDate() {
    return this.clientDetails.termsAccepted ? moment(this.clientDetails.termsAccepted.consentedAt).format('MMM DD, YYYY - HH:mm') : ''
  }

  get subscriptionType(): Subscription {
    return ClientDetailsModel.statusType(this.clientDetails.status)
  }
  get currency(): Currency {
    return ClientDetailsModel.currency(this.clientDetails)
  }

  get underTermination() {
    return this.clientDetails.status === 'under_termination'
  }
  get disabledBtn() {
    return this.error || this.isSaving
  }

  get trialEnded() {
    return this.clientDetails.trialExpirationDate && moment().isAfter(moment(this.clientDetails.trialExpirationDate))
  }

  get paymentConditionType() {
    return ClientDetailsModel.paymentConditionType(this.clientDetails)
  }

  async handleUserTerminate() {
    await ClientsApi.terminateLegacyClient(this.clientDetails.id!)
    this.showTerminateUserModal = false
    this.$root.$emit('reloadClient')
  }

  openCloseModal() {
    this.showTerminateUserModal = !this.showTerminateUserModal
  }

  toggleModal() {
    this.showModal = !this.showModal
  }

  toggleVATModal() {
    this.showVATModal = !this.showVATModal
  }
  resetError() {
    this.error = false
  }
  setTrialDate(date: Date) {
    this.form.trialExpirationDate = moment(date).format('YYYY-MM-DD')
  }
  handleCancel() {
    this.form = {
      trialExpirationDate: moment().add(1, 'month').format('YYYY-MM-DD'),
      platformFee: ''
    }
    this.error = false
    this.errorText = ''
    this.toggleModal()
  }
  async handleOk(_obj: any) {
    await AgreementsApi.convertClientToPaid(this.clientDetails.id!, this.form.trialExpirationDate, this.form.platformFee)
    this.$root.$emit('reloadClient')
    this.showModal = false
  }
}
