


































import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Vue from 'vue'
import FileHandlerApi from '@/shared/services/api/fileHandlerApi'
import AttachmentItem from '@/components/AttachmentItem.vue'

@Component({
  components: { AttachmentItem }
})
export default class AgreementAttachments extends Vue {
  @Prop({ required: true }) attachments: string[] | null
  @Prop({ default: false }) isEditMode: boolean
  @Prop({ default: true }) canPreview: boolean

  get fileToken() {
    return localStorage.getItem('backoffice_file_token')
  }

  uploadInProgress = false
  file: File | null = null
  $refs: { agreementFile: HTMLInputElement }

  handleUploadFile() {
    this.file = this.$refs.agreementFile.files![0]
    if (!this.file) return
    this.uploadFile()
  }

  addFile() {
    if (!this.file) {
      this.$refs.agreementFile.click()
    }
  }

  removeFile(index: number) {
    this.$emit('removeFile', index)
  }

  async uploadFile() {
    try {
      this.uploadInProgress = true
      let formData = new FormData()
      formData.append('file', this.$refs.agreementFile.files![0])
      let response = await FileHandlerApi.uploadFile(formData)
      this.uploadInProgress = false
      this.file = null
      this.$emit('addFile', response.id)
    } catch (e) {
      this.uploadInProgress = false
      this.file = null
      this.$toasted.error('Error uploading file', { icon: 'ion ion-ios-warning' })
    }
  }
}
