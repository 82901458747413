










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Messages from './Messages.vue'
import SendMessageV2 from './SendMessageV2.vue'
import { IConversationMessage } from '@/shared/components/interfaces/messages.interface'
import smoothscroll from 'smoothscroll-polyfill'
import SectionTitle from '@/shared_legacy/components/atoms/SectionTitle.vue'
import { Channel, Socket } from 'phoenix'
import { getModule } from 'vuex-module-decorators'
import ChatStore from '@/store/modules/chatStore'
import Auth from '@/shared/storeModules/auth'

const chatState = getModule(ChatStore)
const authState = getModule(Auth)
@Component({
  components: { Messages, SendMessageV2, SectionTitle }
})
export default class ChatWidgetV2 extends Vue {
  @Prop() chatId: string
  @Prop() chatContext: string
  @Prop() dropText: string
  @Prop() placeholderText: string
  @Prop({ default: false }) isClosingTicket: boolean
  @Prop({ default: '' }) prefilledText: string
  @Prop({ default: 86 }) containerOffset: number
  @Prop({ default: 'conversations-grid' }) scrollId: string
  @Prop({ required: false, default: false }) disableTextArea: boolean
  @Prop() messageCallback: () => void
  authState = authState
  chatState = chatState
  channel: Channel
  messages: IConversationMessage[] = []
  messageKeys: { [key: string]: string } = {}
  scrollTimerId: number | null
  loading = true
  displayContainerOffset = 0
  imagesToPreload: string[] = []
  socket: Socket | null = null
  isSocketConnected = false
  $t: (str: string) => ''
  $baseSocketURL: string
  $browserDetect: any
  $token: string
  isInputFocused = this.chatState.isInputFocused
  inProgress: string | null = null
  forceRerender = 0

  get isSafari() {
    return this.$browserDetect.isSafari
  }

  @Watch('chatId')
  onIdChange(newVal: string, _oldVal: string) {
    this.messages = []
    this.messageKeys = {}
    if (this.channel) this.channel.leave()
    if (this.socket) this.socket!.disconnect()
    this.handleSocketConnection(newVal)
    this.joinChannel()
    this.handleInboundChannelMessages()
  }

  @Watch('chatState.isInputFocused')
  onInputFocusChange(newVal: boolean, _oldVal: string) {
    if (newVal === true) {
      this.markMessagesRead()
    }
  }

  @Watch('chatState.isTabFocused')
  onTabFocusChange(newVal: boolean, _oldVal: string) {
    if (newVal === true) {
      setTimeout(() => {
        this.markMessagesRead()
      }, 2000)
    }
  }

  created() {
    this.handleSocketConnection(this.chatId)
    this.joinChannel()
    this.handleInboundChannelMessages()
    ;(window as any).__forceSmoothScrollPolyfill__ = true
    smoothscroll.polyfill()
  }

  mounted() {
    this.updateContainerOffset()
    window.addEventListener('click', this.clickListener)
  }

  beforeDestroy() {
    if (this.channel) this.channel.leave()
    this.socket!.disconnect()
    window.removeEventListener('click', this.clickListener)
  }

  clickListener() {
    this.markMessagesRead()
  }

  async markMessagesRead() {
    if (this.messages.length) {
      if (
        this.inProgress !== this.messages[this.messages.length - 1].id &&
        this.messages[this.messages.length - 1].unread
      ) {
        this.inProgress = this.messages[this.messages.length - 1].id
        await this.$billie.chat.read(this.chatId, this.authState.user.id!)
        for (const message of this.messages) {
          message.unread = false
        }
        this.forceRerender++
        this.inProgress = null
      }
    }
  }

  handleSocketConnection(channelId: string) {
    this.socket = new Socket(this.$baseSocketURL, { params: { token: this.$token } })
    this.socket.connect()
    this.channel = this.socket.channel(`thread:${this.chatContext}:${channelId}`, {
      token: this.$token
    })
  }

  updateContainerOffset() {
    this.$nextTick(() => {
      if (this.$refs.sendMessage) {
        const sendMessage = (this.$refs.sendMessage as any).$el
        const height = sendMessage.clientHeight
        this.displayContainerOffset = height + (this.isSocketConnected ? 0 : 28)
        this.scrollToBottom()
      }
    })
  }

  joinChannel() {
    this.channel.join().receive('ok', () => {
      this.isSocketConnected = this.socket!.isConnected()
      this.updateContainerOffset()
    })
  }

  handleInboundChannelMessages() {
    this.channel.on('message:new', (payload: IConversationMessage) => {
      if (!this.messageKeys[payload.id]) {
        this.messageKeys[payload.id] = payload.id
        this.messages.push(payload)
        this.waitForImagesToLoad(this.messages)
        if (chatState.isTabFocused && chatState.isInputFocused) {
          this.markMessagesRead()
        }
      }
    })
    this.channel.on('message:history', (payload: { messages: IConversationMessage[] }) => {
      this.messages = payload.messages
      for (const message of this.messages) {
        this.messageKeys[message.id] = message.id
      }
      this.waitForImagesToLoad(this.messages)
      if (chatState.isTabFocused && chatState.isInputFocused && payload.messages.length) {
        this.markMessagesRead()
      }
    })
    this.channel.onError((_reason: string) => {
      this.isSocketConnected = this.socket!.isConnected()
      this.updateContainerOffset()
    })
  }

  scrollToBottom() {
    const scrollDiv = document.getElementById(this.scrollId)
    const scrollFn = () => {
      scrollDiv!.scrollTo({
        top: scrollDiv!.scrollHeight,
        behavior: 'smooth'
      })
      this.scrollTimerId = null
    }
    if (scrollDiv) {
      if (this.scrollTimerId) {
        clearTimeout(this.scrollTimerId)
      }
      this.scrollTimerId = window.setTimeout(scrollFn, 300)
    }
  }

  waitForImagesToLoad(messages: IConversationMessage[]) {
    this.loading = true
    this.generateImageList(messages)
    let imageLoaded = 0
    for (const imageSrc of this.imagesToPreload) {
      const img = new Image()
      img.src = imageSrc

      img.onload = () => {
        imageLoaded++
        if (imageLoaded === this.imagesToPreload.length) {
          this.loading = false
          this.scrollToBottom()
        }
      }
    }
    if (this.imagesToPreload.length === 0) {
      this.scrollToBottom()
    }
  }

  generateImageList(messages: IConversationMessage[]) {
    this.imagesToPreload = []
    for (const msg of messages) {
      if (msg.imageThumbnail) {
        this.imagesToPreload.push(`${(this as any).$baseURL}api/file/${msg.attachment?.id}/small`)
      }
    }
  }
}
