


























































































































































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import DeadlineBar from '../agreement/DeadlineBar.vue'
import { IUser } from '@/shared/storeModules/auth'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
import ClientAgreementTicket from './ClientAgreementTicket.vue'
import { ICareUser } from '@/shared/services/api/tasksApi'
import ClientTicketHelper from '@/shared_legacy/helpers/clientTicketHelper'
import ShortcutsHelperHelper from '@/shared_legacy/helpers/shortcutsHelper'
import ShortcutStore from '@/store/modules/shortcutStore'
import { getModule } from 'vuex-module-decorators'
import StatusBadgeHelper from '@/shared_legacy/helpers/statusBadgeHelper'
//@ts-ignore
import Switches from 'vue-switches'
import LabelField from '@/shared_legacy/components/atoms/LabelField.vue'
import AgreementV2Model from '@/shared/models/AgreementV2Model'

const shortcutState = getModule(ShortcutStore)

@Component({
  components: { DeadlineBar, ClientAgreementTicket, Switches, LabelField }
})
export default class ClientsSubscriptions extends Vue {
  @Prop() loading: boolean
  @Prop({ default: 'subscriptions' }) type: string
  @Prop({ required: true }) agreements: IAgreementV2[]
  @Prop() user: IUser
  @Prop() tasks: ITicket[]
  @Prop() error: boolean
  @Prop({ default: [] }) careUsers: ICareUser[]
  showCancelled = false
  sortBy = 'date_created'
  sortDesc = true
  searchValue = ''
  isExpanded = false
  statusMessages = {
    planned: 'Planned',
    active: 'Active',
    underTermination: 'Under termination',
    terminated: 'Terminated'
  }
  shortcutState = shortcutState
  statusOrder: string[] = Object.values(this.statusMessages)
  shortcutsHelperHelper = new ShortcutsHelperHelper(this.itemsWithCustomIds)

  fields = [
    { key: 'Service', sortable: true },
    { key: 'Supplier', sortable: true },
    { key: 'Status', sortable: true },
    { key: 'Rating', sortable: true },
    { key: 'Ticket', sortable: false }
  ]
  prevKeyDown: any

  get isAdhoc() {
    return this.type === 'oneTimePurchases'
  }

  get tableFields() {
    let tableFieldsArray = [...this.fields]
    if (!this.isAdhoc) {
      tableFieldsArray.splice(2, 0, { key: 'start_date', sortable: true })
    }
    return tableFieldsArray
  }

  get filteredSwitches() {
    if (!this.showCancelled) {
      return this.filteredAgreements.filter(agreement => {
        return agreement.status !== 'Cancelled' && agreement.status !== 'Terminated' && !this.isVoid(agreement)
      })
    } else {
      return this.filteredAgreements
    }
  }
  toggleSwitcher(_type: string) {
    this.showCancelled = !this.showCancelled
  }
  getBadgeColor(status: string) {
    return StatusBadgeHelper.getStatusBadgeColor(status)
  }
  created() {
    moment.locale('en')
  }

  sortCompare(a: IAgreementV2, b: IAgreementV2, key: string, sortDesc: boolean) {
    if (key == 'Service') {
      return this.sortByService(a, b, sortDesc)
    }
    if (key === 'Supplier') {
      return this.sortBySupplier(a, b, sortDesc)
    }
    if (key === 'Status') {
      return this.sortByStatus(a, b, sortDesc)
    }
    return null
  }

  sortByDate(serviceA: IAgreementV2, serviceB: IAgreementV2, _sortDesc: boolean) {
    let a = serviceA.agreementStart || serviceA.created || ''
    let b = serviceB.agreementStart || serviceB.created || ''

    return a < b
  }

  sortByStatus(serviceA: IAgreementV2, serviceB: IAgreementV2, _sortDesc: boolean) {
    return this.statusSorterItem(serviceA) < this.statusSorterItem(serviceB)
  }
  statusSorterItem(service: IAgreementV2) {
    if (this.isVoid(service)) {
      return 'Void'
    } else {
      if (StatusBadgeHelper.isAdHocTerminated(service)) {
        return 'Terminated'
      } else {
        return service.status!
      }
    }
  }

  sortByService(serviceA: IAgreementV2, serviceB: IAgreementV2, sortDesc: boolean) {
    if (this.type === 'subscriptions') {
      return this.activeRevisionTitle(serviceA) < this.activeRevisionTitle(serviceB)
    } else {
      let a = serviceA.revisions[0].title || ''
      let b = serviceB.revisions[0].title || ''
      return sortDesc ? a < b : a > b
    }
  }

  sortBySupplier(serviceA: IAgreementV2, serviceB: IAgreementV2, sortDesc: boolean) {
    let a = serviceA.supplier ? serviceA.supplier.name! : '-'
    let b = serviceB.supplier ? serviceB.supplier.name! : '-'

    return sortDesc ? a < b : a > b
  }

  isVoid(agreement: IAgreementV2) {
    if (agreement?.revisions.length) {
      if (agreement?.revisions.length === 1 && agreement?.revisions[0].deleted) {
        return true
      }
    }
    return false
  }

  rowAttribute(item: IAgreementV2, _type: string) {
    let props: { [key: string]: string } = {
      'data-cy': `agreement-row-item--${item.id}`
    }
    return props
  }

  rowClassList(item: IAgreementV2) {
    let classList = ['agreement-item']
    if (item.id === shortcutState.activeTableItem) {
      classList.push('active-row')
    }
    if (!item.invoiceable) {
      classList.push('yellow-bg')
    }
    return classList
  }

  beforeDestroy() {
    if (this.shortcutsHelperHelper) {
      window.removeEventListener('keydown', this.shortcutsHelperHelper.keydownEventListener)
    }
  }

  rowHovered(item: IAgreementV2) {
    this.shortcutsHelperHelper.activateItem(item.id)
  }

  get filteredAgreements() {
    return this.sortAgreements(StatusBadgeHelper.mapAgreementsWithStatus(this.filterAgreements()))
  }

  get updatedTasks() {
    return this.tasks
  }

  get itemsWithCustomIds() {
    for (let i = 0; i < this.filteredSwitches.length; i++) {
      //@ts-ignore
      this.filteredSwitches[i].key = ClientTicketHelper.ticketUniqueId(this.updatedTasks, this.filteredSwitches[i].id)
    }

    return this.filteredSwitches
  }

  get tableHeight() {
    return this.isExpanded ? '750px' : '295px'
  }

  activeRevisionTitle(agreement: IAgreementV2) {
    const activeRevision = AgreementV2Model.firstActiveRevision(agreement)
    return activeRevision.title
  }

  handleExpandClick() {
    this.isExpanded = !this.isExpanded
  }

  filterAgreements() {
    return this.agreements.filter(agreement => {
      return (
        agreement?.revisions?.[0]?.category !== 'platform_fee' &&
        ((agreement.revisions[0] && agreement.revisions[0].title === null && this.searchValue === '') ||
          (agreement.revisions[0] &&
            agreement.revisions[0].title !== null &&
            agreement.revisions[0]?.title?.toLowerCase().includes(this.searchValue?.toLowerCase())) ||
          agreement.supplier?.name?.toLowerCase().includes(this.searchValue?.toLowerCase()))
      )
    })
  }

  sortAgreements(agreements: IAgreementV2[]) {
    if (this.type === 'subscriptions') {
      return agreements.sort((a, b) => {
        const titleA = a.revisions[0].title.toLowerCase()
        const titleB = b.revisions[0].title.toLowerCase()

        if (this.statusOrder.indexOf(a.status!) < this.statusOrder.indexOf(b.status!)) return -1
        if (this.statusOrder.indexOf(a.status!) > this.statusOrder.indexOf(b.status!)) return 1
        if (titleA < titleB) return -1
        if (titleA > titleB) return 1
        return 0
      })
    } else {
      return agreements.sort((serviceA: IAgreementV2, serviceB: IAgreementV2) => {
        let a = moment(serviceA.agreementStart || serviceA.created || '')
        let b = moment(serviceB.agreementStart || serviceB.created || '')
        //@ts-ignore
        return b - a
      })
    }
  }
}
