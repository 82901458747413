





































import { Vue, Watch, Prop, Component } from 'vue-property-decorator'
import DeleteButton from '../../agreement/element/DeleteButton.vue'
import DocumentFirstRow from './DocumentFirstRow.vue'
import DocumentSecondRow from './DocumentSecondRow.vue'
import { IAgreementElementV2 } from '@/shared/components/interfaces/agreements.interface'
import RRule from 'rrule'
import DocumentProductElement from './DocumentProductElement.vue'

@Component({ components: { DeleteButton, DocumentFirstRow, DocumentSecondRow, DocumentProductElement } })
export default class DocumentElementV2 extends Vue {
  @Prop() id: string
  @Prop() currency: string
  @Prop() language: string
  @Prop() existingElements: { [key: string]: IAgreementElementV2 }
  @Prop() editing: boolean
  @Prop() agreementType: string
  @Prop() startDate: string
  @Prop() showHeader: boolean
  @Prop() existingElement: boolean

  element: IAgreementElementV2 = {
    productId: null,
    description: null,
    quantity: 1,
    clientPrice: 1,
    supplierPrice: 1,
    unit: null,
    vatPercentage: 0,
    pricing: null,
    schedule: null,
  }

  @Watch('startDate')
  onStartDateChange(value: string) {
    const startDate = value ? new Date(value) : new Date()
    this.initialDate(startDate)
  }

  get quantity() {
    return this.element.quantity
  }

  created() {
    if (this.$route.params.type === 'adhoc') {
      delete this.element.pricing
    }
    this.element.productId = this.id
    if (this.existingElements && JSON.stringify(this.existingElements[this.id])) {
      this.element = JSON.parse(JSON.stringify(this.existingElements[this.id]))
      this.$emit('updateElement', this.element)
    }
  }

  mounted() {
    if (!this.element.schedule) {
      const startDate = this.startDate ? new Date(this.startDate) : new Date()
      this.initialDate(startDate)
    }
  }

  initialDate(val: Date) {
    let data: { dtstart: Date; count?: number } = {
      dtstart: new Date(Date.UTC(val.getUTCFullYear(), val.getUTCMonth(), val.getUTCDate())),
    }
    if (this.$route.params.type === 'adhoc') {
      data = {
        ...data,
        count: 1,
      }
    }
    this.updateElementRecurrence(data)
  }

  updateElementRecurrence(dataObj: any) {
    let ruleReset: any = {
      bymonthday: null,
      count: null,
      bymonth: null,
      wkst: null,
      byhour: null,
      byminute: null,
      bysecond: null,
    }

    let recurrence = this.element.schedule
    let ruleInitialReset = {}
    if (!recurrence || !recurrence.length) {
      recurrence = ''
      ruleInitialReset = {
        freq: RRule.WEEKLY,
        byweekday: [],
        interval: 1,
      }
    }

    let rule = RRule.fromString(recurrence)
    if (rule.origOptions.byweekday === undefined) {
      ruleReset = { ...ruleReset, byweekday: [] }
    }

    let fullDataObj = {
      ...rule.origOptions,
      ...ruleReset,
      ...ruleInitialReset,
      ...dataObj,
    }

    rule = new RRule(fullDataObj)
    this.updateVal(rule.toString(), 'schedule')
  }

  updateVal(value: string | number, field: keyof IAgreementElementV2) {
    //@ts-ignore
    this.element[field] = value
    this.$emit('updateElement', this.element)
  }
}
