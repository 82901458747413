










































































































import { Vue, Prop } from 'vue-property-decorator'
import AgreementSetup from '@/components/agreement/AgreementSetup.vue'
import { IUser } from '@/shared/storeModules/auth'
import AgreementContentDetails from '@/components/agreement/AgreementContentDetails.vue'
import moment from 'moment'
import Component from 'vue-class-component'
import ChatWithDeadlineV2 from '../chat/ChatWithDeadlineV2.vue'
import {
  IAgreementRevision,
  IAgreementV2,
  IAgreementRevisionV2
} from '@/shared/components/interfaces/agreements.interface'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
import AgreementSchedule from './schedule/AgreementSchedule.vue'
import uuid from 'uuid-random'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import ClientDetailsHeader from '../clients/ClientDetailsHeader.vue'
import StatusBadgeHelper from '@/shared_legacy/helpers/statusBadgeHelper'
import ICareUser from '@/shared/services/api/tasksApi'
import CalendarEvents from '@/components/agreement/calendar/CalendarEvents.vue'

@Component({
  components: {
    AgreementSetup,
    AgreementContentDetails,
    ChatWithDeadlineV2,
    AgreementSchedule,
    ClientDetailsHeader,
    CalendarEvents
  }
})
export default class AgreementDetails extends Vue {
  @Prop({ required: true }) tasks: ITicket[]
  @Prop({ required: true }) language: string
  @Prop({ required: true }) careUsers: ICareUser[]
  @Prop({ required: true }) clientDetails: IClientData | null
  @Prop({ required: true }) agreement: IAgreementV2
  @Prop({ required: true }) clientId: string
  @Prop() user: IUser
  editMode = false
  revision: IAgreementRevision = {} as IAgreementRevision
  endDate = ''
  terminateModalOpen = false
  terminateAgreementId: string | null = null
  editTitle = false
  scheduleKey = 'agreementSchedule'
  activeRevision: IAgreementRevision | IAgreementRevisionV2 | null = null
  componentKey = uuid()
  editRevisionIndex = 0
  selectedRevisionIndex = 0
  isAddingNewRevision = false
  showClientDetailsModal = false

  get revisions() {
    return this.agreement?.revisions.slice().reverse()
  }
  get isAdhoc() {
    return this.agreement?.invoiceSchedule === 'once'
  }
  get agreementTitle() {
    if (this.isAddingNewRevision) return 'Add new revision to subscription agreement'
    if (this.editMode) {
      const startDate = this.agreement.revisions[this.editRevisionIndex].startDate
      return `Edit ${this.isAdhoc ? 'ad hoc' : 'subscription'} agreement ${
        this.isAdhoc ? '' : `(revision ${startDate})`
      }`
    }
    return `${this.isAdhoc ? 'Ad hoc' : 'Subscription'} agreement details`
  }

  toggleClientDetailsModal() {
    this.showClientDetailsModal = !this.showClientDetailsModal
  }

  openOriginalRequest() {
    this.$router.push(`/clients/${this.agreement.client.id}/request/${this.agreement.originatingRequestId}`)
  }

  updateClientDetails(res: IClientData) {
    this.clientDetails = res
  }
  range(startDate: string, endDate: string) {
    if (!endDate) {
      return startDate
    } else if (endDate === startDate) {
      return 'Terminated'
    } else {
      return moment(startDate).format('LL') + ' - ' + this.getPreviousDay(endDate)
    }
  }

  createNewRevision(addNewRevision: (value: boolean) => void) {
    this.isAddingNewRevision = true
    addNewRevision(true)
  }

  editAgreement(index: number) {
    this.revision = JSON.parse(JSON.stringify(this.revisions![index]))
    this.revision.startDate = ''
    this.editMode = true
  }

  rerenderSchedule() {
    this.scheduleKey += Math.random().toString().slice(2)
  }

  get agreementStatus() {
    if (this.isAdhoc) {
      return this.adhocStatus
    }
    return this.subscriptionStatus
  }

  get badgeColor() {
    return StatusBadgeHelper.getStatusBadgeColor(this.agreementStatus)
  }

  get adhocStatus() {
    return StatusBadgeHelper.adhocAgreementStatus(this.agreement)
  }

  get subscriptionStatus() {
    return StatusBadgeHelper.subscriptionAgreementStatus(this.agreement)
  }

  setActiveRevision(revision: IAgreementRevisionV2) {
    this.activeRevision = { ...revision }
    const activeIndex = revision ? this.revisions.findIndex(x => x.revisionId === this.agreement!.activeRevisionId) : 0
    this.selectedRevisionIndex = activeIndex === -1 ? 0 : activeIndex
  }

  getPreviousDay(date: string) {
    return moment(date).subtract(1, 'days').format('LL')
  }

  edit(index: number) {
    this.editMode = true
    this.editRevisionIndex = index
  }

  shouldUpdate() {
    this.$emit('shouldUpdate')
    this.editMode = false
  }

  cancelEdit() {
    this.editMode = false
    this.isAddingNewRevision = false
  }
}
