































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import RevisionMixin from '@/mixins/RevisionMixin'
import AgreementRevisionBody from './AgreementRevisionBody.vue'

@Component({
  components: { AgreementRevisionBody },
  mixins: [RevisionMixin],
})
export default class ShowMoreRevision extends Vue {
  @Prop({ required: true }) revisions: IAgreementRevisionV2[]
  @Prop({ required: true }) currency: string
  @Prop({ required: true }) currentRevisionIndex: number
  @Prop({ required: true }) prepaid: boolean
  @Prop({ required: true }) revisionBaseIndex: number

  activeRevision: IAgreementRevisionV2 | null = null
  revisionIndex = -1

  edit() {
    this.$emit('edit', this.revisionIndex)
  }

  showMoreRevisionContent(revision: IAgreementRevisionV2, index: number) {
    this.activeRevision = revision
    this.revisionIndex = this.revisionBaseIndex + index
    this.$emit('activeRevision', this.revisionIndex)
  }
}
