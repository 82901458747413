



































































































































import moment from 'moment'

import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import Component from 'vue-class-component'
import ClientStore from '@/shared_legacy/storeModules/client'
import CreateClient from '../components/clients/CreateClient.vue'
import { IAddress } from '@/shared/components/interfaces/supplierData.interface'
import Country from '@/shared_legacy/storeModules/country'
import SentNotificationModel from '@/shared/models/SentNotificationModel'
import { ISentNotification } from '@/shared/components/interfaces/finance.interface'
import ClientDetailsModel from '@/shared/models/ClientDetailsModel'

const clientStore = getModule(ClientStore)

interface IClientData {
  address: IAddress
  name: string
  insertedAt: string
  numEmployees: string
  country: string
  countryCode: string
  clientSince: string
  id: string
  overdueNotifications: ISentNotification[]
  status: string
}

const countryState = getModule(Country)

@Component({
  components: { CreateClient },
  metaInfo: {
    title: 'Clients | Good Monday'
  }
})
export default class Clients extends Vue {
  SentNotificationModel = SentNotificationModel
  modalShow = false
  countryState = countryState
  clientStore = clientStore
  searchKeys = ['name', 'contact_person']
  filterBy = ''
  sortBy = 'name'
  sortDesc = false
  perPage = 50
  totalEmployees = 0
  avgEmployees = 0
  totalAgre = 0
  totalGmv = 0

  clientsData: IClientData[] | null = null
  originalClientsData: IClientData[] | null = null
  currentPage = 1
  clientStatusOptions = [
    { text: 'Paying', value: 'paying' },
    { text: 'Trial', value: 'trial' },
    { text: 'Free', value: 'free' },
    { text: 'Under termination', value: 'under_termination' },
    { text: 'Terminated', value: 'terminated' }
  ]
  clientStatusFilters = ['paying', 'trial', 'free', 'under_termination']
  fields = [
    { key: 'name', sortable: true },
    { key: 'numEmployees', sortable: true },
    { key: 'country', sortable: true },
    { key: 'clientSince', sortable: true },
    { key: 'status', sortable: true },
    { key: 'overduePayments', sortable: true }
  ]

  get totalItems() {
    return this.filteredClients?.length ?? 0
  }
  get totalPages() {
    return Math.ceil(this.totalItems / this.perPage)
  }

  get filteredClients() {
    const filteredByCountry = this.filterClientsByCountry()
    const filteredByStatus = this.filterClientsByStatus(filteredByCountry)
    const filteredBySearch = this.filterClientsBySearch(filteredByStatus)
    return filteredBySearch
  }

  statusBadge(status: string) {
    return ClientDetailsModel.clientStatusBadge(status)
  }

  rowAttribute(item: IClientData, _type: string) {
    return {
      'data-cy': `client-row-item--${item.name.replace(/[\s&]/g, '-')}${item.id}`
    }
  }

  filterClientsByCountry() {
    if (this.originalClientsData) {
      if (!this.selectedCountry) this.clientsData = this.originalClientsData
      if (this.selectedCountry === 'DK') {
        this.clientsData = this.originalClientsData!.filter(d => {
          return d.countryCode === 'DK'
        })
      }
      if (this.selectedCountry === 'UK') {
        this.clientsData = this.originalClientsData!.filter(d => {
          return d.countryCode === 'UK'
        })
      }
      return this.clientsData
    }
    return []
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }

  openCloseModal() {
    this.modalShow = !this.modalShow
  }

  filterClientsBySearch(clients: IClientData[] | null) {
    const val = this.filterBy.toLowerCase()
    if (clients) {
      let filteredClients = clients.filter(d => {
        return (
          Object.keys(d)
            .filter(k => this.searchKeys.includes(k))
            //@ts-ignore
            .map(k => String(d[k]))
            .join('|')
            .toLowerCase()
            .indexOf(val) !== -1 || !val
        )
      })
      return filteredClients
    } else return
  }

  filterClientsByStatus(clients: IClientData[] | null) {
    if (clients) {
      return clients.filter(client => (this.clientStatusFilters as Array<string>).includes(client.status))
    } else return []
  }

  getTimeSince(date: string) {
    return date ? moment(date).fromNow() : ''
  }
  addClientToAllCompaniesList(client: IClientData) {
    client.country = client.address.country
    client.countryCode = client.address.countryCode
    client.clientSince = moment(client.insertedAt).format('D MMM / YYYY')
    this.clientsData!.push(client)
  }
  created() {
    this.clientStore.getClients().then(res => {
      for (let client of res) {
        client.country = client.address.country
        client.countryCode = client.address.countryCode
        client.clientSince = moment(client.insertedAt).format('D MMM / YYYY')
        if (client.overdueNotifications) {
          client.overdueNotifications.sort((a: any, b: any) => {
            //@ts-ignore
            return moment(b.date) - moment(a.date)
          })
        }
      }
      this.clientsData = res
      this.originalClientsData = res
    })
  }
}
