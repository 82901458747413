import moment from 'moment'
import { ICalendarForm } from '../components/interfaces/calendar.interface'

/**
 * This is a model working over the ICalendarForm interface
 *
 * Functions should be pure, static and accept the ICalendarForm
 * interface as the first parameter.
 *
 * The only exception to accepting ICalendarForm as the first
 * parameter is when using the class as a static factory to build
 * the interface from another data structure.
 *
 * Example:
 * public static from_form(form: ICalendarForm): ICalendarForm
 */

export default abstract class CalendarFormModel {
  public static isAllDay(calendarForm: ICalendarForm): boolean {
    return moment(calendarForm.range.start).format('HH:mm:ss') === '00:00:00' && moment(calendarForm.range.end).format('HH:mm:ss') === '00:00:00'
  }

  public static generateAllDayIcal(calendarForm: ICalendarForm): string {
    return `DTSTART;VALUE=DATE:${moment(calendarForm.range.start).format('YYYYMMDD')}\nDTEND;VALUE=DATE:${moment(
      calendarForm.range.end
    ).format('YYYYMMDD')}
	${calendarForm.repeatPattern}`
  }

  public static generateIcal(calendarForm: ICalendarForm): string {
    return `DTSTART:${moment(calendarForm.range.start).utc().format('YYYYMMDD[T]HHmmss[Z]')}\nDTEND:${moment(
      calendarForm.range.end
    )
      .utc()
      .format('YYYYMMDD[T]HHmmss[Z]')}
	  ${calendarForm.repeatPattern}`
  }
}
