












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelField from '@/shared_legacy/components/atoms/LabelField.vue'

@Component({
  components: { LabelField }
})
export default class RadioInput extends Vue {
  @Prop({ required: true }) label: string
  @Prop({ required: true }) value: string
  @Prop({ default: false }) disabled: boolean

  get inputModel() {
    return this.value
  }

  set inputModel(value) {
    this.emitValue(value)
  }

  emitValue(value: string) {
    this.$emit('input', value)
  }

  handleClick() {
    if (!this.disabled) {
      this.emitValue(this.label)
    }
  }
}
