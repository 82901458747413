





























































































































































import SortByHelper from '@/shared/helpers/sortByHelper'
import moment from 'moment'
import Vue from 'vue'
import Component from 'vue-class-component'
import { IOverdueClient } from '../shared/components/interfaces/finance.interface'
import FinanceApi from '../shared/services/api/financeApi'
import PaymentRemindersModal from './payments/PaymentRemindersModal.vue'
import SentNotificationModel from '../shared/models/SentNotificationModel'
import InvoiceApi from '@/shared/services/api/invoiceApi'
import { getModule } from 'vuex-module-decorators'
import Country from '@/shared_legacy/storeModules/country'

const countryState = getModule(Country)

@Component({
  components: { PaymentRemindersModal },
  metaInfo: {
    title: 'Clients | Good Monday'
  }
})
export default class Payments extends Vue {
  countryState = countryState
  overdueClients: IOverdueClient[] = []
  showPaymentReminderModal = false
  fields = [
    { key: 'name', sortable: true, title: 'Name' },
    { key: 'daysSinceOldestOverdueInvoice', sortable: true, title: 'Days overdue' },
    { key: 'dueAmount', sortable: true, title: 'Due amount' },
    { key: 'clientSince', sortable: true, title: 'Client since' }
  ]
  sortBy = 'name'
  sortDesc = false
  ascending = true
  key = 'name'
  touched = false
  searchValue = ''
  expanded: string | null = null
  SentNotificationModel = SentNotificationModel

  sortClicked(key: string) {
    this.ascending = !this.ascending
    this.key = key
    this.touched = true
  }
  get selectedCountry(): string | null {
    return this.countryState.selectedCountry
  }
  get selectedClient() {
    return this.sortedClients.find(x => {
      return x.clientId === this.expanded
    })
  }
  get clientsSortedByClientSince() {
    return this.overdueClients.sort(function (a, b) {
      return new Date(a.clientSince).getTime() - new Date(b.clientSince).getTime()
    })
  }

  get sortedClients() {
    const isDate = this.key === 'clientSince'
    if (this.key === 'dueAmount') {
      return SortByHelper.sortByNumber(this.filteredClients, this.key, this.ascending)
    } else {
      return SortByHelper.sortByText(this.filteredClients, this.key, this.ascending, isDate)
    }
  }

  get filteredClients() {
    const countryToCurrency = new Map<string, string>([
      ['DK', 'DKK'],
      ['UK', 'GBP']
    ])
    const filteredBySearch = this.clientsSortedByClientSince.filter(client => {
      return this.searchValue === '' || client.name.toLowerCase().includes(this.searchValue.toLowerCase())
    })
    const filteredByCountry = filteredBySearch.filter(client => {
      return !this.selectedCountry || client.currency === countryToCurrency.get(this.selectedCountry)
    })
    return filteredByCountry
  }
  async openInvoice(invoiceId: string) {
    const pdfData = await InvoiceApi.getInvoicePdf(invoiceId)
    if (pdfData) {
      const blob = new Blob([pdfData], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
  }
  toggleModal() {
    this.showPaymentReminderModal = !this.showPaymentReminderModal
  }

  async sendReminder(n: number) {
    const resp = await FinanceApi.changeStatus(this.selectedClient.clientId, n)
    if (this.selectedClient.paymentBalance.overdueNotifications) {
      this.selectedClient.paymentBalance.overdueNotifications.push(resp)
    } else {
      this.selectedClient.paymentBalance.overdueNotifications = [resp]
    }
  }

  async created() {
    this.overdueClients = await FinanceApi.getOverdueClients()
    for (const client of this.overdueClients) {
      client.paymentBalance.transactions.sort((a: any, b: any) => {
        //@ts-ignore
        return moment(a.date) - moment(b.date)
      })
      if (client.paymentBalance.overdueNotifications) {
        client.paymentBalance.overdueNotifications.sort((a: any, b: any) => {
          //@ts-ignore
          return moment(b.date) - moment(a.date)
        })
      }
    }
  }
}
