




import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

type Currency = 'DKK' | 'EUR' | 'GBP' | 'USD'

@Component({})
export default class Price extends Vue {
  @Prop({ required: true }) readonly price!: number
  // Currency in the format 'DKK', 'USD' or similar
  @Prop({ required: true }) readonly currency!: Currency
  // Locale in the format 'DK', 'da-DK' or similar
  @Prop({ required: true }) readonly locale!: string

  localeOptions = {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  get formattedPrice() {
    const price = this.price
    return price.toLocaleString(this.locale, { ...this.localeOptions, currency: this.currency })
  }
}
