import { render, staticRenderFns } from "./UpdateDocument.vue?vue&type=template&id=207b4876&scoped=true&"
import script from "./UpdateDocument.vue?vue&type=script&lang=ts&"
export * from "./UpdateDocument.vue?vue&type=script&lang=ts&"
import style0 from "./UpdateDocument.vue?vue&type=style&index=0&id=207b4876&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207b4876",
  null
  
)

export default component.exports