





















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import AdhocAgreement from './AdhocAgreement.vue'
import SubscriptionAgreement from './SubscriptionAgreement.vue'
import AgreementAddNewRevision from './schedule/AgreementAddNewRevision.vue'
import { IProposalV2 } from '@/shared/services/api.interface'

@Component({
  components: { AdhocAgreement, SubscriptionAgreement, AgreementAddNewRevision }
})
export default class AgreementContentDetails extends Vue {
  @Prop({ required: true }) agreement: IAgreementV2 | IProposalV2
  @Prop({ required: true }) setActiveRevision: () => void
  @Prop({ required: true }) agreementType: 'subscription' | 'adhoc'
  @Prop({ required: true }) editMode: boolean
  @Prop({ default: false }) isProposal: boolean
  @Prop({ required: true }) language: string
  @Prop({ required: true }) editRevisionIndex: number
  agreementOptions = {
    adhoc: AdhocAgreement,
    subscription: SubscriptionAgreement
  }
  newRevisionTab = false

  get isV1Proposal() {
    return this.agreement.invoiceSchedule == null && this.agreement.prepaid == null
  }

  addNewRevision(value: boolean) {
    this.newRevisionTab = value
    if (!value) {
      this.$emit('cancel')
    }
  }

  edit(index: number) {
    this.$emit('edit', index)
  }

  shouldUpdate() {
    this.$emit('shouldUpdate')
  }

  cancel() {
    this.$emit('cancel')
  }
}
