





























import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Vue from 'vue'
import moment from 'moment'

@Component({
  components: {},
})
export default class DeadlineBar extends Vue {
  @Prop({ required: true }) expiresAt: string
  @Prop({ default: false }) showflex: boolean
  @Prop({ default: false }) textLeft: boolean
  @Prop({ default: true }) textRight: boolean

  get due() {
    let dueObject = {
      percent: 10,
      class: 'success',
      text: this.expiresAt ? moment(this.expiresAt).fromNow() : this.$t('labels.chatWidget.noDeadline'),
    }
    let diff = moment(Date.now()).diff(this.expiresAt, 'minutes')

    if (typeof diff === 'number' && isNaN(diff)) return { percent: 0, class: '', text: dueObject.text }
    if (diff < 0) {
      if (diff > -60) {
        let percent = 100 - Math.floor((Math.abs(diff) / 60) * 100)

        if (percent > 10) {
          dueObject.percent = percent
        }
        if (percent > 50) {
          dueObject.class = 'warning'
        }
      }
    } else {
      dueObject.percent = 100
      dueObject.class = 'danger'
    }
    return dueObject
  }
}
