



























import { Vue, Component, Prop } from 'vue-property-decorator'
import CircularProgressBar from '@/shared_legacy/components/atoms/CircularProgressBar.vue'

@Component({
  components: { CircularProgressBar }
})
export default class ImageModal extends Vue {
  @Prop({ required: true }) image: string
  $appName: string
  showModalContent = false

  created() {
    window.addEventListener('keydown', this.onKeyDown)
    const convoGrid = document.getElementById('conversations-grid') as HTMLDivElement
    convoGrid.style.setProperty('z-index', '100')

    if (this.$appName === 'platform') {
      const mainContent = document.getElementById('main-content') as HTMLDivElement
      mainContent.style.setProperty('z-index', '100')
    } else {
      const navBarTop = document.querySelector('.layout-container nav.layout-navbar') as HTMLElement
      const navBarLeft = document.querySelector('.layout-inner nav.layout-sidenav') as HTMLElement
      navBarTop.style.setProperty('z-index', '1')
      navBarLeft.style.setProperty('z-index', '1', 'important')
    }
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
    const convoGrid = document.getElementById('conversations-grid') as HTMLDivElement
    convoGrid.style.setProperty('z-index', null)

    if (this.$appName === 'platform') {
      const mainContent = document.getElementById('main-content') as HTMLDivElement
      mainContent.style.setProperty('z-index', null)
    } else {
      const navBarTop = document.querySelector('.layout-container nav.layout-navbar') as HTMLElement
      const navBarLeft = document.querySelector('.layout-inner nav.layout-sidenav') as HTMLElement
      navBarTop.style.setProperty('z-index', null)
      navBarLeft.style.setProperty('z-index', null)
    }
  }

  onKeyDown(e: KeyboardEvent) {
    e.preventDefault()
    if (e.key === 'Escape') {
      this.$emit('close')
    }
  }
}
