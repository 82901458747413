import { ISentNotification } from '../components/interfaces/finance.interface'

export default abstract class SentNotificationModel {
  public static notificationText(notification: ISentNotification) {
    let notificationNumber = ''
    if (notification.level === 1) {
      notificationNumber = ' First'
    } else if (notification.level === 2) {
      notificationNumber = ' Second'
    } else if (notification.level === 3) {
      notificationNumber = ' Third'
    } else if (notification.level === 4) {
      notificationNumber = ' Fourth'
    }
    const text = notification.level === 0 ? ' All reminders reset' : notificationNumber + ' reminder sent'

    return text
  }
}
