










































































































































import moment from 'moment'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AgreementScheduleRow from './AgreementScheduleRow.vue'
import AgreementScheduleNewRow from './AgreementScheduleNewRow.vue'
import AgreementScheduleStore from '@/shared_legacy/storeModules/agreementSchedule'
import { getModule } from 'vuex-module-decorators'
import Agreements from '@/shared_legacy/storeModules/agreements'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import agreementsApi from '@/shared/services/api/agreementsApi'
import NotReadyForInvoiceModal from './NotReadyModal.vue'

import { IAgreementInvoiceDataV2, IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import AgreementHelper from '@/helpers/AgreementHelper'

const agreementScheduleState = getModule(AgreementScheduleStore)
const agreementsState = getModule(Agreements)

@Component({ components: { AgreementScheduleRow, AgreementScheduleNewRow, NotReadyForInvoiceModal } })
export default class AgreementSchedule extends Vue {
  @Prop({ required: true }) language: string
  @Prop() clientDetails: IClientData

  agreementScheduleState = agreementScheduleState
  agreementsState = agreementsState
  months: string[] = []
  selectedMonth = ''
  editingRow = false
  showModal = false
  errorMessage = ''
  formatMonth = AgreementHelper.formatMonth

  get elementsPricingById() {
    const elementsPricing: { [key: string]: string | undefined } = {}

    if (this.agreement.revisions[0].prepaymentPatterns) {
      for (var element of this.agreement.revisions[0].prepaymentPatterns as any) {
        elementsPricing[element!.productId] = element.pricing
      }
      return elementsPricing
    }
    return null
  }

  get agreement() {
    return this.agreementsState.agreementV2Data as IAgreementV2
  }

  get agreementSchedule() {
    return this.agreementScheduleState.agreementScheduleData
  }

  get totalClientPrice() {
    if (!this.agreementSchedule) return 0
    const perMonthPriceById: { [key: string]: boolean } = {}
    const flatArray = Object.values(this.agreementSchedule).reduce((acc, cur) => {
      return acc.concat(cur)
    }, [])

    if (this.agreement.invoiceSchedule === 'once') {
      return flatArray.reduce((acc: number, cur: IAgreementInvoiceDataV2) => {
        return acc + cur.clientPrice * cur.quantity
      }, 0)
    }

    return flatArray.reduce((acc: number, cur: IAgreementInvoiceDataV2) => {
      if (this.elementsPricingById === null) return 0
      if (this.elementsPricingById[cur.productId] === 'per_month') {
        if (perMonthPriceById[cur.productId]) return acc
        else {
          perMonthPriceById[cur.productId] = true
          return acc + cur.clientPrice * cur.quantity
        }
      } else return acc + cur.clientPrice * cur.quantity
    }, 0)
  }

  get totalSupplierPrice() {
    if (!this.agreementSchedule) return 0
    const perMonthPriceById: { [key: string]: boolean } = {}
    const flatArray = Object.values(this.agreementSchedule).reduce((acc, cur) => {
      return acc.concat(cur)
    }, [])

    if (this.agreement.invoiceSchedule === 'once') {
      return flatArray.reduce((acc: number, cur: IAgreementInvoiceDataV2) => {
        return acc + cur.supplierPrice * cur.quantity
      }, 0)
    }

    return flatArray.reduce((acc: number, cur: IAgreementInvoiceDataV2) => {
      if (this.elementsPricingById === null) return 0
      if (this.elementsPricingById[cur.productId] === 'per_month') {
        if (perMonthPriceById[cur.productId]) return acc
        else {
          perMonthPriceById[cur.productId] = true
          return acc + cur.supplierPrice * cur.quantity
        }
      } else return acc + cur.supplierPrice * cur.quantity
    }, 0)
  }

  addMissingDetails() {
    this.showModal = false
    this.$emit('toggleModal')
  }

  async readyForInvoiceAction() {
    try {
      if (this.agreement.invoiceSchedule === 'once') {
        await agreementsApi.markAdhocAsReady(this.agreement.id)
      } else {
        await agreementsApi.markSubscriptionAsReady(this.agreement.id, this.selectedMonth)
      }
      this.$toasted.success('Marked as ready for Invoicing')
      this.agreementScheduleState.setMarked(true)
    } catch (e) {
      this.errorMessage = e.message
      this.showModal = true
    }
  }
  async unmarkAsReadyForInvoice() {
    try {
      if (this.agreement.invoiceSchedule === 'once') {
        await agreementsApi.unmarkAdhocAsReady(this.agreement.id)
      } else {
        await agreementsApi.unmarkSubscriptionAsReady(this.agreement.id, this.selectedMonth)
      }
      this.$toasted.success('Unmarked as ready for Invoicing')
      this.agreementScheduleState.setMarked(false)
    } catch (e) {
      this.$toasted.error('Error unmarking as ready', { icon: 'ion ion-ios-warning' })
    }
  }

  decreaseMonth() {
    this.selectedMonth = AgreementHelper.decreaseMonth(this.months, this.selectedMonth)
  }

  increaseMonth() {
    this.selectedMonth = AgreementHelper.increaseMonth(this.months, this.selectedMonth)
  }

  loadSchedule(month: string) {
    agreementScheduleState.getAgreementSchedule({
      agreementId: this.$route.params.agreementId,
      month,
      type: this.agreement.invoiceSchedule
    })
  }
  @Watch('selectedMonth')
  onSelectedMonthChange(value: string) {
    if (value) {
      this.loadSchedule(this.selectedMonth)
    }
  }

  get isPastAgreement() {
    const agreementEnd = this.agreement.agreementEnd
    if (agreementEnd) {
      return moment().isAfter(moment(agreementEnd))
    }
    return false
  }

  mounted() {
    this.months = AgreementHelper.generateMonths(this.agreement)
    this.selectedMonth = moment().format('YYYY-MM')
    if (this.months.length && !this.months.includes(this.selectedMonth)) {
      if (this.isPastAgreement) {
        this.selectedMonth = this.months[this.months.length - 1]
      } else {
        this.selectedMonth = this.months[0]
      }
    }
  }
}
