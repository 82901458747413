






































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import Component from 'vue-class-component'
import ClientStore from '@/shared_legacy/storeModules/client'
import Country from '@/shared_legacy/storeModules/country'

const clientStore = getModule(ClientStore)
const countryState = getModule(Country)

@Component({
  components: {}
})
export default class SearchBox extends Vue {
  searchString = ''
  currentItem = 0
  isHovering = false
  clientStore = clientStore
  countryState = countryState


  mounted() {
    this.clientStore.getClients()
    const country = window.localStorage.getItem('selectedCountry')
    if (country) {
      this.countryState.selectCountry(country)
    }
  }

  get showResults() {
    return this.searchString.length > 1
  }

  get clients() {
    this.currentItem = 0
    return this.clientStore.filterClients(this.searchString)
      .filter(c => c.item.address.countryCode === this.countryState.selectedCountry || this.countryState.selectedCountry === null)
      .map(client => {
        return { ...client.item }       
    })
  }

  closeSearchBar() {
    this.currentItem = 0
    this.searchString = ''
  }

  setCurrentIndex(index: number) {
    return (this.currentItem = index)
  }
  
  hoverClient(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      if (this.currentItem === this.clients.length - 1) {
        this.currentItem = 0
      } else {
        this.currentItem++
      }
    }
    if (event.key === 'ArrowUp') {
      if (this.currentItem === 0) {
        this.currentItem = this.clients.length - 1
      } else {
        this.currentItem--
      }
    }
    if (event.key === 'Escape') {
      this.closeSearchBar()
    }
  }

  goToClientByKey() {
    if (this.currentItem !== -1 && this.clients[this.currentItem]) {
      const id = this.clients[this.currentItem].id
      this.goToClient(id as string)
    }
  }
  
  goToClient(id: string) {
    this.searchString = ''
    if (this.$route.params.id !== id) {
      this.$router.push({ name: 'ClientDetails', params: { id } })
    }
  }
}
