







import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Progress extends Vue {
  @Prop() readonly progress!: number
  @Prop() readonly color!: 'green' | 'yellow' | 'red'

  get width() {
    if (this.progress < 0) {
      return 0
    } else if (this.progress > 100) {
      return 100
    } else {
      return this.progress
    }
  }

  get backgroundColor() {
    if (this.color == 'red') {
      return '#d9534f'
    } else if (this.color == 'yellow') {
      return '#FFD950'
    } else {
      return '#02BC77'
    }
  }
}
