


import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FeatureFlags from '@/shared/FeatureFlags'

@Component({
  components: {}
})
export default class FeatureFlag extends Vue {
  @Prop() on?: string
  @Prop() off?: string

  get showSlot() {
    if (this.on) {
      return FeatureFlags.isEnabled(this.on)
    }
    if (this.off) {
      return !FeatureFlags.isEnabled(this.off)
    }
    return null
  }

  render() {
    return this.showSlot ? this.$slots.default![0] : null // feature flag should only wrap 1 element
  }
}
