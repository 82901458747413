

































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import uuid from 'uuid-random'
import moment from 'moment'
import AddOrEditCalendarEvent from '@/components/agreement/calendar/AddOrEditCalendarEvent.vue'
import { ICalendarEventItemV2, ICalendarForm } from '@/shared/components/interfaces/calendar.interface'
import CalendarFormModel from '../../../shared/models/CalendarFormModel'
import CalendarEventItemV2Model from '@/shared/models/CalendarEventItemV2Model'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

@Component({ components: { AddOrEditCalendarEvent, DatePicker } })
export default class CalendarEvents extends Vue {
  @Prop({ required: true }) editMode: boolean
  @Prop({ required: true }) link: string
  @Prop({ required: true }) events: ICalendarEventItemV2[]
  @Prop({ required: false, default: '' }) defaultTitle: string
  event: ICalendarEventItemV2 | null = null
  moment = moment
  showDeleteModal = false
  showEditModal = false
  editType: 'all' | 'one' | 'following' = 'all'
  eventToDelete: ICalendarEventItemV2 | null = null
  activeMonth = moment().format('YYYY-MM')
  selectedDate = null

  fetchedEvents: ICalendarEventItemV2[] = []
  selectAttribute = {
    dot: 'white'
  }
  @Watch('selectedDate')
  onDateChange() {
    this.handleDayClick({ id: moment(this.selectedDate) })
  }

  @Watch('events')
  onEventChange(value: ICalendarEventItemV2[]) {
    this.populateEvents(value)
  }

  get eventTimes() {
    return this.events.map(event => {
      return event.startTime
    })
  }

  get calendarAttributes() {
    return [
      {
        key: 'today',
        highlight: true,
        dates: moment().format()
      },
      {
        dot: true,
        dates: this.eventTimes
      }
    ]
  }

  get sortedAndFilteredEvents() {
    let filteredEvents = this.events.filter(event => {
      let copyOfEndTime = event.endTime
      if (CalendarEventItemV2Model.isAllDay(event)) {
        copyOfEndTime = moment(event.endTime).subtract(1, 'days').format('YYYY-MM-DD')
      }
      if (
        moment(event.startTime).isBetween(this.startOfMonth, this.endOfMonth, 'day', '[]') ||
        moment(copyOfEndTime).isBetween(this.startOfMonth, this.endOfMonth, 'day', '[]')
      )
        return true
      else {
        return false
      }
    })
    //@ts-ignore
    return filteredEvents.sort((a, b) => (new Date(a.startTime) as any) - (new Date(b.startTime) as any))
  }

  get startOfMonth() {
    return moment(this.activeMonth).startOf('month').format('YYYY-MM-DD')
  }

  get endOfMonth() {
    return moment(this.activeMonth).endOf('month').format('YYYY-MM-DD')
  }

  formatDatesForDisplaying(event: ICalendarEventItemV2) {
    let dayMatch: number = moment(event.endTime).diff(event.startTime, 'days')
    if (CalendarEventItemV2Model.isAllDay(event)) {
      if (dayMatch === 1) {
        return moment(event.startTime).local().format('MMM D, ddd')
      } else {
        return (
          moment(event.startTime).local().format('MMM D, ddd') +
          ' - ' +
          moment(event.endTime).subtract(1, 'days').local().format('MMM D, ddd')
        )
      }
    } else {
      let minutesMatch: number = moment(event.endTime).diff(event.startTime, 'minutes')
      if (dayMatch > 0 && minutesMatch > 0) {
        return (
          moment(event.startTime).local().format('MMM D, ddd HH:mm') +
          ' - <span class="d-block">' +
          moment(event.endTime).local().format('MMM D, ddd HH:mm') +
          '</span>'
        )
      } else if (dayMatch === 0 && minutesMatch > 0) {
        return (
          moment(event.startTime).local().format('MMM D, ddd HH:mm') +
          ' - ' +
          moment(event.endTime).local().format('HH:mm')
        )
      } else {
        //if (dayMatch === 0 && minutesMatch === 0)
        return moment(event.startTime).local().format('MMM D, ddd HH:mm')
      }
    }
  }

  handleDayClick(e: any) {
    const dayTime = moment(e.id).startOf('day')
    this.event = {
      title: this.defaultTitle,
      isRecurring: false,
      startTime: dayTime.format(),
      endTime: moment(dayTime).add(1, 'day').format(),
      type: 'arrival',
      id: '',
      ical: '',
      link: ''
    }
    this.$emit('newEvent')
  }

  handleMonthChange(page: any) {
    let formatedMonth = `0${page.month}`.slice(-2)
    this.activeMonth = `${page.year}-${formatedMonth}`
    this.$emit('fetchEvents', this.startOfMonth, this.endOfMonth)
  }

  newEventClick() {
    this.event = null
    this.$emit('newEvent')
  }

  addNewCalendarEvent(formData: ICalendarForm) {
    const ical = CalendarFormModel.isAllDay(formData)
      ? CalendarFormModel.generateAllDayIcal(formData)
      : CalendarFormModel.generateIcal(formData)
    const id = formData.id ? formData.id : uuid()
    const data = {
      id: id,
      description: formData.description,
      link: this.link,
      title: formData.title,
      type: formData.type,
      ical: ical
    }
    if (formData.id) {
      this.$emit('updateEvent', data, this.editType, this.event?.startTime)
    } else {
      this.$emit('addNewEvent', data)
    }
  }

  deleteCalendarEvent(event: ICalendarEventItemV2) {
    this.eventToDelete = event
    this.showDeleteModal = true
  }

  handleDelete(deleteType?: 'following' | 'one') {
    this.$emit('deleteEvent', this.eventToDelete, deleteType)
    this.showDeleteModal = false
    this.eventToDelete = null
  }

  editCalendarEvent(event: ICalendarEventItemV2) {
    this.event = event
    if (event.isRecurring) {
      this.showEditModal = true
    } else {
      this.editType = 'all'

      this.$emit('toggleEditMode')
    }
  }

  handleCancelModal() {
    this.eventToDelete = null
    this.showDeleteModal = false
  }

  handleCancelEditModal() {
    this.event = null
    this.showEditModal = false
  }

  handleEdit() {
    this.showEditModal = false
    this.editType = 'all'
    this.$emit('toggleEditMode')
  }
  handleFollowingEdit() {
    this.showEditModal = false
    this.editType = 'following'
    this.$emit('toggleEditMode')
  }

  handleEditOne() {
    this.editType = 'one'
    this.showEditModal = false
    this.$emit('toggleEditMode')
  }

  cancel() {
    this.editType = 'all'
    this.$emit('cancel')
  }

  populateEvents(events = this.events) {
    this.fetchedEvents = events
  }

  async created() {
    this.$emit('fetchEvents', this.startOfMonth, this.endOfMonth)
  }
}
