
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import TasksApi from '@/shared/services/api/tasksApi'
import datePickerModalStore from '@/shared_legacy/storeModules/datePickerModalStore'
const datePickerState = getModule(datePickerModalStore)

@Component({
  components: { VueTimepicker, DatePicker }
})
export default class SelectDateModal extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) text: string
  datePickerState = datePickerState
  date: any
  inputValue = ''

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.datePickerState.setModal(modalShow)
    return
  }
  get dateModel() {
    return this.date
  }

  set dateModel(value: Date) {
    this.date = value
  }

  handleCancel() {
    this.datePickerState.setModal(false)
  }

  handleOk() {
    const now = Date.now()
    const expiration = this.date.getTime() - now
    this.setReminder(Math.floor(expiration / 1000))
    this.date = this.setDateToTomorrow()
  }

  async setReminder(time: number) {
    await TasksApi.snoozeTicket(this.datePickerState.idTask, time)
    this.datePickerState.setModal(false)
  }
  setDateToTomorrow() {
    let selectedDay = new Date()
    selectedDay.setDate(selectedDay.getDate() + 1)
    selectedDay.setHours(9)
    selectedDay.setMinutes(0, 0, 0)
    return selectedDay
  }
  created() {
    this.date = this.setDateToTomorrow()
  }
}
