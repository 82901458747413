





















import { Component, Vue } from 'vue-property-decorator'
import AppKey from '@/shared/storeModules/appKey'
import { getModule } from 'vuex-module-decorators'
import { Channel, Socket } from 'phoenix'
import Tickets from '@/shared_legacy/storeModules/tickets'
import { ITicket } from './shared/components/interfaces/tickets.interface'
import ShortcutOverlay from '@/components/ShortcutOverlay.vue'
import ShortcutStore from '@/store/modules/shortcutStore'
import Shortcut from '@/directives/shortcut'
import NewReleaseVersion from '@/shared_legacy/components/atoms/NewReleaseVersion.vue'
import ErrorToast from './shared/storeModules/errorToast'
import ToastError from '@/components/ToastError.vue'
import datePickerModalStore from '@/shared_legacy/storeModules/datePickerModalStore'
import SelectDateModal from '@/shared_legacy/components/atoms/SelectDateModal.vue'

const shortcutState = getModule(ShortcutStore)
const appState = getModule(AppKey)
const ticketsState = getModule(Tickets)
const toastError = getModule(ErrorToast)
const datePickerState = getModule(datePickerModalStore)

@Component({
  components: { ShortcutOverlay, NewReleaseVersion, ToastError, SelectDateModal },
  directives: { Shortcut }
})
export default class App extends Vue {
  $logger: { error: (s1: string, s2?: any) => void }
  channel: Channel
  socket: Socket | null = null
  $token: string
  $baseSocketURL: string
  ticketsState = ticketsState
  datePickerState = datePickerState
  shortcutState = shortcutState
  toastErrorState = toastError
  get appKey() {
    return appState.appKeyValue.value
  }
  get showPickDateModal() {
    return this.datePickerState.modalShow
  }
  created() {
    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      this.$logger.error('unhandled promise rejection: ', event)
      event.preventDefault()
    }
    window.addEventListener('keydown', this.keydownListener)
    this.setupSocketConnection()
  }

  keydownListener(e: any) {
    if (e.ctrlKey && e.altKey) {
      if (shortcutState.isVisible) {
        shortcutState.closeOverlay()
      } else {
        const copy = [...shortcutState.elements]
        for (const el of copy) {
          shortcutState.unregisterElement(el.id)
        }
        for (const el of copy) {
          el.trigger = el.originalKey
          shortcutState.registerElement(el)
        }
        shortcutState.showOverlay()
      }
    }
    if (e.key === 'Escape') {
      shortcutState.closeOverlay()
    }
  }

  setupSocketConnection() {
    const jwt = this.$token
    this.socket = new Socket(this.$baseSocketURL, { params: { token: jwt } })
    this.socket.connect()

    this.channel = this.socket.channel('tickets', { token: jwt })
    this.channel.join()

    this.channel.on('tickets:new', (response: { tickets: ITicket[] }) => {
      this.handleUpdate(response.tickets)
    })
  }

  handleUpdate(tickets: ITicket[]) {
    this.ticketsState.setTickets(tickets)
  }

  mounted() {
    // Remove loading state
    setTimeout(() => {
      document.body.classList.remove('app-loading')
    }, 1)
  }
  updated() {
    // Remove loading state
    setTimeout(() => {
      document.body.classList.remove('app-loading')
    }, 1)
  }
}
