import { flags as defaultFlags } from '../../flags'

const FLAG_ENABLED = 'enabled'
const FLAG_DISABLED = 'disabled'

class FeatureFlags {
  public static isEnabled(flag: string) {
    const key = FeatureFlags.key(flag)
    const existing = window.localStorage.getItem(key)

    if (existing === null) {
      return defaultFlags[flag]
    } else {
      return existing === FLAG_ENABLED
    }
  }

  public static enable(flag: string) {
    window.localStorage.setItem(FeatureFlags.key(flag), FLAG_ENABLED)
  }

  public static disable(flag: string) {
    window.localStorage.setItem(FeatureFlags.key(flag), FLAG_DISABLED)
  }

  public static toggle(flag: string) {
    if (FeatureFlags.isEnabled(flag)) {
      FeatureFlags.disable(flag)
    } else {
      FeatureFlags.enable(flag)
    }
  }

  private static key(flag: string) {
    return 'flag' + flag
  }
}

export default FeatureFlags
