import { render, staticRenderFns } from "./ClientsCompanyUsers.vue?vue&type=template&id=193fb843&scoped=true&"
import script from "./ClientsCompanyUsers.vue?vue&type=script&lang=ts&"
export * from "./ClientsCompanyUsers.vue?vue&type=script&lang=ts&"
import style0 from "./ClientsCompanyUsers.vue?vue&type=style&index=0&id=193fb843&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "193fb843",
  null
  
)

export default component.exports