import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "../../store/store";
type Nullable<T> = T | null;

@Module({
  name: "Country",
  dynamic: true,
  store: store,
})
export default class Country extends VuexModule {
  selectedCountry: Nullable<string> = "DK";

  get countrySelected() {
    return this.selectedCountry;
  }

  @Mutation
  public setCountry(payload: Nullable<string>) {
    this.selectedCountry = payload;
  }

  @Action({ commit: "setCountry" })
  public async selectCountry(country: Nullable<string>) {
    return country;
  }
}
