import axios from 'axios'

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from './../../store/store'
import Fuse from 'fuse.js'

export interface IClientData {
  id?: string
  address: {
    addressLine: string
    city: string
    country: string
    countryCode: string
    zipcode: string
  }
  city: string
  country?: string
  name: string
  logo?: string | null
  vatNo?: string | null
  phone?: string | null
  googleAnalyticsClientId?: string | null
  clientId?: string
  website?: string
  numEmployees?: string
  numSquaremeters?: string
  numFloors?: string
  stripeId?: string
  activityAt?: string
  invoiceLanguage?: string
  createdAt?: string
  updatedAt?: string
  active?: boolean
  billingEmail?: string
}

type ClientDataKeys = keyof IClientData
@Module({
  name: 'ClientStore',
  dynamic: true,
  store: store,
})
export default class ClientStore extends VuexModule {
  clientData: IClientData = {
    id: '',
    name: '',
    vatNo: null,
    address: {
      addressLine: '',
      city: '',
      country: '',
      countryCode: '',
      zipcode: '',
    },
    city: '',
    phone: '',
    clientId: '',
    country: '',
    website: '',
    numEmployees: '',
    numSquaremeters: '',
    numFloors: '0',
    stripeId: '',
    logo: null,
    googleAnalyticsClientId: null,
    activityAt: '',
    active: true,
    invoiceLanguage: '',
    createdAt: '',
    updatedAt: '',
    billingEmail: '',
  }
  clients: IClientData[] = []

  get client() {
    return this.clientData
  }

  get allClients() {
    return this.clients
  }

  get filterClients() {
    const that = this
    return function (query: string) {
      const fuse = new Fuse(that.allClients, {
        threshold: 0.1,
        keys: ['name', 'vatNo'],
      })

      return fuse.search(query)
    }
  }

  @Mutation
  public setClientMutation(clientData: IClientData) {
    for (const key in clientData) {
      if (Object.prototype.hasOwnProperty.call(clientData, key)) {
        // @ts-ignore
        this.clientData[key as ClientDataKeys] = clientData[key as ClientDataKeys]
      }
    }
  }

  @Mutation
  public setClients(clients: IClientData[]) {
    this.clients = [...clients]
  }

  @Action({ commit: 'setClientMutation' })
  public async getClient(clientId: string) {
    return axios.get(`/api/clients/${clientId}`).then((res) => {
      return res.data
    })
  }

  @Action({ commit: 'setClientMutation' })
  public async updateClient(clientData: IClientData) {
    return axios.patch(`/api/clients/${clientData.id}`, clientData).then(() => {
      return clientData
    })
  }

  @Action({ commit: 'setClients' })
  public async getClients() {
    return axios.get(`/api/clients`).then((res) => res.data)
  }
}
