












import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
const iconTypes: { [key: string]: { color: string; icon: string; description: string } } = {
  AnswerCustomerChat: { color: '#D184E2', icon: 'fa-comment-dots', description: 'Customer wrote a message' },
  ExpectCustomerResponse: { color: '#ECCF64', icon: 'fa-comment-slash', description: 'Customer not responded' },
  ProcessRejectedProposal: { color: '#EC6363', icon: 'fa-times', description: 'Proposal rejected' },
  ProcessAcceptedProposal: { color: '#7ED37C', icon: 'fa-check', description: 'Proposal accepted' },
  ProcessRequest: { color: '#84B4E2', icon: 'fa-file-invoice', description: 'New request' },
  RequestReactivation: { color: '#D184E2', icon: 'fa-phone', description: 'Customer requested to be contacted' },
  ProcessServiceRequest: { color: '#84B4E2', icon: 'fa-file-invoice', description: 'New service request' },
  ProcessUpdatedServiceRequest: { color: '#84B4E2', icon: 'fa-sync-alt', description: 'Updates on service request' },
  ProcessClosedRequest: { color: '#C9C9C9', icon: 'fa-archive', description: 'Request closed' },
}
@Component({
  components: {},
})
export default class TicketIcon extends Vue {
  @Prop() readonly ticket!: ITicket
  @Prop({ default: 48 }) size: number

  get fontSize() {
    return this.size / 2
  }

  get iconType() {
    if (this.ticket.type in iconTypes) {
      return iconTypes[this.ticket.type]
    } else {
      return { color: '#4E5155', icon: 'fa-question', description: 'Unknown' }
    }
  }
}
