class FormHelper {
  public static checkVATValidity(e: KeyboardEvent | string, country: string) {
    let inputValue
    if (typeof e === 'string') {
      inputValue = e
    } else {
      //@ts-ignore
      inputValue = e.target.value
    }
    const pattern = country === 'DK' || country === 'Denmark' ? /^(DK)[0-9]{8}$/ : /^(GB)[0-9]{9}$/
    const format = country === 'DK' || country === 'Denmark' ? 'DK00000000' : 'GB000000000'
    if (inputValue.match(pattern)) {
      return ''
    } else {
      return 'VAT number does not match this format: ' + format
    }
  }

  public static emptyStringsToNull(data: any): any {
    if (Array.isArray(data)) {
      const newArr = []
      for (const iterator of data) {
        newArr.push(this.emptyStringsToNull(iterator))
      }
      return newArr
    } else if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (typeof data[key] === 'object') {
            data[key] = this.emptyStringsToNull(data[key])
          } else {
            data[key] = data[key] === '' ? null : data[key]
          }
        }
      }
      return data
    } else {
      return data
    }
  }
}

export default FormHelper
