import axios from './interceptor'
import { IClientAccount, IClientForm, IClientData } from '../../components/interfaces/clients.interface'
import { AxiosResponse } from 'axios'
import FormHelper from '../../helpers/formHelper'

export default class ClientsApi {
  static async getClients(): Promise<IClientData[]> {
    return axios.get('/api/clients').then((res: AxiosResponse) => {
      return res.data
    })
  }

  static async getClientById(clientId: string): Promise<IClientData> {
    return axios.get(`/api/clients/${clientId}`).then((res) => {
      return res.data
    })
  }

  static async getDraftAgreementsByClient(clientId: string) {
    return axios.get(`/api/draft-agreements/client/${clientId}`).then((res: AxiosResponse) => res.data)
  }

  static async saveClientData(client: IClientAccount) {
    let clientCopy = JSON.parse(JSON.stringify(client))
    clientCopy = FormHelper.emptyStringsToNull(clientCopy)
    return axios.patch(`api/clients/${client.id}`, clientCopy).then((res: AxiosResponse) => res.data)
  }

  static async acceptTerms(clientId: string): Promise<{ status: string }> {
    return axios.post(`api/clients/${clientId}/consent`).then((res: AxiosResponse) => res.data)
  }

  static async createClient(client: IClientForm): Promise<IClientData> {
    let clientCopy = JSON.parse(JSON.stringify(client))
    clientCopy = FormHelper.emptyStringsToNull(clientCopy)
    return axios.post(`api/clients`, clientCopy).then((res: AxiosResponse) => res.data)
  }

  static async saveUserData(user: IClientAccount) {
    return axios.patch(`api/users/${user.id}`, user).then((res: AxiosResponse) => res.data)
  }

  static async requestReactivation(clientId: string, phone: string) {
    return axios.post(`api/clients/${clientId}/reactivation-request`, { phone }).then((res: AxiosResponse) => res.data)
  }

  static async terminateLegacyClient(clientId: string) {
    return axios.post(`api/clients/${clientId}/legacy-client-termination`, {}).then((res: AxiosResponse) => res.data)
  }

  static async transferToNewCompany(clientId: string, companyId: string) {
    return axios.post(`api/clients/${clientId}/companies/transfer-to-new-company`, { companyId }).then((res: AxiosResponse) => res.data)
  }

  static async editCompanyId(clientId: string, companyId: string) {
    return axios.post(`api/clients/${clientId}/companies/correct-company-id`, { companyId }).then((res: AxiosResponse) => res.data)
  }

  static async getLockStatus(clientId: string): Promise<{
    overdueNotificationLevel: number | null
    status: 'active' | 'under_termination' | 'terminated'
  }> {
    return axios.get(`api/clients/${clientId}/locking-status`).then((res: AxiosResponse) => res.data)
  }
}
