











































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'

@Component({})
export default class CreateUser extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) clientId: string
  @Prop({ required: true }) addUserToAllUsersCallback: (form: IInvitee) => void
  disabledBtn = false
  error = false
  errorText = ''
  form: IInvitee = {
    email: '',
    phone: '',
    id: '',
    invitedAt: '',
    lastEmailSent: null,
    clientId: '',
    trialExpirationDate: null,
    platformFee: null,
    currency: null
  }
  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }

  handleCancel() {
    this.form = {
      email: '',
      phone: '',
      invitedAt: '',
      lastEmailSent: null,
      id: '',
      clientId: '',
      trialExpirationDate: null,
      platformFee: null,
      currency: null
    }
    this.error = false
    this.$emit('showModal')
  }

  handleOk(_obj: any) {
    this.disabledBtn = true
    this.form.clientId = this.$route.params.id

    const payload = {
      email: this.form.email,
      phone: this.form.phone,
      clientId: this.form.clientId
    }

    this.$billie.invitees.create(payload)
      .then((res) => {
        this.addUserToAllUsersCallback(res)
        this.disabledBtn = false
        this.showModal = false
        this.error = false
      })
      .catch((error) => {
        this.error = true
        this.disabledBtn = false
        this.errorText = error.response.data.error
      })
  }
}
