




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AgreementScheduleModal from './AgreementScheduleModal.vue'

@Component({
  components: { AgreementScheduleModal }
})
export default class AgreementScheduleNewRow extends Vue {
  @Prop() selectedMonth: string
  @Prop() agreementEnd: string
  @Prop() agreementStart: string
  @Prop() currency: string
  @Prop() invoiceSchedule: string
  @Prop({ default: 'en-UK' }) language: string
  modalOpened = false

  openModal() {
    this.modalOpened = true
    this.$nextTick(() => this.$bvModal.show('addElement'))
  }
}
