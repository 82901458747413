



































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
//@ts-ignore
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import Country from '@/shared_legacy/storeModules/country'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import { IActiveAgreementSubscription, IAgreementV2 } from '../shared/components/interfaces/agreements.interface'
import AgreementHelper from '@/helpers/AgreementHelper'
import StatusBadgeHelper from '@/shared_legacy/helpers/statusBadgeHelper'
import NotReadyForInvoiceModal from '../components/agreement/schedule/NotReadyModal.vue'
import client from '@/shared_legacy/storeModules/client'
import Multiselect from 'vue-multiselect'

const countryState = getModule(Country)
const clientState = getModule(client)
interface ISubscription {
  id: string
  agreementStart: string | null
  agreementEnd: string | null
  client: { id: string; name: string }
  supplier: string
  service: {
    category: string
    title: string
  }
  isPrepaid: boolean
  clientTotalPrice: number
  supplierTotalPrice: number
  status: string
  currency: string
}
@Component({ components: { flatPickr, NotReadyForInvoiceModal, Multiselect } })
export default class Subscriptions extends Vue {
  countryState = countryState
  date: string | null = null
  filterBy = ''
  clientState = clientState
  showModal = false
  agreements: IAgreementV2[] | null = null
  agreementSubscriptions: IActiveAgreementSubscription[] | null = null
  categories: { [key: string]: string } = {}
  modalClientId = ''
  errorMessage = ''
  fields = [
    { key: 'client', sortable: true },
    { key: 'supplier', sortable: true },
    { key: 'service', sortable: true },
    { key: 'isPrepaid', sortable: true, label: 'Fixed / Prepaid' },
    { key: 'clientTotalPrice', sortable: true, class: 'amount-field' },
    { key: 'supplierTotalPrice', sortable: true, class: 'amount-field' },
    { key: 'status', sortable: true },
    { key: 'invoices', sortable: true },
    { key: 'readyForInvoicing', sortable: true }
  ]
  agreementStatusMessages = {
    cancelled: 'Cancelled',
    planned: 'Planned',
    terminated: 'Terminated',
    underTermination: 'Under termination',
    active: 'Active'
  }
  months: string[] = []
  formatMonth = AgreementHelper.formatMonth
  selectedMonth = ''
  monthlySubscriptionsMap: { [key: string]: IActiveAgreementSubscription } = {}

  subscriptionTypes = ['Fixed price/prepaid', 'Estimate/Consumption-based', 'All']
  selectedSubscriptionType = 'Fixed price/prepaid'
  subscriptionStatusOptions = ['Active and planned', 'Void and terminated', 'All']
  selectedSubscriptionStatus = 'Active and planned'
  invoiceStatus = ['Not ready', 'Ready for invoicing', 'All']
  selectedInvoiceStatus = 'Not ready'

  get loadingAgreement() {
    return this.agreements === null
  }

  get subscriptions() {
    return this.agreements!.filter(agreement => this.monthlySubscriptionsMap[agreement.id]).map(agreement => {
      return {
        id: agreement.id,
        agreementStart: agreement.agreementStart,
        agreementEnd: agreement.agreementEnd,
        client: agreement.client,
        supplier: agreement.supplier?.name,
        isPrepaid: agreement.prepaid,
        invoices: this.monthlySubscriptionsMap[agreement.id].markedReady ? 'Ready for invoicing' : 'Not ready',
        service: {
          title: this.activeRevision(agreement)?.title,
          category: this.categories[this.activeRevision(agreement)?.category || '']
        },
        clientTotalPrice: this.monthlySubscriptionsMap[agreement.id].clientPrice,
        supplierTotalPrice: this.monthlySubscriptionsMap[agreement.id].supplierPrice,
        status: this.subscriptionStatus(agreement),
        currency: agreement.currency,
        readyForInvoicing: this.monthlySubscriptionsMap[agreement.id].markedReady
      } as ISubscription
    })
  }

  get filteredSubscriptions() {
    const filteredBySupplierServiceOrClient = this.filterByName(this.subscriptions)
    const filteredByCountry = this.filterByCountry(filteredBySupplierServiceOrClient)
    const filteredByDate = this.filterByCreatedDate(filteredByCountry)
    return filteredByDate
  }

  @Watch('selectedMonth')
  onSelectedMonthChange(value: string) {
    window.localStorage.setItem('selectedMonth', value)
    if (value) {
      this.loadMonthlySubscriptions()
      this.getAgreements()
    }
  }

  filterByName(subscriptions: ISubscription[]) {
    let filteredByName = []
    window.localStorage.setItem('filterByName', this.filterBy)
    if (this.filterBy === '') return subscriptions
    for (const sub of subscriptions) {
      if (sub.client?.name?.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(sub)
      } else if (sub.service.title?.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(sub)
      } else if (sub.supplier?.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(sub)
      }
    }
    return filteredByName
  }

  filterByCreatedDate(subscriptions: ISubscription[]) {
    subscriptions.sort((a, b) => {
      return moment(b.agreementStart, 'YYYY-MM-DD').unix() - moment(a.agreementStart, 'YYYY-MM-DD').unix()
    })
    return subscriptions
  }

  filterByCountry(subscriptions: ISubscription[]) {
    let filteredByCountry = []
    if (!this.selectedCountry) return subscriptions
    for (const sub of subscriptions) {
      if (sub.currency === 'DKK' && this.selectedCountry === 'DK') filteredByCountry.push(sub)
      if (sub.currency === 'GBP' && this.selectedCountry === 'UK') filteredByCountry.push(sub)
    }
    return filteredByCountry
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }

  subscriptionStatus(agreement: IAgreementV2) {
    return StatusBadgeHelper.subscriptionAgreementStatus(agreement)
  }

  badgeColor(status: string) {
    return StatusBadgeHelper.getStatusBadgeColor(status)
  }

  activeRevision(agreement: IAgreementV2) {
    return agreement.revisions.find(revision => revision.revisionId === agreement.activeRevisionId)
  }

  goToSubscription(order: ISubscription) {
    const url = `clients/${order.client.id}/agreements/${order.id}`
    this.$router.resolve(url)
    window.open(url)
  }

  compareDate(itemA: ISubscription, itemB: ISubscription, key: string) {
    if (key !== 'agreementStart') {
      return false
    } else {
      let a = moment(itemA[key], 'DD/MM/YYYY').unix()
      let b = moment(itemB[key], 'DD/MM/YYYY').unix()
      return a - b
    }
  }

  decreaseMonth() {
    this.selectedMonth = AgreementHelper.decreaseMonth(this.months, this.selectedMonth)
  }

  increaseMonth() {
    this.selectedMonth = AgreementHelper.increaseMonth(this.months, this.selectedMonth)
  }

  async markOrUnmarkForInvoicing(rowItem: ISubscription, markedReady: boolean) {
    try {
      if (markedReady) {
        await AgreementsApi.unmarkSubscriptionAsReady(rowItem.id, this.selectedMonth)
        this.monthlySubscriptionsMap[rowItem.id].markedReady = !markedReady
        this.$toasted.success('Unmarked as ready for Invoicing')
      } else {
        await AgreementsApi.markSubscriptionAsReady(rowItem.id, this.selectedMonth)
        this.monthlySubscriptionsMap[rowItem.id].markedReady = !markedReady
        this.$toasted.success('Marked as ready for Invoicing')
      }
    } catch (e) {
      this.modalClientId = rowItem.client.id
      this.errorMessage = e.message
      this.showModal = true
    }
  }

  async created() {
    try {
      if (window.localStorage.getItem('selectedMonth') !== null) {
        //@ts-ignore
        this.selectedMonth = window.localStorage.getItem('selectedMonth')
      } else {
        this.selectedMonth = moment().format('YYYY-MM')
      }
      if (window.localStorage.getItem('filterByName') !== null) {
        //@ts-ignore
        this.filterBy = window.localStorage.getItem('filterByName')
      }
      if (window.localStorage.getItem('selectedSubscriptionType') !== null) {
        //@ts-ignore
        this.selectedSubscriptionType = window.localStorage.getItem('selectedSubscriptionType')
      }
      if (window.localStorage.getItem('selectedSubscriptionStatus') !== null) {
        //@ts-ignore
        this.selectedSubscriptionStatus = window.localStorage.getItem('selectedSubscriptionStatus')
      }
      if (window.localStorage.getItem('selectedInvoiceStatus') !== null) {
        //@ts-ignore
        this.selectedInvoiceStatus = window.localStorage.getItem('selectedInvoiceStatus')
      }
      const ukCategories = await AgreementsApi.getCategories('LON')
      const cphCategories = await AgreementsApi.getCategories('CPH')
      this.categories = [...ukCategories, ...cphCategories].reduce((acc, value) => {
        if (acc[value.slug]) return acc
        acc[value.slug] = value.title
        return acc
      }, {})
      await this.getAgreements()
      this.loadMonthlySubscriptions()
    } catch (error) {
      this.agreements = []
    } finally {
      this.months = AgreementHelper.generateMonths(this.getDateRange())
    }
  }

  async getAgreements() {
    let params: any = {}
    if (this.selectedSubscriptionType !== 'All') {
      params['prepaid'] = this.selectedSubscriptionType === 'Fixed price/prepaid'
    }
    if (this.selectedSubscriptionStatus !== 'All') {
      params['active'] = this.selectedSubscriptionStatus === 'Active and planned'
    }
    if (this.selectedInvoiceStatus !== 'All') {
      params['readyForInvoicing'] = this.selectedInvoiceStatus === 'Ready for invoicing'
      params['month'] = this.selectedMonth
    }
    this.agreements = await AgreementsApi.getAgreementsV2ByFilters(params)
  }

  async loadMonthlySubscriptions() {
    this.agreementSubscriptions = await AgreementsApi.getActiveSubscriptions(this.selectedMonth)
    if (this.agreementSubscriptions) {
      this.monthlySubscriptionsMap = this.agreementSubscriptions.reduce((acc, value) => {
        acc[value.agreementId] = value
        return acc
      }, {} as { [key: string]: IActiveAgreementSubscription })
    }
  }

  getDateRange() {
    if (this.subscriptions.length !== 0) {
      return {
        agreementStart: moment().subtract(6, 'month').format('YYYY-MM-DD'),
        agreementEnd: moment().add(6, 'month').format('YYYY-MM-DD')
      }
    } else {
      return {
        agreementStart: moment().format('YYYY-MM-DD'),
        agreementEnd: moment().add(10, 'month').format('YYYY-MM-DD')
      }
    }
  }

  async onFilterChange() {
    window.localStorage.setItem('selectedSubscriptionType', this.selectedSubscriptionType)
    window.localStorage.setItem('selectedSubscriptionStatus', this.selectedSubscriptionStatus)
    window.localStorage.setItem('selectedInvoiceStatus', this.selectedInvoiceStatus)
    await this.getAgreements()
    this.$forceUpdate()
  }
}
