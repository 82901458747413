


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class ConfirmationModal extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) text: string
  @Prop({ required: false, default: 'Cancel' }) cancelButtonText: string
  @Prop({ required: false, default: 'Confirm' }) confirmButtonText: string
  @Prop({ required: false, default: 'Confirmation' }) titleText: string
  @Prop({ required: false, default: '' }) cancelButtonVariant: string
  @Prop({ required: false, default: 'primary' }) confirmButtonVariant: string

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }

  handleCancel() {
    this.$emit('cancel')
  }

  handleOk(_obj: any) {
    this.$emit('confirm')
  }
}
