import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'

export interface IShortcutElement {
  id: string
  elementType: 'button' | 'input' | 'datePicker' | 'select' | 'routerLink'
  trigger: string //letter | any
  originalKey: string //letter | any , if "any" - can be changed to other letter
  element: HTMLElement
  preserve?: boolean
}
@Module({
  name: 'ShortcutStore',
  dynamic: true,
  store: store,
})
export default class ShortcutStore extends VuexModule {
  allShortcuts = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'r',
    's',
    't',
    'u',
    'v',
    'q',
    'w',
    'x',
    'y',
    'z',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    '-',
    '=',
    ';',
    "'",
  ]
  overlayVisibility = false
  shortcutElements: IShortcutElement[] = []
  usedLetters: string[] = []
  availableLetters = [...this.allShortcuts]
  tableActiveItem: string | null = null
  tableTotalItems: any[] = []
  eventListenerAdded = false

  get isVisible() {
    return this.overlayVisibility
  }

  get elements() {
    return this.shortcutElements
  }

  get usedShortcuts() {
    return this.usedLetters
  }

  get activeTableItem() {
    return this.tableActiveItem
  }

  get tableItems() {
    return this.tableTotalItems
  }

  get eventRegistered() {
    return this.eventListenerAdded
  }

  @Mutation
  public setActiveTableItem(payload: { id: string | null }) {
    this.tableActiveItem = payload.id
  }

  @Mutation
  public setTableItems(payload: { items: any[] }) {
    this.tableTotalItems = payload.items
  }

  @Mutation
  public setEventRegistered(payload: { registered: boolean }) {
    this.eventListenerAdded = payload.registered
  }

  @Mutation
  public switchShortcuts(payload: { from: IShortcutElement; to: IShortcutElement }) {
    const fromKey = payload.from.trigger
    const toKey = payload.to.trigger
    payload.to.trigger = fromKey
    payload.from.trigger = toKey
  }

  @Mutation
  public addElement(payload: IShortcutElement) {
    this.shortcutElements.push(payload)
  }

  @Mutation
  public useLetter(payload: { letter: string; id: string }) {
    const element = this.shortcutElements.find((el) => {
      return el.id === payload.id
    })

    if (element) {
      if (this.availableLetters.indexOf(payload.letter) !== -1) {
        this.usedLetters.push(payload.letter)
        element.trigger = payload.letter
        this.availableLetters.splice(this.availableLetters.indexOf(payload.letter), 1)
      } else {
        this.usedLetters.push(this.availableLetters[0])
        element.trigger = this.availableLetters[0]
        this.availableLetters.splice(this.availableLetters.indexOf(this.availableLetters[0]), 1)
      }
    }
  }

  @Mutation
  public showOverlay() {
    this.overlayVisibility = true
  }

  @Mutation
  public closeOverlay() {
    this.overlayVisibility = false
  }

  @Mutation
  public clearShortcuts() {
    this.shortcutElements = []
    this.availableLetters = [...this.allShortcuts]
    this.usedLetters = []
  }

  @Mutation
  public async unregisterElement(id: string) {
    const found = this.shortcutElements.find((element) => {
      return element.id === id
    })
    if (found) {
      if (found.trigger) {
        this.availableLetters.push(found.trigger)
        this.usedLetters.splice(this.usedLetters.indexOf(found.trigger), 1)
      }
      this.shortcutElements.splice(this.shortcutElements.indexOf(found), 1)
    }
  }

  @Action
  public async registerElement(payload: IShortcutElement) {
    this.addElement(payload)
    if (payload.trigger === 'any') {
      this.useLetter({ letter: this.availableLetters[0], id: payload.id })
    } else {
      let assigned = false

      const foundSameTriggerElement = this.shortcutElements.find((el) => {
        return el.trigger === payload.trigger && el.originalKey === 'any'
      })
      if (foundSameTriggerElement) {
        assigned = true
        this.useLetter({ letter: this.availableLetters[0], id: payload.id })
        this.switchShortcuts({ from: foundSameTriggerElement, to: payload })
      }

      if (!assigned) {
        this.useLetter({ letter: payload.trigger, id: payload.id })
      }
    }
  }
}
