import { extend, setInteractionMode } from 'vee-validate'
import { integer, required } from 'vee-validate/dist/rules'
import moment from 'moment'

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('date', {
  validate: value => {
    const date = moment(value)
    return date.isValid()
  },
  message: 'Must select a date'
})

extend('decimal', value => {
  const regExpDecimal = /^([0-9]\d*(\.)\d*|0?(\.)\d*[0-9]\d*|[0-9]\d*)$/
  if (regExpDecimal.test(value)) {
    return true
  }
  return 'Use only numbers and "." character'
})

extend('integer', {
  ...integer,
  message: 'Must be an integer'
})
