



















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class TicketSelect extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly options!: { [key: string]: string }
  @Prop() readonly onSelect!: (value: string) => void
  isOpen = false

  toggleOpen() {
    this.isOpen = !this.isOpen
  }

  select(key: string) {
    this.isOpen = false
    this.onSelect(key)
  }
}
