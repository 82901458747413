




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import InvoiceItem from './InvoiceItem.vue'
import { IInvoiceItem } from '../../shared/components/interfaces/invoices.interface'

@Component({ components: { InvoiceItem } })
export default class InvoiceItems extends Vue {
  highlightHeader = false
  @Prop({ required: true }) items!: IInvoiceItem[]

  get subtotal() {
    return 19
  }

  get vat() {
    return 20
  }

  get total() {
    return 245
  }

  formatAmount(currency: string, amount: string) {
    let formattedAmount = ''
    if (currency === 'GBP') {
      formattedAmount = `£ ${parseFloat(amount).toFixed(2)}`
    } else {
      formattedAmount = `${parseFloat(amount).toFixed(2)} DKK`
    }
    return formattedAmount
  }

  isNonstandard(currency: string, vat: number): boolean {
    let isNonstandard = !(currency === 'GBP' && vat === 20) && !(currency === 'DKK' && vat === 25)
    if (isNonstandard) {
      this.highlightHeader = true
    }
    return isNonstandard
  }
}
