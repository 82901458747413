import moment from 'moment'
import { IAgreementV2, IAgreementRevisionV2 } from '../components/interfaces/agreements.interface'
import { AgreementRevisionV2Model } from './AgreementV2Model/AgreementRevisionV2Model'

/**
 * This is a model working over the IAgreementV2 interface
 *
 * Functions should be pure, static and accept the IAgreementV2
 * interface as the first parameter.
 *
 * The only exception to accepting IAgreementV2 as the first
 * parameter is when using the class as a static factory to build
 * the interface from another data structure.
 *
 * Example:
 * public static from_form(form: AgreementForm): IAgreementV2
 */

export default abstract class AgreementV2Model {
  public static adHoc(agreement: IAgreementV2): boolean {
    return agreement.invoiceSchedule === 'once'
  }

  public static activeRevision(agreement: IAgreementV2): IAgreementRevisionV2 {
    return agreement.revisions.find((revision) => {
      return revision.revisionId === agreement.activeRevisionId
    })!
  }

  public static firstActiveRevision(agreement: IAgreementV2): IAgreementRevisionV2 {
    const index = agreement.revisions.findIndex((revision) => revision.revisionId === agreement.activeRevisionId)
    const activeIndex = index === -1 ? 0 : index
    return agreement.revisions[activeIndex]
  }

  public static activeAt(agreement: IAgreementV2, date: moment.Moment): boolean {
    return (
      date.isAfter(AgreementV2Model.startDate(agreement)) &&
      //@ts-ignore
      (date.isBefore(AgreementV2Model.endDate(agreement)) || AgreementV2Model.endDate(agreement) == null)
    )
  }

  public static firstRevision(agreement: IAgreementV2): IAgreementRevisionV2 | null {
    const firstRevision = agreement.revisions
      // Start dates are Moments which will be coerced to epoch time (numbers) when used with algebra.
      .sort((a, b) => (AgreementRevisionV2Model.startDate(a) as any) - (AgreementRevisionV2Model.startDate(b) as any))
      .shift()

    return firstRevision == undefined ? null : firstRevision
  }

  public static startDate(agreement: IAgreementV2): moment.Moment {
    return moment(agreement.agreementStart)
  }

  public static endDate(agreement: IAgreementV2): moment.Moment | null {
    return agreement.agreementEnd ? moment(agreement.agreementEnd) : null
  }
}
