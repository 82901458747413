































































































import SearchBox from '@/components/SearchBox.vue'
import ClientPicture from '@/shared_legacy/components/ClientPicture.vue'

import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Auth from '../../shared/storeModules/auth'
import Country from '@/shared_legacy/storeModules/country'

const userState = getModule(Auth)
const countryState = getModule(Country)

type Nullable<T> = T | null
@Component({
  components: {
    SearchBox,
    ClientPicture,
  },
})
export default class AppLayoutNavbar extends Vue {
  @Prop({ default: true }) sidenavToggle: boolean
  userState = userState
  countryState = countryState

  created() {
    this.userState.getUser()
    const country = window.localStorage.getItem('selectedCountry')
    if (country) {
      this.countryState.selectCountry(country)
    }
  }

  get user() {
    return this.userState.userData
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }

  handleSelectCountry(selected: Nullable<string>) {
    this.countryState.selectCountry(selected)
    if (selected) {
      window.localStorage.setItem('selectedCountry', selected)
    } else {
      window.localStorage.removeItem('selectedCountry')
    }
  }

  handleLogout() {
    this.userState
      .logout()
      .then(() => {
        this.$router.push('/login')
      })
      .catch((err) => {
        console.log('An error occurred: ', err)
      })
  }

  toggleSidenav() {
    //@ts-ignore
    this.layoutHelpers.toggleCollapsed()
  }

  getLayoutNavbarBg() {
    //@ts-ignore
    return this.layoutNavbarBg
  }
}
