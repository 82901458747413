import API from '@/shared/billie/api'
import { IFileResponseData } from '../../components/interfaces/fileHandler.interface'
import heic2any from 'heic2any'

const uploadHeaders = { 'Content-Type': 'multipart/form-data' }

export default class FileUpload extends API {
  public async upload(file: File): Promise<IFileResponseData> {
    const formData = new FormData()

    const extension = file.name
      .split('.')
      .pop()
      ?.toLowerCase()

    if (extension === 'heic' || extension === 'gif') {
      await heic2any({ blob: file as Blob, toType: 'image/jpeg', quality: 1 }).then((conversionResult) => {
        file = new File([conversionResult as BlobPart], `${file.name.split('.')[0]}.jpeg`, {
          type: 'image/jpeg',
        })
      })
    }

    formData.append('file', file)
    return this.http.post('/api/file', formData, { headers: uploadHeaders }).then((resp) => {
      return resp.data
    })
  }
}
