
















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import moment from 'moment'
//   import { getModule } from 'vuex-module-decorators'
//   import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'
//   import RevisionMixin from '@/mixins/RevisionMixin'
import AgreementRevisionBody from './AgreementRevisionBody.vue'
import AgreementAttachments from './AgreementAttachments.vue'

@Component({
  components: { AgreementRevisionBody, AgreementAttachments }
  // mixins: [RevisionMixin]
})
export default class RevisionEditsModal extends Vue {
  moment = moment
  @Prop({ required: true }) agreementRevisionEdits: IAgreementRevisionV2[]
  @Prop({ required: true }) selectedCategory: IAgreementRevisionV2[]
  @Prop({ required: true }) currency: string
  @Prop({ required: true }) modalShow: boolean

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }
}
