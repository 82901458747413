




































































































import Vue from 'vue'
import AgreementDetails from '@/components/agreement/AgreementDetails.vue'
import moment from 'moment'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
//@ts-ignore
import Datepicker from 'vuejs-datepicker'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import ErrorOnLoading from '@/components/common/ErrorOnLoading.vue'
import Component from 'vue-class-component'
import Auth from '../shared/storeModules/auth'
import Agreements from '@/shared_legacy/storeModules/agreements'
import { getModule } from 'vuex-module-decorators'
import client from '@/shared_legacy/storeModules/client'
import Tickets from '@/shared_legacy/storeModules/tickets'
import { CalendarEventPayload } from '@/shared/billie/api/calendar'
import { ICalendarEventItemV2 } from '@/shared/components/interfaces/calendar.interface'
import CalendarEvents from '@/components/agreement/calendar/CalendarEvents.vue'
import { IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import TasksApi from '@/shared/services/api/tasksApi'
import ICareUser from '@/shared/services/api/tasksApi'

const userState = getModule(Auth)
const clientState = getModule(client)
const agreementState = getModule(Agreements)
const ticketsState = getModule(Tickets)

@Component({
  components: {
    ErrorOnLoading,
    AgreementDetails,
    Datepicker,
    CalendarEvents
  }
})
export default class AgreementDetailsPage extends Vue {
  careUsers: ICareUser[] = []
  clientDetails: IClientData | null = null
  clientState = clientState
  userState = userState
  agreementState = agreementState
  ticketsState = ticketsState
  reloadEvents = false
  metaInfo = {
    title: 'Agreement | Good Monday'
  }
  owner = null
  editTitle = false
  subscription = {
    subscription: {}
  }
  loadingAgreement = true
  steps = [
    {
      key: 0,
      title: 'Requested',
      checked: true
    },
    {
      key: 1,
      title: 'Inspection scheduled',
      checked: false
    },
    {
      key: 2,
      title: 'Proposal to client',
      checked: false
    },
    {
      key: 3,
      title: 'Approved by client',
      checked: false
    },
    {
      key: 4,
      title: 'Completed',
      checked: false
    },
    {
      key: 5,
      title: 'Paid',
      checked: false
    },
    {
      key: 6,
      title: 'Rated',
      checked: false
    }
  ]
  endDate = ''
  lastDayOfAgreement = ''
  language = 'en-UK'
  $token: string
  terminationError = null
  activeRevision: IAgreementRevisionV2 | null = null
  calendarEvents: ICalendarEventItemV2[] = []
  clientId = this.$route.params.id
  selectedEventDates: { start: string; end: string } | null = null

  editModeCalendar = false

  get agreement() {
    let agreement = this.agreementState.agreementV2Data as IAgreementV2
    agreement.revisions = agreement.revisions.sort((a: any, b: any) => {
      return moment(a.startDate!).valueOf() - moment(b.startDate!).valueOf()
    })
    return agreement
  }

  get getDefaultEventTitle() {
    return this.agreement.revisions?.[0]?.title ?? ''
  }

  get tasks() {
    return this.ticketsState.tickets
  }

  get isAdhoc() {
    return this.agreement?.invoiceSchedule === 'once'
  }

  get disabledDates() {
    const revisions = JSON.parse(JSON.stringify(this.agreement!.revisions)) as IAgreementRevisionV2[]
    const oldestRevision = revisions
      .sort((a, b) => {
        const dayA = moment(a.startDate)
        const dayB = moment(b.startDate)
        if (dayA.isBefore(dayB, 'seconds')) return -1
        if (dayA.isAfter(dayB, 'seconds')) return 1
        return 0
      })
      .shift()
    if (!oldestRevision?.startDate) return {}

    return { to: moment(oldestRevision.startDate).add(1, 'day').toDate() }
  }

  get assignedTo() {
    // TODO remove when agreement data has owner info
    if (!this.agreementState.agreementData?.owner?.id) {
      return 'Unassigned'
    } else if (this.userState.userData.id === this.agreementState.agreementData?.owner?.id) {
      return 'Assigned to me'
    }
    return `Assigned to ${this.agreementState.agreementData.owner.name}`
  }

  updateTitle(title: string) {
    this.agreementState.updateAgreementTitle({ id: this.$route.params.agreementId, title }).then(() => {
      this.editTitle = false
    })
  }
  loadAgreement() {
    this.agreementState
      .getAgreementV2({ agreementId: this.$route.params.agreementId })
      .then(data => {
        clientState.getClient(data.client.id).then(clientData => {
          this.language = clientData.invoiceLanguage
        })
      })
      .catch(e => {
        this.$toasted.show('Error loading agreement', { duration: 3000 })
        console.log('Error loading agreement: ', e.message)
      })
      .finally(() => {
        this.loadingAgreement = false
      })
  }

  terminateModal(activeRevision: IAgreementRevisionV2) {
    this.activeRevision = activeRevision
    this.$bvModal.show('terminateModalOpen')
  }
  terminateAgreement() {
    const end = this.isAdhoc ? null : moment(this.endDate)
    this.$billie.agreements
      .terminate(agreementState.agreementV2!.id, end)
      .then(_res => {
        this.resetTerminationModal()
        this.loadAgreement()
        this.activeRevision = null
        if (this.selectedEventDates)
          this.onFetchCalendarEvents(this.selectedEventDates.start, this.selectedEventDates.end)
        this.$nextTick(() => {
          this.$bvModal.hide('terminateModalOpen')
        })
      })
      .catch(err => {
        console.error(err)
        this.resetTerminationModal()
        if (err?.response?.data) {
          this.terminationError = err.response.data.error
        }
      })
  }
  resetTerminationModal() {
    this.endDate = this.lastDayOfAgreement = ''
  }
  setLastDayOfAgreement(date: string) {
    this.lastDayOfAgreement = date ? moment(date).subtract('1', 'days').format('LL') : ''
  }

  async shouldUpdate() {
    if (this.agreement?.revisions.length) {
      if (this.agreement?.revisions.length === 1 && this.agreement?.revisions[0].deleted) {
        this.$router.push(`/clients/${this.$route.params.id}`)
      }
    }
    this.clientState.getClient(this.$route.params.id).then(data => {
      this.clientDetails = data
    })
    this.loadAgreement()
  }

  async onUpdateCalendarEvent(
    data: CalendarEventPayload,
    editType: 'all' | 'one' | 'following' = 'all',
    startTime: string
  ) {
    try {
      switch (editType) {
        case 'all': {
          await this.$billie.calendar.edit(this.clientId, data.id, data)
          break
        }
        case 'one': {
          await this.$billie.calendar.editInstance(this.clientId, data.id, startTime, data)
          break
        }
        default: {
          await this.$billie.calendar.editFuture(this.clientId, data.id, startTime, data)
        }
      }
      if (this.selectedEventDates)
        await this.onFetchCalendarEvents(this.selectedEventDates.start, this.selectedEventDates.end)
      this.editModeCalendar = false
    } catch (error) {
      console.log('error: ', error)
    }
  }
  onNewCalendarEvent() {
    this.editModeCalendar = true
  }
  onAddNewCalendarEvent(newEvent: CalendarEventPayload) {
    this.$billie.calendar
      .create(this.clientId, newEvent)
      .then(() => {
        if (this.selectedEventDates) {
          this.onFetchCalendarEvents(this.selectedEventDates.start, this.selectedEventDates.end)
        }
        this.editModeCalendar = false
      })
      .catch(error => console.log('error: ', error))
  }

  async onFetchCalendarEvents(startDate: string, enddate: string) {
    this.selectedEventDates = { start: startDate, end: enddate }
    this.calendarEvents = await this.$billie.calendar.list(
      this.clientId,
      startDate,
      enddate,
      'agreement:' + this.agreement.id
    )
  }

  onCancelCalendarEvent() {
    this.editModeCalendar = false
  }

  toggleCalendarEditMode() {
    this.editModeCalendar = !this.editModeCalendar
  }

  async onDeleteCalendarEvent(
    { id, startTime, description, link, title, ical, type }: ICalendarEventItemV2,
    deleteType?: 'all' | 'one' | 'following'
  ) {
    try {
      switch (deleteType) {
        case 'one': {
          await this.$billie.calendar.deleteInstance(this.clientId, id, startTime)
          break
        }
        case 'following': {
          let icalSplit = ical.split(';UNTIL')[0] + `;UNTIL=${moment(startTime).format('YYYYMMDD')}`
          await this.$billie.calendar.edit(this.clientId, id, { id, description, link, title, ical: icalSplit, type })
          break
        }
        default: {
          await this.$billie.calendar.delete(this.clientId, id)
        }
      }
      if (this.selectedEventDates)
        await this.onFetchCalendarEvents(this.selectedEventDates.start, this.selectedEventDates.end)
      this.editModeCalendar = false
    } catch (error) {
      console.log('error: ', error)
    }
  }

  async fetchCustomerCareUsers() {
    this.careUsers = await TasksApi.getCareUsers()
  }

  mounted() {
    this.loadAgreement()
  }

  destroyed() {
    this.agreementState.resetAgreement()
  }

  async created() {
    this.clientState.getClient(this.$route.params.id).then(data => {
      this.clientDetails = data
    })
    this.fetchCustomerCareUsers()
  }
}
