































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import DeadlineBar from '../agreement/DeadlineBar.vue'
import TasksApi from '@/shared/services/api/tasksApi'
import RemindDropdown from '@/shared_legacy/components/chat/RemindDropdown.vue'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
import { getModule } from 'vuex-module-decorators'
import datePickerModalStore from '@/shared_legacy/storeModules/datePickerModalStore'
const datePickerState = getModule(datePickerModalStore)

@Component({
  components: { DeadlineBar, RemindDropdown }
})
export default class ClientsTicketActivityLine extends Vue {
  @Prop() expiresAt: string
  @Prop() task: ITicket
  datePickerState = datePickerState

  taskTimeout: null | number = null
  closed = false

  typeToTitle(type: string) {
    if (type === 'ProcessRequest' || type === 'ProcessServiceRequest') {
      return 'New Request'
    } else if (type === 'ProcessAcceptedProposal') {
      return 'Proposal Accepted'
    } else if (type === 'ProcessRejectedProposal') {
      return 'Proposal Rejected'
    }
  }

  selectedDropdownValue(value: number | string) {
    if (typeof value === 'string') {
      this.datePickerState.setModal(true)
      this.datePickerState.setTaskId(this.task.id)
    } else {
      this.taskTimeout = value
      this.closed = false
    }
  }
  async closeTicket() {
    await TasksApi.closeTicket(this.task.id)
  }

  async sendMessageCallback() {
    await TasksApi.snoozeTicket(this.task.id, this.taskTimeout! * 60)
  }
}
