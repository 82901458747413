import { Component, Vue } from 'vue-property-decorator'
import { IAgreementRevisionV2, IAgreementElementV2 } from '../shared/components/interfaces/agreements.interface'
import moment from 'moment'
import { IRevision } from '../shared/services/api.interface'

@Component
export default class RevisionMixin extends Vue {
  revision: IAgreementRevisionV2
  activeRevision: IAgreementRevisionV2

  get hasPrepaymentPattern() {
    if (this.revision) return this.revision.prepaymentPatterns?.length
    return this.activeRevision?.prepaymentPatterns?.length
  }

  get prepaymentPatterns() {
    const { prepaymentPatterns } = this.revision ?? this.activeRevision
    if (!prepaymentPatterns) return null
    return prepaymentPatterns.reduce(
      (acc, pattern) => {
        let key = 'adhoc'
        if (pattern.pricing) key = pattern.pricing as string
        if (acc[key]) acc[key].push(pattern)
        else acc[key] = [pattern]

        return acc
      },
      { per_delivery: [], per_month: [], adhoc: [] } as { [key: string]: IAgreementElementV2[] }
    )
  }

  get clientTotalPrice() {
    const { prepaymentPatterns } = this.revision ?? this.activeRevision
    if (!prepaymentPatterns) return '0.00'
    const sum = prepaymentPatterns.reduce((acc, pattern) => {
      acc += pattern.clientPrice! * pattern.quantity!
      return acc
    }, 0)
    return Number(sum).toFixed(2)
  }

  get supplierTotalPrice() {
    const { prepaymentPatterns } = this.revision ?? this.activeRevision
    if (!prepaymentPatterns) return '0.00'
    const sum = prepaymentPatterns.reduce((acc, pattern) => {
      acc += pattern.supplierPrice! * pattern.quantity!
      return acc
    }, 0)
    return Number(sum).toFixed(2)
  }

  sortRevisions(revisions: (IRevision | IAgreementRevisionV2)[]) {
    return revisions.sort((a, b) => {
      const dayA = moment(a.createdAt)
      const dayB = moment(b.createdAt)
      if (dayA.isBefore(dayB, 'seconds')) return -1
      if (dayA.isAfter(dayB, 'seconds')) return 1
      return 0
    })
  }
}
