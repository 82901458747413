
































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { IProposalV2, IElement, IProposalRequest, IRevision } from '@/shared/services/api.interface'
import ShortcutStore from '@/store/modules/shortcutStore'
import { getModule } from 'vuex-module-decorators'
import ShortcutsHelperHelper from '@/shared_legacy/helpers/shortcutsHelper'
import ProposalDetails from '@/components/proposals/ProposalDetails.vue'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import CreateNewDocument from '@/components/agreementAndProposal/CreateNewDocument.vue'
import uuid from 'uuid-random'

const shortcutState = getModule(ShortcutStore)

@Component({
  components: { ProposalDetails, CreateNewDocument }
})
export default class RequestProposals extends Vue {
  @Prop() requestId: string
  @Prop() clientDetails: IClientData
  @Prop() userStateId: string
  @Prop() proposals: IProposalV2[]
  @Prop() activeProposal: IProposalV2 | null
  @Prop({ default: true }) loading: boolean
  @Prop({ default: false }) isStateCancelled: boolean
  sortBy = 'createdAt'
  sortDesc = true
  fields = [
    { key: 'title', label: 'Title', sortable: true },
    { key: 'createdAt', label: 'Created at', sortable: true },
    { key: 'supplier', label: 'Supplier', sortable: true },
    { key: 'accepted', label: 'Status', sortable: false }
  ]
  shortcutState = shortcutState
  shortcutsHelperHelper: any
  selectedProposal: IProposalV2 | null = null
  isNewProposal: 'subscription' | 'adhoc' | null = null

  createdById = null
  offeringId = null
  proposalId = ""

  get clientId() {
    return this.$route.params.id
  }

  createNewUuid() {
    this.proposalId = uuid();
  }

  proposalClassFn(item: IProposalV2) {
    const active = item.id === this.selectedProposal?.id ? 'active-table-proposal' : ''
    return 'agreement-item ' + active
  }

  getSelectedIndex() {
    let index = null
    if (this.selectedProposal) {
      this.proposals.forEach((item: IProposalV2, i: number) => {
        if (this.selectedProposal!.id === item.id) {
          index = i
        }
      })
    }
    return index
  }

  rowAttribute(item: any, _type: string) {
    const props: { [key: string]: string } = {
      'data-cy': `proposal-row-item--${item.id}`
    }
    const active = item.id === this.selectedProposal?.id ? 'active-table-proposal' : ''
    if (item.id === shortcutState.activeTableItem) {
      props.class = 'active-row ' + active
    } else {
      props.class = '' + active
    }
    return props
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.shortcutsHelperHelper.clickHandler!)
  }

  formatedDate(date: string) {
    return moment(date).format('MMM DD, YYYY - HH:mm')
  }

  badgeStatusColor(data: boolean) {
    if (data === false) return 'danger'
    if (data === true) return 'success'
    return 'primary'
  }

  badgeStatusText(data: boolean) {
    if (data === false) return 'Rejected'
    if (data === true) return 'Accepted'
    return 'Awaiting approval'
  }

  async created() {
    this.shortcutsHelperHelper = new ShortcutsHelperHelper(this.proposals)
    if (this.activeProposal) {
      this.selectedProposal = this.activeProposal
    }
    moment.locale('en')
  }

  rowHovered(item: any) {
    this.shortcutsHelperHelper.activateItem(item.id)
  }
  onRowSelected(item: IProposalV2[]) {
    this.selectedProposal = item.length ? item[0] : null
  }

  cloneProposal(data: IProposalV2) {
    return {
      ...data,
      revisions: [this.sortRevisions(data.revisions).pop() as IRevision]
    }
  }

  sortRevisions: (revisions: IRevision[]) => IRevision[]

  createProposalObj(proposal: IProposalRequest, isExistingProposal: boolean) {
    const proposalObj = { ...proposal }
    proposalObj.clientId = this.$route.params.id
    proposalObj.requestedBy = this.createdById
    proposalObj.creatorId = this.userStateId
    proposalObj.offeringId = this.offeringId
    proposalObj.requestId = this.requestId
    if (proposal.invoiceSchedule === 'monthly') {
      proposalObj.startDate = proposalObj.startDate ? moment(proposalObj.startDate).format('YYYY-MM-DD') : null
    } else proposalObj.startDate = null
    proposalObj.elements = this.populateProposalElements(isExistingProposal, proposal?.elements as IElement[])
    return proposalObj
  }

  saveProposal(proposal: IProposalRequest, isExistingProposal: boolean) {
    let proposalObj = this.createProposalObj(proposal, isExistingProposal)
    this.$billie.proposals
      .create(proposalObj)
      .then(_proposal => {
        this.$emit('updateProposalList', _proposal, isExistingProposal)
        if (!isExistingProposal) this.isNewProposal = null
        if (this.$refs.proposalsTable) {
          let i = this.getSelectedIndex()
          //@ts-ignore
          this.$refs.proposalsTable.refresh()
          setTimeout(() => {
            //@ts-ignore
            this.$refs.proposalsTable.selectRow(i)
          }, 0)
        }
      })
      .catch(e => {
        this.$toasted.show(`Error: ${e.response.data.error}`, { duration: 3000 })
      })
  }

  async save(payload: any) {
    const proposal: IProposalRequest = {
      id: payload.id,
      title: payload.title,
      description: payload.description,
      currency: payload.currency,
      startDate: payload.startDate,
      clientId: payload.clientId,
      supplierId: payload.supplierId,
      creatorId: '',
      requestId: '',
      category: payload.category,
      invoiceSchedule: payload.invoiceSchedule,
      prepaid: payload.prepaid,
      requestedBy: '',
      offeringId: '',
      elements: payload.prepaymentPatterns,
      attachments: payload.attachments
    }
    if (await this.areProposalFieldsValid(proposal)) {
      this.saveProposal(proposal, false)
    }
  }

  async update(proposal: IProposalRequest, isExistingProposal: boolean) {
    if (await this.areProposalFieldsValid(proposal)) {
      this.saveProposal(proposal, isExistingProposal)
    }
  }

  async areProposalFieldsValid(agreement: IProposalRequest) {
    let isValid = false
    if (agreement.title && agreement.description && agreement.supplierId && agreement.category) {
      if (agreement.invoiceSchedule === 'once') {
        if (!agreement.prepaid) isValid = true
        else {
          if (agreement.elements!.length) {
            isValid = this.validateElements(agreement.elements!)
          }
        }
      } else {
        if (agreement.startDate) {
          if (!agreement.prepaid) isValid = true
          else {
            if (agreement.elements!.length) {
              isValid = this.validateElements(agreement.elements!)
            }
          }
        }
      }
    }
    if (!isValid) {
      this.$toasted.error('Please fill in the required fields', { icon: 'ion ion-ios-warning' })
    }
    return isValid
  }

  validateElements(elements: IElement[]) {
    var allElementsValid = true
    for (var element of elements!) {
      var elementValid = false
      if (element.description && element.quantity && element.clientPrice && element.unit && element.schedule) {
        elementValid = true
      }
      if (!elementValid) allElementsValid = false
    }
    if (allElementsValid) return true
    return false
  }

  populateProposalElements(isExistingProposal: boolean, e: IElement[]) {
    const proposalElements = e
    if (!proposalElements) return []
    if (isExistingProposal) return proposalElements
    const elements = []
    for (var element of proposalElements) {
      let newElement = { ...element }
      newElement.pricingType = element.pricing!
      //@ts-ignore
      delete newElement.pricing
      newElement.vatPercentage = parseFloat(newElement.vatPercentage as unknown as string)
      elements.push(newElement)
    }
    return elements
  }
}
