
























































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import DeadlineBar from '../agreement/DeadlineBar.vue'
import ClientPicture from '@/shared_legacy/components/ClientPicture.vue'
import { ICareUser } from '@/shared/services/api/tasksApi'

@Component({
  components: { DeadlineBar, ClientPicture }
})
export default class ClientAgreementTicketIcon extends Vue {
  @Prop() title: string
  @Prop() iconClass: string
  @Prop({ default: false }) showShortcuts: boolean
  @Prop() dropdownText: string
  @Prop() assignee: { id: string; name: string; avatar: string }
  @Prop() chatStyle: boolean
  @Prop() careUsers: ICareUser[]
  //@Prop() callback: (option: ICareUser, event: any) => void
  top = 0
  left = 0
  show = false
  activeOption: string | null = null

  toggleShowOptions() {
    if (!this.show) {
      if (this.careUserOptions.length > 2) {
        document.addEventListener('keydown', this.keyDown)
      } else {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
          if (e.key === 'Escape') this.show = false
        })
      }
      document.addEventListener('click', this.mouseClickOutisde)

      setTimeout(() => {
        //@ts-ignore
        if (this.$refs.icon.getBoundingClientRect().top + 400 > window.innerHeight) {
          //@ts-ignore
          this.top = this.$refs.icon.getBoundingClientRect().top - 400
        } else {
          //@ts-ignore
          this.top = this.$refs.icon.getBoundingClientRect().top + 20
        }
        //@ts-ignore
        this.left = this.$refs.icon.getBoundingClientRect().left

        this.show = true
      }, 0)
    } else {
      this.show = false
      document.removeEventListener('keydown', this.keyDown)
    }
  }

  callback(option: any, $event: MouseEvent) {
    document.removeEventListener('keydown', this.keyDown)
    this.$emit('callback', option, $event)
  }

  get careUserOptions() {
    let careDK = [{ id: 'careDK', name: 'DK', disabled: true }]
    let careUK = [{ id: 'careUK', name: 'UK', disabled: true }]
    for (let user of this.careUsers) {
      if (user.countryCode === 'DK') {
        careDK.push(user as any)
      } else if (user.countryCode === 'UK') {
        careUK.push(user as any)
      }
    }
    const combined = careDK.concat(careUK)
    return combined
  }

  get activeOptions() {
    return this.careUserOptions.filter(option => {
      return !option.disabled
    })
  }

  get ticketIconClass() {
    const iClass = this.chatStyle ? ' chat-style' : ''
    return this.iconClass + iClass
  }

  mouseClickOutisde(e: any) {
    if (this.show && !this.$el.querySelectorAll('.options-container')[0].contains(e.target)) {
      this.show = false
      document.removeEventListener('keydown', this.keyDown)
      document.removeEventListener('click', this.mouseClickOutisde)
    }
  }

  keyDown(e: KeyboardEvent) {
    e.stopPropagation()
    e.preventDefault()

    switch (e.key) {
      case 'ArrowDown':
        this.activateNext()
        return
      case 'ArrowRight':
        this.activateNext()
        return
      case 'ArrowUp':
        this.activatePrevious()
        return
      case 'ArrowLeft':
        this.activatePrevious()
        return
      case 'Enter':
        e.preventDefault()
        e.stopPropagation()
        document.removeEventListener('keydown', this.keyDown)
        //@ts-ignore
        document.getElementsByClassName('active-option')[0]?.click()
        return
      case 'Escape':
        this.toggleShowOptions()
        return
    }
  }

  activateNext() {
    const activeIndex = this.activeOptions.findIndex(item => {
      return this.activeOption === item.name
    })
    if (activeIndex + 1 === this.activeOptions.length) {
      this.activeOption = this.activeOptions[0].name
    } else {
      this.activeOption = this.activeOptions[activeIndex + 1].name
    }
  }

  activatePrevious() {
    const activeIndex = this.activeOptions.findIndex(item => {
      return this.activeOption === item.name
    })
    if (activeIndex === 0) {
      this.activeOption = this.activeOptions[this.activeOptions.length - 1].name
    } else {
      this.activeOption = this.activeOptions[activeIndex - 1].name
    }
  }

  firstName(name: string) {
    return name.split(' ')[0]
  }

  lastName(name: string) {
    if (name.split(' ')[1]) {
      return name.split(' ')[1]
    } else {
      return ''
    }
  }
}
