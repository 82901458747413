import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../../store/store'

@Module({
  name: 'datePickerModalStore',
  dynamic: true,
  store: store,
})
export default class datePickerModalStore extends VuexModule {
  showModal = false
  taskId = ''

  get modalShow() {
    return this.showModal
  }
  get idTask() {
    return this.taskId
  }

  @Mutation
  public setModalVisibility(value: boolean) {
    this.showModal = value
  }

  @Action({ commit: 'setModalVisibility' })
  public async setModal(value: boolean) {
    return value
  }
  @Mutation
  public setTaskIdMutation(value: string) {
    this.taskId = value
  }

  @Action({ commit: 'setTaskIdMutation' })
  public async setTaskId(value: string) {
    return value
  }
}
