

















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
//@ts-ignore
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

type Nullable<T> = T | null

@Component({ components: { FlatPickr } })
export default class DocumentWhenSection extends Vue {
  @Prop() existingDate: string
  date: Nullable<string> = null

  created() {
    if (this.existingDate) {
      this.date = this.existingDate
      this.$emit('updateStartDate', this.date)
    }
  }
  updateStartDate(val: Date) {
    this.$emit('updateStartDate', moment(val.toString()).format('YYYY-MM-DD'))
  }
}
