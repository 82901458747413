
































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import ClientStore from '@/shared_legacy/storeModules/client'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import AgreementDetailsCreateNew from '../components/agreement/AgreementDetailsCreateNew.vue'
import ClientDetailsHeader from '../components/clients/ClientDetailsHeader.vue'
import uuid from 'uuid-random'

const clientStore = getModule(ClientStore)

@Component({
  components: { AgreementDetailsCreateNew, ClientDetailsHeader }
})
export default class AgreementCreatePage extends Vue {
  loadingClientDetails = true
  clientStore = clientStore
  showClientDetailsModal = false
  proposalId = uuid()

  get client() {
    return this.clientStore.client
  }

  get country() {
    return this.clientStore.client.address.countryCode
  }

  toggleClientDetailsModal() {
    this.showClientDetailsModal = !this.showClientDetailsModal
  }

  updateClientDetails(res: IClientData) {
    this.clientStore.updateClient(res)
  }
  created() {
    this.clientStore.getClient(this.$route.params.id).then(() => {
      this.loadingClientDetails = false
    })
  }
}
