


































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import agreementSchedule from '@/shared_legacy/storeModules/agreementSchedule'
import { IAgreementInvoiceDataV2 } from '@/shared/components/interfaces/agreements.interface'
import moment from 'moment'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import AgreementScheduleModal from './AgreementScheduleModal.vue'
import AgreementUnitSelect from '../AgreementUnitSelect.vue'
import NumberInput from './inputs/NumberInput.vue'

const agreementScheduleState = getModule(agreementSchedule)

@Component({
  components: { AgreementScheduleModal, AgreementUnitSelect, NumberInput },
})
export default class AgreementScheduleRow extends Vue {
  @Prop() item: IAgreementInvoiceDataV2[]
  @Prop() date: string
  @Prop() index: string
  @Prop() selectedMonth: string
  @Prop() currency: string
  @Prop() agreementEnd: string
  @Prop() invoiceSchedule: string
  @Prop({ default: 'en-UK' }) language: string
  @Prop() pricingMap: { [key: string]: string }
  isEditMode = false
  rowData: IAgreementInvoiceDataV2[] = []
  rowDataOriginal: IAgreementInvoiceDataV2[] = []
  agreementScheduleState = agreementScheduleState
  modalOpened = false

  @Watch('item')
  onItemChange() {
    this.setRowDataToItem()
  }

  mounted() {
    this.setRowDataToItem()
  }

  formatQuantity(quantity: number) {
    return quantity
  }

  formatPrice(price: number) {
    return price
  }

  setProperty(property: 'quantity' | 'clientPrice' | 'supplierPrice', index: number, e: number) {
    this.rowData[index][property] = e
  }

  openModal() {
    this.modalOpened = true
    this.$nextTick(() => this.$bvModal.show('addElement'))
  }

  isPast(date: string) {
    return moment().isAfter(moment(date), 'day')
  }

  editMode(on: boolean) {
    if (on) {
      this.setRowDataToItem()
      this.isEditMode = on
      this.$emit('editing', on)
    }
  }

  handleDontSave() {
    this.isEditMode = false
    this.$emit('editing', false)
  }

  convertToInt(row: IAgreementInvoiceDataV2) {
    return row
  }

  setRowDataToItem() {
    this.rowData = JSON.parse(JSON.stringify(this.item))
    this.rowDataOriginal = JSON.parse(JSON.stringify(this.item))
  }

  isCancelledItem(quantity: number) {
    return this.formatQuantity(quantity) <= 0 ? 'canceled-item' : ''
  }

  cancelAgreementItems() {
    let items = this.item.map((i) => {
      return {
        ...i,
        quantity: 0,
      }
    })
    this.updateScheduleDay(items)
    this.isEditMode = false
    this.$emit('editing', false)
  }

  saveScheduleDay() {
    let changedElements: IAgreementInvoiceDataV2[] = []
    for (let i = 0; i < this.rowData.length; i++) {
      if (JSON.stringify(this.rowData[i]) !== JSON.stringify(this.rowDataOriginal[i])) {
        changedElements.push(this.convertToInt(JSON.parse(JSON.stringify(this.rowData[i]))))
      }
    }
    if (changedElements.length) {
      this.updateScheduleDay(changedElements)
    }
    this.isEditMode = false
    this.$emit('editing', false)
    this.rowDataOriginal = []
  }

  updateScheduleDay(items: IAgreementInvoiceDataV2[]) {
    AgreementsApi.createAmendmentV2(this.$route.params.agreementId, items).then(() => {
      this.agreementScheduleState.getAgreementSchedule({
        agreementId: `${this.$route.params.agreementId}`,
        month: this.selectedMonth,
        type: this.invoiceSchedule,
      })
    })
  }
}
