















import { IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import ClientStore from '@/shared_legacy/storeModules/client'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import UpdateDocument from '../../agreementAndProposal/UpdateDocument.vue'
import Agreements from '@/shared_legacy/storeModules/agreements'

const clientStore = getModule(ClientStore)
const agreementState = getModule(Agreements)
@Component({
  components: { UpdateDocument },
})
export default class AgreementAddNewRevision extends Vue {
  @Prop() revision: IAgreementRevisionV2
  @Prop() edit: boolean
  @Prop() isProposal: boolean
  @Prop({ default: false }) isV1: boolean
  @Prop() language: string
  @Prop() totalRevisions: number
  @Prop({ required: true }) prepaid: boolean
  @Prop({ required: true }) agreementType: 'subscription' | 'adhoc'
  loadingClientDetails = true
  clientStore = clientStore

  get client() {
    return this.clientStore.client
  }

  get country() {
    return this.clientStore.client.address.countryCode
  }

  created() {
    this.clientStore.getClient(this.$route.params.id).then(() => {
      this.loadingClientDetails = false
    })
  }

  cancel() {
    this.$emit('cancel')
  }
  save(agreementId: string, revisionId: string, payload: IAgreementRevisionV2) {
    AgreementsApi.AgreementAddNewRevisionV2(agreementId, revisionId, payload)
      .then((data) => {
        agreementState.setAgreementV2(data)
        this.$emit('shouldUpdate')
      })
      .catch((e) => {
        console.log(e)
        this.$toasted.error('error: Agreement not updated')
      })
      .finally(() => {
        this.$emit('closeNewRevisionTab')
      })
  }
}
