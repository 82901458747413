import API, { Payload } from '@/shared/billie/api'
import { AxiosInstance } from 'axios'
import Events from  '@/shared/billie/api/calendar/events'
import { ICalendarEventItemV2, ICalendarEventResponse } from '@/shared/components/interfaces/calendar.interface'
import uuid from 'uuid-random'

export interface CalendarEventPayload extends Payload {
  id: string
  description?: string
  link?: string
  title: string
  ical: string
  type: 'arrival' | 'schedule'
}

export default class Calendar extends API {
  public events: Events

  constructor(http: AxiosInstance) {
    super(http)
    this.events = new Events(http)
  }

  public async list(
    clientId: string,
    startDate: string,
    endDate: string,
    link?: string
  ): Promise<ICalendarEventItemV2[]> {
    const linkString = link ? `&link=${link}` : ''
    return this._get(`/api/clients/${clientId}/calendar/events?start=${startDate}&end=${endDate}${linkString}`)
  }

  public async create(clientId: string, data: CalendarEventPayload): Promise<ICalendarEventResponse> {
    return this._post(`/api/clients/${clientId}/calendar/events`, data)
  }

  public async edit(clientId: string, eventId: string, payload: CalendarEventPayload): Promise<ICalendarEventResponse> {
    // TODO: Maybe not an API responsibility? Enforce with Omit<CalendarEventPayload, 'link'>
    delete payload.link
    return this._patch(`/api/clients/${clientId}/calendar/events/${eventId}`, payload)
  }

  public async editInstance(
    clientId: string,
    eventId: string,
    startTime: string,
    payload: CalendarEventPayload
  ): Promise<ICalendarEventResponse> {
    // TODO: Maybe not an API responsibility? Enforce with Omit<CalendarEventPayload, 'link'>
    delete payload.link
    // TODO: Maybe not an API responsibility?
    payload.id = uuid()
    return this._patch(`/api/clients/${clientId}/calendar/events/${eventId}/instances/${startTime}`, payload)
  }

  public async editFuture(
    clientId: string,
    eventId: string,
    startTime: string,
    payload: CalendarEventPayload
  ): Promise<ICalendarEventResponse> {
    // TODO: Maybe not an API responsibility? Enforce with Omit<CalendarEventPayload, 'link'>
    delete payload.link
    // TODO: Maybe not an API responsibility?
    payload.id = uuid()
    return this._post(`/api/clients/${clientId}/calendar/events/${eventId}/instances/${startTime}/split`, payload)
  }

  public async delete(clientId: string, eventId: string): Promise<{ status: string }> {
    return this._delete(`/api/clients/${clientId}/calendar/events/${eventId}`)
  }

  public async deleteInstance(clientId: string, eventId: string, eventStartTime: string): Promise<{ status: string }> {
    return this._delete(`/api/clients/${clientId}/calendar/events/${eventId}/instances/${eventStartTime}`)
  }
}
