


























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ClientPicture extends Vue {
  @Prop() avatar!: string
  @Prop() initials!: string
  @Prop({ default: '' }) firstName!: string
  @Prop({ default: '' }) lastName!: string
  @Prop({ default: false }) square: boolean
  @Prop({ default: false }) directLink: boolean
  @Prop({ default: 111 }) width!: number
  @Prop({ default: 14 }) fontSize!: number
  @Prop() editMode!: boolean
  @Prop({ default: false }) chatStyle: boolean

  $baseURL: string

  get calcWidth() {
    if (!this.chatStyle) {
      return this.width
    } else {
      return 25
    }
  }

  get urlCssParam() {
    return !this.directLink ? `url(${this.$baseURL}uploads/${this.avatar})` : `url(${this.avatar})`
  }

  get generatedInitials() {
    let i = this.initials || 'GM'
    if (this.firstName.length && this.lastName.length) {
      i = `${this.firstName[0]}${this.lastName[0]}`
    } else if (this.firstName.length) {
      i = `${this.firstName[0]}`
    }
    return i
  }
}
