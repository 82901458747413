import API, { Payload } from '@/shared/billie/api'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'

export interface AcceptPayload extends Payload {
    firstName: string
    lastName: string
    position: string
    phone: string
    password: string
    acceptsTerms?: boolean
    privacyPolicy?: boolean
}

export default class Invitations extends API {
    public async get(invitationId: string): Promise<IInvitee> {
        return this._get(`/api/invitations/${invitationId}`)
    }

    public async accept(invitationId: string, payload: AcceptPayload): Promise<{ status: string }> {
        return this._post(`api/invitations/${invitationId}/accept`, payload)
    }

    public async sendEmail(id: string): Promise<IInvitee> {
        return this._post(`/api/invitees/${id}/invitations`)
    }

    public async resendEmail(clientId: string, id: string): Promise<IInvitee> {
        return this._post(`/api/clients/${clientId}/invitees/${id}/invitations`)
    }
}