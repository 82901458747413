import axios from 'axios'
import * as Sentry from '@sentry/browser'
import NumberHelper from '../../helpers/numberHelper'
import ErrorToast from '../../storeModules/errorToast'
import { getModule } from 'vuex-module-decorators'
const errorStore = getModule(ErrorToast)
axios.defaults.params = {}

axios.interceptors.request.use(
  (req) => {
    // Important: request interceptors **must** return the request.
    if (req.data) {
      req.data = NumberHelper.toggleFloats(req.data, false)
    }
    return req
  },
  function(error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function(response) {
    return NumberHelper.toggleFloats(response, true)
  },
  function(error) {
    Sentry.addBreadcrumb({
      category: 'axios-interceptor-data',
      data: {
        status: error.response && error.response.status,
        request: {
          method: error.config && error.config.method,
          data: error.config && error.config.data,
          baseURL: error.config && error.config.baseURL,
          url: error.config && error.config.url,
        },
        response: error.response && error.response.data,
      },
      level: Sentry.Severity.Info,
    })
    if (error.response && [401, 403].includes(error.response.status)) {
      const tasksTimerId = localStorage.getItem('tasksTimerId')
      if (tasksTimerId) {
        window.clearTimeout(parseInt(tasksTimerId))
      }
      localStorage.clear()
      axios.defaults.headers.common = {}
      const query: { [key: string]: string | boolean | number } = {}
      window.location.search
        .slice(1)
        .split('&')
        .forEach((param) => {
          const [key, value] = param.split('=')
          query[key] = value
        })

      let redirect = window.location.href
      if (Object.keys(query).length && query.redirect) {
        redirect = query.redirect as string
      }

      const message = error.response.status === 401 ? 'login-failure' : 'unauthorized'
      window.location.href = `/login?redirect=${window.encodeURIComponent(redirect)}&error=${message}`
    }
    if (error.response?.data?.error && [403, 400, 500].includes(error.response.status)) {
      errorStore.setToastError({ message: error.response.data.error, value: true })
      setTimeout(() => {
        errorStore.setToastError({ message: '', value: false })
      }, 0)
    }
    if (error.message == 'Network Error') {
      errorStore.setToastError({ message: error.message, value: true })
    }

    return Promise.reject(error)
  }
)

export default axios
