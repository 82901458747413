















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ClientsApi from '@/shared/services/api/ClientsApi'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import { IClientForm } from '@/shared/components/interfaces/clients.interface'
import FormHelper from '@/shared/helpers/formHelper'
import moment from 'moment'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import NumberInput from '../agreement/schedule/inputs/NumberInput.vue'

@Component({ components: { DatePicker, NumberInput } })
export default class CreateClient extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) addClientToAllCompaniesCallback: (form: IClientData) => void
  error = false
  errorText = ''
  isSaving = false
  vatErrorText = ''
  form: IClientForm = {
    name: '',
    identification: '',
    address: {
      zipcode: '',
      city: '',
      countryCode: 'DK',
      addressLine: ''
    },
    areaCode: 'CPH'
  }
  paymentConditionType = 'Standard'
  formSubscriptionPaymentCondition = 30
  formAdhocPaymentCondition = 14
  options = [
    { text: 'Standard', value: 'Standard' },
    { text: 'Special', value: 'Special' }
  ]

  get disabledBtn() {
    return this.error || this.isSaving
  }

  countryOptions = [
    { text: 'Denmark', value: 'DK' },
    { text: 'United Kingdom', value: 'UK' }
  ]

  resetError() {
    this.error = false
  }

  setTrialDate(date: Date) {
    this.form.trialExpirationDate = moment(date).format('YYYY-MM-DD')
  }

  setStandardPaymentCondition() {
    if (this.paymentConditionType === 'Standard') {
      this.formSubscriptionPaymentCondition = 30
      this.formAdhocPaymentCondition = 14
    }
  }

  get getCurrency() {
    const currency = this.form.address.countryCode
    switch (currency) {
      case 'UK': {
        return 'GBP'
      }
      default: {
        return 'DKK'
      }
    }
  }

  countryChange() {
    this.vatErrorText = FormHelper.checkVATValidity(this.form.identification, this.form.address.countryCode)
    if (this.form.address.countryCode === 'UK') {
      this.form.areaCode = 'LON'
    } else {
      this.form.identification = ''
      this.form.areaCode = 'CPH'
    }
    this.resetError()
  }

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }
  get showAreaCodeOptions() {
    if (this.form.address.countryCode === 'UK') {
      return [{ text: 'London', value: 'LON' }]
    } else {
      return [{ text: 'Copenhagen', value: 'CPH' }]
    }
  }
  clientVatNoChanged(e: KeyboardEvent | string) {
    this.vatErrorText = FormHelper.checkVATValidity(e, this.form.address.countryCode)
    this.resetError()
  }

  mounted() {
    let trialInfo = {
      trialExpirationDate: moment().add(1, 'month').format('YYYY-MM-DD'),
      platformFee: ''
    }
    this.form = { ...this.form, ...trialInfo }
  }
  handleCancel() {
    this.form = {
      name: '',
      identification: '',
      address: {
        zipcode: '',
        city: '',
        countryCode: 'DK',
        addressLine: ''
      },
      areaCode: 'CPH',
      paymentConditionForSubscription: 30,
      paymentConditionForAdhoc: 14
    }

    let trialInfo = {
      trialExpirationDate: moment().add(1, 'month').format('YYYY-MM-DD'),
      platformFee: ''
    }
    this.form = { ...this.form, ...trialInfo }

    this.error = false
    this.errorText = ''
    this.$emit('showModal')
  }
  handleOk(_obj: any) {
    this.isSaving = true
    this.form.paymentConditionForSubscription = parseInt(this.formSubscriptionPaymentCondition as unknown as string)
    this.form.paymentConditionForAdhoc = parseInt(this.formAdhocPaymentCondition as unknown as string)
    ClientsApi.createClient(this.form)
      .then(res => {
        this.addClientToAllCompaniesCallback(res)
        this.showModal = false
        this.isSaving = false
        this.error = false
      })
      .catch(err => {
        this.isSaving = false
        this.error = true
        this.errorText = err.response.data.error
      })
  }
}
