
































































import moment from 'moment'
import Vue from 'vue'
import Component from 'vue-class-component'
import 'flatpickr/dist/flatpickr.css'
import { ITopUpStockLevel, ITopUpStockLevels } from '../shared/components/interfaces/topUp.interface'
import TopUpApi from '../shared/services/api/topUpApi'
import _ from 'lodash'

@Component({ components: {} })
export default class TopUpPage extends Vue {
  filterBy = ''
  currentPage = 1
  topUpStockLevels: ITopUpStockLevels | null = null
  defaultFields = [
    { key: 'client', sortable: true },
    { key: 'product', sortable: true },
    { key: 'level', sortable: true },
    { key: 'createdAt', sortable: true }
  ]
  isLoading = false

  get meta(){
    return this.topUpStockLevels?.meta
  }

   get records () {
    return this.topUpStockLevels?.records
  }

  handleDebouncedSearch = _.debounce(this.handleSearch, 250)

  async handleSearch(){
    
    this.isLoading = true
    this.currentPage = 1
    this.topUpStockLevels!.records = []
    this.topUpStockLevels = await TopUpApi.getStockLevels(1, this.filterBy)
    this.isLoading = false
  }

  async handlePageChange(){
    this.isLoading = true
    this.topUpStockLevels!.records = []
    this.topUpStockLevels = await TopUpApi.getStockLevels(this.currentPage, this.filterBy)
    this.isLoading = false
  }

  async mounted() {
    this.isLoading = true
    this.topUpStockLevels = await TopUpApi.getStockLevels(this.currentPage,this.filterBy)
    this.isLoading = false
  }

  formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }

  compareDate(itemA: ITopUpStockLevel, itemB: ITopUpStockLevel, key: string) {
    if (key !== 'createdAt') {
      return false
    } else {
      let a = moment(itemA[key])
      let b = moment(itemB[key])
      return (a as any) - (b as any)
    }
  }
}
