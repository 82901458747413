












import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {}
})
export default class AppLayoutFooter extends Vue {
  getLayoutFooterBg() {
    //@ts-ignore
    return `bg-${this.layoutFooterBg}`
  }
}
