import axios from './interceptor'
import { ITicket } from '../../components/interfaces/tickets.interface'

export interface ICareUser {
  id: string
  name: string
  countryCode: string
  email: string
}

class TasksApi {
  static async getTasks(): Promise<ITicket[]> {
    return axios.get('/api/tasks').then((res) => res.data)
  }

  static async snoozeTicket(ticketId: string, timeoutSeconds: number) {
    return axios.post(`/api/tickets/${ticketId}/snooze`, { seconds: timeoutSeconds }).then((res) => res.data)
  }

  static async closeTicket(ticketId: string) {
    return axios.post(`/api/tickets/${ticketId}/close`).then((res) => res.data)
  }

  static async assignTicket(ticketId: string, userId: string) {
    return axios.post(`/api/tickets/${ticketId}/assign`, { userId: userId }).then((res) => res.data)
  }

  static async getCareUsers(): Promise<ICareUser[]> {
    return axios.get('/api/care-users').then((res) => res.data)
  }
}
export default TasksApi
