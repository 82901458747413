import axios from './interceptor'
class AuthApi {
  authenticate(email: string, password: string) {
    return axios.post('/api/login', { email, password }).then((resp) => resp.data)
  }

  async createAgreement(agreementId: string, store: { [key: string]: any }) {
    return axios.put('/api/draft-agreement/' + agreementId, { document: store }).then((resp) => resp.data)
  }

  sendResetPassword(email: string) {
    return axios.post('/authentication/send-reset-pwd', { email }).then((resp) => resp.data)
  }

  requestResetPassword(email: string) {
    return axios.post(`api/auth/reset-token/${email}`).then((resp) => resp.data)
  }

  resetPassword(token: string, password: string) {
    return axios.post('/authentication/reset-pwd', { token, password }).then((resp) => resp.data)
  }

  verifyToken(token: string) {
    return axios.post('/authentication/verify-token', { token }).then((resp) => resp.data)
  }
}
export default new AuthApi()
