import { ISupplier } from '@/shared/components/interfaces/agreements.interface'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from './../../store/store'
import SupplierApi from '@/shared/services/api/supplierApi'

@Module({
  name: 'Suppliers',
  dynamic: true,
  store: store,
})
export default class Suppliers extends VuexModule {
  suppliersState: ISupplier[] = []

  suppliersData: ISupplier[] = [...this.suppliersState]

  get allSuppliers() {
    return this.suppliersData
  }

  get suppliersByName() {
    return this.suppliersData.sort((a, b) => {
      const nameA = a.name?.toUpperCase() ?? ''
      const nameB = b.name?.toUpperCase() ?? ''
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      return 0
    })
  }

  @Mutation
  public setSuppliers(payload: ISupplier[]) {
    this.suppliersData = [...payload]
  }

  @Action({ commit: 'setSuppliers' })
  public async getAllSuppliers() {
    return SupplierApi.getAllSuppliers().then((data: ISupplier[]) => {
      return data
    })
  }
}
