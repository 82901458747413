

















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Progress from '../common/Progress.vue'
import moment from 'moment'
import Auth from '../../shared/storeModules/auth'
import { getModule } from 'vuex-module-decorators'
import TicketSelect from './TicketSelect.vue'
import DeadlineBar from '../agreement/DeadlineBar.vue'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'
import TicketIcon from './TicketIcon.vue'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'
import datePickerModalStore from '@/shared_legacy/storeModules/datePickerModalStore'
const datePickerState = getModule(datePickerModalStore)

const userState = getModule(Auth)
const postponeOptions = {
  '1800': '30 minutes',
  '7200': '2 hours',
  '21600': '6 hours',
  '86400': '1 day',
  '259200': '3 days',
  '604800': '1 week',
  '2592000': '1 month',
  '7776000': '3 months',
  custom: 'Custom time'
}

@Component({
  components: {
    Progress,
    TicketSelect,
    DeadlineBar,
    TicketIcon
  }
})
export default class Ticket extends Vue {
  @Prop() readonly ticket!: ITicket
  @Prop() readonly onAssign!: (ticketId: string, userId: string) => Promise<void>
  @Prop() readonly onPostpone!: (ticketId: string, seconds: number) => Promise<void>
  @Prop() readonly careUsers!: ICareUser[]
  isHovered = false
  postponeOptions = postponeOptions
  userState = userState
  datePickerState = datePickerState
  created() {
    moment.locale('en')
  }

  async closeTicket() {
    await TasksApi.closeTicket(this.ticket.id)
  }

  get timeAgo() {
    return moment(this.ticket.createdAt).fromNow()
  }

  get timeLeft() {
    return moment(this.ticket.expiresAt).fromNow()
  }

  get assignee() {
    if (this.ticket.assignee === null) {
      return 'Unassigned'
    } else {
      return this.ticket.assignee.name
    }
  }

  get careUserOptions() {
    let careDK = [{ id: 'careDK', name: 'DK' }]
    let careUK = [{ id: 'careUK', name: 'UK' }]
    for (let user of this.careUsers) {
      if (user.countryCode === 'DK') {
        careDK.push(user)
      } else if (user.countryCode === 'UK') {
        careUK.push(user)
      }
    }
    const combined = careDK.concat(careUK)
    return combined
  }

  postpone(seconds: string, e: any) {
    e.stopPropagation()
    if (seconds === 'custom') {
      this.datePickerState.setModal(true)
      this.datePickerState.setTaskId(this.ticket.id)
    } else {
      this.onPostpone(this.ticket.id, parseInt(seconds))
    }
  }

  assignToUser(user_id: string, e: any) {
    e.stopPropagation()
    this.onAssign(this.ticket.id, user_id)
  }

  assignToMe(e: any) {
    e.stopPropagation()
    this.onAssign(this.ticket.id, userState.id as string)
  }

  async navigate() {
    let route
    const ticketType = this.ticket.subjectType

    switch (ticketType) {
      case 'client':
      case 'request_reactivation':
        route = { name: 'ClientDetails', params: { id: this.ticket.client.id } }
        break
      case 'service_request':
        route = { name: 'RequestDetails', params: { id: this.ticket.client.id, requestId: this.ticket.subjectId } }
        break
      case 'request':
        route = { name: 'RequestDetails', params: { id: this.ticket.client.id, requestId: this.ticket.subjectId } }
        break
      case 'proposal':
        route = {
          name: 'AgreementDetails',
          params: { id: this.ticket.client.id, agreementId: this.ticket.subjectId }
        }
        break
      case 'agreement':
        route = { name: 'AgreementDetails', params: { id: this.ticket.client.id, agreementId: this.ticket.subjectId } }
        break
      default:
        route = { name: 'ClientDetails', params: { id: this.ticket.client.id } }
    }

    this.$router.push(route)
  }
}
