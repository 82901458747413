



























import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IAgreementElement } from '@/shared/components/interfaces/agreements.interface'

@Component({ components: {} })
export default class DocumentInputGroup extends Vue {
  @Prop({ required: true }) value: string
  @Prop({ required: true }) model: keyof IAgreementElement
  @Prop({ required: true }) label: string
  @Prop() validationRef: string
  @Prop() rules: string
  @Prop() vid: string
  @Prop({ default: null }) dataTestId: string
  @Prop() inputRef: string
  @Prop({ default: true }) showLabel: string

  updateValue(e: any) {
    this.$emit('update', e.target.value, this.model)
  }
}
