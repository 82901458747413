




















import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({})
export default class RemindDropdown extends Vue {
  @Prop() message: string
  @Prop() disabled: boolean
  @Prop() selectedDropdownValueCallback: () => void
  @Prop() sendMessageCallback: () => void
}
