




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import { getModule } from 'vuex-module-decorators'
import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'
import RevisionMixin from '@/mixins/RevisionMixin'
import AgreementRevisionBody from './AgreementRevisionBody.vue'
import AgreementAttachments from './AgreementAttachments.vue'

const agreementState = getModule(AgreementsV2)
@Component({
  components: { AgreementRevisionBody, AgreementAttachments },
  mixins: [RevisionMixin]
})
export default class AdhocAgreement extends Vue {
  @Prop({ required: true }) revisions: IAgreementRevisionV2[]
  @Prop({ required: true }) currency: string
  @Prop({ required: true }) prepaid: boolean
  @Prop({ required: true }) setActiveRevision: (revision: IAgreementRevisionV2) => void
  @Prop({ default: false }) isProposal: boolean
  agreementState = agreementState
  errorLoading = false
  revision: IAgreementRevisionV2 | null = null

  created() {
    this.revision = this.revisions[0]
  }

  async mounted() {
    this.setActiveRevision(this.revisions[0])
    await this.loadCategories()
  }

  get allCategories() {
    return this.agreementState.allCategories
  }

  get serviceArea() {
    return this.currency === 'DKK' ? 'CPH' : 'LON'
  }

  get selectedCategory() {
    if (this.allCategories.length) {
      let category = this.allCategories.filter(category => category.slug === this.revisions[0].category)
      return category[0] && category[0].title
    } else return this.revisions[0].category
  }

  async loadCategories() {
    try {
      await this.agreementState.getCategories(this.serviceArea)
    } catch (e) {
      this.errorLoading = true
    }
  }
}
