import { roundToInt } from '../components/interfaces/numbers.interface'
class NumberHelper {
  public static keysToConvert = [
    'quantity',
    'supplierPrice',
    'clientPrice',
    'unitPrice',
    'unitCost',
    'platformFee',
    'upcomingPlatformFee',
    'currentPlatformFee',
    'priceFrom',
    'priceTo',
  ]

  public static keysToConvertScale4 = ['vatPercentage']

  public static makeFloat(num: number) {
    return Math.round(num * 100) / 100
  }

  public static makeResponseFloat(num: number, scale: number) {
    return num / Math.pow(10, scale)
  }

  public static makeInt(num: number, scale: number) {
    return roundToInt(Math.round(num * Math.pow(10, scale)))
  }

  public static toggleFloats(data: any, toFloat: boolean): any {
    if (Array.isArray(data)) {
      const newArr = []
      for (const iterator of data) {
        newArr.push(this.toggleFloats(iterator, toFloat))
      }
      return newArr
    } else if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (this.keysToConvert.includes(key) || this.keysToConvertScale4.includes(key)) {
            if (data[key] !== null) {
              const scale = this.keysToConvert.includes(key) ? 2 : 4
              data[key] = toFloat ? this.makeResponseFloat(data[key], scale) : this.makeInt(data[key], scale)
            }
          } else {
            if (data[key] !== null) data[key] = this.toggleFloats(data[key], toFloat)
          }
        }
      }
      return data
    } else {
      return data
    }
  }
}

// Export a singleton instance in the global namespace
export default NumberHelper
