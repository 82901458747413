

































































































































































































































































import Vue from 'vue'
import moment from 'moment'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import client from '@/shared_legacy/storeModules/client'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'
import ClientsTicketActivityList from '@/components/clients/ClientsTicketActivityList.vue'
import Auth from '@/shared/storeModules/auth'
import ChatWithDeadlineV2 from '@/components/chat/ChatWithDeadlineV2.vue'
import Tickets from '@/shared_legacy/storeModules/tickets'
import ClientDetailsHeader from '@/components/clients/ClientDetailsHeader.vue'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import { IRequest } from '@/shared/components/interfaces/request.interface'
import { RequestPayload, IRequestEvent } from '@/shared/billie/api/serviceRequests'
import NewEditRequest from '@/components/requests/NewEditRequest.vue'
import RequestProposals from '@/components/requests/RequestProposals.vue'
import { IProposalV2 } from '@/shared/services/api.interface'
import AgreementAttachments from '@/components/agreement/AgreementAttachments.vue'
import FeatureFlag from '@/shared_legacy/components/FeatureFlag.vue'

const clientState = getModule(client)
const userState = getModule(Auth)
const ticketsState = getModule(Tickets)

@Component({
  components: {
    ClientsTicketActivityList,
    ChatWithDeadlineV2,
    ClientDetailsHeader,
    NewEditRequest,
    RequestProposals,
    AgreementAttachments,
    FeatureFlag
  }
})
export default class RequestDetailsPage extends Vue {
  requestLoaded = false
  editMode = false
  requestPayload: RequestPayload = {
    id: '',
    title: '',
    description: '',
    location: '',
    attachments: [],
    people: null,
    timeframe: {
      type: 'between'
    },
    budget: {
      type: 'total',
      priceFrom: 0,
      priceTo: 0
    },
    frequency: null
  }
  showCancelModal = false
  showCompleteModal = false

  showClientDetailsModal = false
  clientState = clientState
  clientDetails: IClientData | null = null
  language = 'en-UK'
  requestId: string | null = null
  chatContext = 'request'
  userState = userState
  exisitingRequest: IRequest | null = null
  careUsers: ICareUser[] = []
  activeProposal: IProposalV2 | null = null
  ticketsState = ticketsState
  events: IRequestEvent[] = []
  isEventsVisible = false

  async fetchCustomerCareUsers() {
    this.careUsers = await TasksApi.getCareUsers()
  }

  get fullCreatorName() {
    return `${this.exisitingRequest?.createdBy.firstName} ${this.exisitingRequest?.createdBy.lastName}`
  }

  get isStateCancelled() {
    return this.exisitingRequest?.status == 'cancelled'
  }

  get isStateCompleted() {
    return this.exisitingRequest?.status == 'completed'
  }

  get tasks() {
    return this.ticketsState.ticketsById(this.$route.params.id)
  }

  get referrals() {
    if (this.exisitingRequest?.referrals.offering) {
      return this.exisitingRequest?.referrals.offering.name
    } else if (this.exisitingRequest?.referrals.service) {
      return this.exisitingRequest?.referrals.service.name
    } else if (this.exisitingRequest?.referrals.supplier) {
      return this.exisitingRequest?.referrals.supplier.name
    } else {
      return '-'
    }
  }

  budgetType(type: string) {
    switch (type) {
      case 'perPerson':
        return 'Per person'
      case 'total':
        return 'Total'
      case 'perHour':
        return 'Per hour'
      case 'perMonth':
        return 'Per month'
      case 'perWeek':
        return 'Per Week'
      default:
        return ''
    }
  }

  get formatedCreatedAtDate() {
    return moment(this.exisitingRequest!.createdAt).format('ll')
  }

  formatTimeframe(date: string) {
    return moment(date).format('MMMM Do YYYY')
  }

  formatDateToTimestamp(date: string) {
    return moment(date).format('Do MMM - hh:mm')
  }

  timeframeToText(timeframe: any) {
    switch (timeframe?.type) {
      case 'between':
        return (
          this.formatTimeframe(timeframe.from) +
          ' - ' +
          this.formatTimeframe(timeframe.to) +
          (timeframe?.time ? ' - ' + timeframe.time : '')
        )
      case 'asap':
        return this.$t('requests.timeframe.asapLabel')
      case 'before':
        return (
          this.$t('requests.timeframe.beforeLabel') +
          ' ' +
          this.formatTimeframe(timeframe.date) +
          (timeframe?.time ? ' - ' + timeframe.time : '')
        )
      case 'after':
        return (
          this.$t('requests.timeframe.afterLabel') +
          ' ' +
          this.formatTimeframe(timeframe.date) +
          (timeframe?.time ? ' - ' + timeframe.time : '')
        )
      case 'date':
        return this.formatTimeframe(timeframe.date)
      default:
        return ''
    }
  }

  frequencyToText(frequency: any) {
    if (frequency?.type && !frequency?.value && !frequency?.days) {
      return this.$t(`requests.frequency.selectOptions.${frequency?.type}`)
    } else {
      switch (frequency?.type) {
        case 'perMonth':
        case 'perQuarter':
        case 'perYear':
          return frequency?.value!.toString() + this.$t(`requests.frequency.optionsToText.${frequency?.type}`)
        case 'perWeek':
          return this.$t('requests.frequency.optionsToText.every') + this.translateWeekDays(frequency.days)
        case 'other':
          return frequency?.value
        default:
          return null
      }
    }
  }

  translateWeekDays(days: string[]) {
    if (days) {
      let result = days.map(day => this.$t(`requests.frequency.days.${day}`))
      return result.length > 1
        ? //@ts-ignore
          result.reduce((prev, curr, i) => prev + curr + (i === result.length - 2 ? ' and ' : ', '), '').slice(0, -2)
        : result[0]
    }
    return ''
  }

  get proposalIDs() {
    const ids = []
    if (this.exisitingRequest) {
      for (const proposal of this.exisitingRequest.proposals) {
        ids.push(proposal.id)
      }
    }
    return ids
  }

  get hasCompleted() {
    let hasCompleted = false
    if (this.exisitingRequest) {
      for (const proposal of this.exisitingRequest.proposals) {
        if (proposal.accepted === true) {
          hasCompleted = true
        }
      }
    }
    return hasCompleted
  }

  get badge() {
    if (this.requestLoaded && this.$route.params.requestlId === 'new') {
      return null
    } else {
      return { text: this.$t(`serviceRequests.status.${this.exisitingRequest?.status}`), color: `primary` }
    }
  }

  get currency() {
    const currency = this?.clientDetails?.areaCode
    switch (currency) {
      case 'LON': {
        return 'GBP'
      }
      default: {
        return 'DKK'
      }
    }
  }

  get requestPropertyChangedEvents() {
    return this.events.filter(event => {
      if (
        event.eventType === 'requestPropertyChangedByUser' ||
        event.eventType === 'requestPropertyChangedByCustomerCare'
      )
        return true
    })
  }

  toggleClientDetailsModal() {
    this.showClientDetailsModal = !this.showClientDetailsModal
  }

  updateClientDetails(res: IClientData) {
    this.clientDetails = res
  }

  dispplayRequestProperty(property: string, value: any, to: boolean) {
    if (!value) return '-'
    switch (property) {
      case 'attachments':
        return to ? 'Attachmnets changed' : ''
      case 'timeframe':
        return this.timeframeToText(value)
      case 'frequency':
        return this.frequencyToText(value)
      case 'budget':
        return `${value.priceFrom} - ${value.priceTo} ${this.currency} ${this.budgetType(value.type)}`
      case 'people':
        return value.min ? `${value.min} - ${value.max}` : `${value.value}`
      default:
        return value
    }
  }

  getPropertyIcon(property: string) {
    switch (property) {
      case 'title':
        return 'create'
      case 'description':
        return 'list-box'
      case 'timeframe':
        return 'time'
      case 'frequency':
        return 'repeat'
      case 'budget':
        return 'card'
      case 'people':
        return 'people'
      case 'location':
        return 'pin'
      case 'attachments':
        return 'attach'
    }
  }

  async createRequest(requestPayload: RequestPayload) {
    let response = await this.$billie.serviceRequests.create(this.$route.params.id, requestPayload)
    if (response.id) {
      this.requestLoaded = true
      this.exisitingRequest = response
      this.$router.push({ name: 'RequestDetails', params: { id: this.$route.params.id, requestId: response.id } })
    }
  }

  async updateRequest(requestPayload: RequestPayload) {
    let response = await this.$billie.serviceRequests.update(
      this.$route.params.id,
      this.exisitingRequest!.id,
      requestPayload
    )
    if (response.id) {
      this.requestLoaded = true
      this.editMode = false
      this.exisitingRequest = response
      try {
        this.events = await this.$billie.serviceRequests.events(this.$route.params.id, this.requestId!)
      } catch (e) {
        throw new Error(`Something went wrong while getting events of a request`)
      }
    }
  }

  async close() {
    await this.$billie.serviceRequests.close(this.$route.params.id, this.exisitingRequest!.id)
    this.$router.push({ name: 'ClientDetails', params: { id: this.$route.params.id } })
  }

  handleEditRequest() {
    this.requestPayload = {
      id: this.exisitingRequest!.id,
      title: this.exisitingRequest!.title,
      description: this.exisitingRequest!.description,
      location: this.exisitingRequest!.location,
      attachments: this.exisitingRequest!.attachments,
      people: this.exisitingRequest!.people,
      frequency: this.exisitingRequest!.frequency,
      timeframe: {
        type: this.exisitingRequest?.timeframe?.type ?? 'between',
        //@ts-ignore
        from: this.exisitingRequest?.timeframe?.from,
        //@ts-ignore
        to: this.exisitingRequest?.timeframe?.to,
        //@ts-ignore
        date: this.exisitingRequest?.timeframe?.date,
        //@ts-ignore
        time: this.exisitingRequest?.timeframe?.time
      },
      budget: {
        type: this.exisitingRequest?.budget?.type ?? 'total',
        priceFrom: this.exisitingRequest?.budget?.priceFrom,
        priceTo: this.exisitingRequest?.budget?.priceTo
      }
    }
    this.editMode = true
  }

  updateProposalList(proposal: IProposalV2, isExistingProposal: boolean) {
    if (isExistingProposal) {
      this.exisitingRequest!.proposals.forEach((item, i) => {
        if (item.id == proposal.id) {
          this.exisitingRequest!.proposals[i] = proposal
        }
      })
    } else {
      this.exisitingRequest!.proposals.push(proposal)
    }
  }

  async created() {
    this.fetchCustomerCareUsers()
    this.clientState.getClient(this.$route.params.id).then(data => {
      this.clientDetails = data
      this.language = data.invoiceLanguage
    })
    this.requestId = this.$route.params.requestId
    if (this.requestId === 'new') {
      this.requestLoaded = false
      return
    }
    try {
      this.exisitingRequest = await this.$billie.serviceRequests.get(this.$route.params.id, this.requestId)
      if (this.$route.params.activateProposal) {
        this.activeProposal =
          this.exisitingRequest.proposals.find(prop => prop.id === this.$route.params.activateProposal) ?? null
      }
      this.chatContext = 'request'
      this.requestLoaded = true
    } catch (e) {
      throw new Error(`Something went wrong while getting request`)
    }
    try {
      this.events = await this.$billie.serviceRequests.events(this.$route.params.id, this.requestId!)
    } catch (e) {
      throw new Error(`Something went wrong while getting events of a request`)
    }
  }
}
