































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import ClientsTicketActivityLine from './ClientsTicketActivityLine.vue'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'

@Component({
  components: { ClientsTicketActivityLine },
})
export default class ClientsTicketActivityList extends Vue {
  @Prop() tasks: ITicket[]
  @Prop() subjectIds?: string | string[]

  get isArrayOfSubjects() {
    return Array.isArray(this?.subjectIds)
  }

  get subjectId() {
    return this.isArrayOfSubjects ? this?.subjectIds![0] : this?.subjectIds
  }
  get filteredTasks() {
    const filtered = this.tasks.filter((task) => {
      if (this.isArrayOfSubjects) {
        return (
          task.type !== 'AnswerCustomerChat' &&
          task.type !== 'ExpectCustomerResponse' &&
          (!this.subjectId || this.subjectIds?.indexOf(task.subjectId) !== -1)
        )
      } else {
        return (
          task.type !== 'AnswerCustomerChat' &&
          task.type !== 'ExpectCustomerResponse' &&
          (!this.subjectId || task.subjectId === this.subjectId)
        )
      }
    })
    return filtered
  }

  redirect(task: ITicket) {
    if (!this.subjectId) {
      this.$router.push(`/clients/${task.client.id}/proposal/${task.subjectId}`)
    }
  }
}
