import axios from './interceptor'

import { IOverdueClient, ISentNotification } from '../../components/interfaces/finance.interface'

class FinanceApi {
  static async getOverdueClients(): Promise<IOverdueClient[]> {
    return axios.get(`/api/finance/overdue_clients`).then((resp) => resp.data)
  }

  static async changeStatus(clientId: string, level: number): Promise<ISentNotification> {
    return axios.post(`/api/finance/overdue_notification/${clientId}`, { level }).then((resp) => resp.data)
  }
}
export default FinanceApi
