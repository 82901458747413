import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import API from '@/shared/billie/api'

export default class Agreements extends API {
    public async list(): Promise<IAgreementV2> {
        return this._get(`/api/v2/agreements`)
    }

    public async listByClient(clientId: string): Promise<IAgreementV2[]> {
        return this._get(`/api/clients/${clientId}/agreementsv2`)
    }

    public async terminate(id: string, endDate: moment.Moment | null): Promise<any> {
        const payload = endDate ? { endDate: endDate.format('YYYY-MM-DD') } : {}
        return this._post(`/api/v2/agreements/${id}/termination`, payload)
    }
}