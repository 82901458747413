










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PlaceholderShimmer extends Vue {
  @Prop({ default: true }) animate: boolean
  @Prop({
    default: undefined,
    validator: (value) => ['undefined', 'boolean'].includes(typeof value),
  })
  slotStatus: boolean
  @Prop({ default: '100%' }) height: string
  @Prop({ default: '100%' }) width: string
  isLoadingResource = true

  get animationStatus() {
    if (this.isLoadingResource) return this.animate
    return this.isLoadingResource
  }

  get renderingStatus() {
    if (typeof this.slotStatus === 'boolean') return this.slotStatus
    return this.isLoadingResource
  }

  onResourceLoaded() {
    this.isLoadingResource = false
  }
}
