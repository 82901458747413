




































































































import { Component, Vue } from 'vue-property-decorator'
import { Channel, Socket } from 'phoenix'
//@ts-ignore
import StringToColor from 'string-to-color'

interface Event {
  event: { event: { id: string }; name: string; cx: number; cy: number; lifespan: string; color: string }
  metadata: {
    created_at: string
    event_id: string
    event_type: string
    lifespan: number
    model_id: string
    model_type: string
  }
}

interface EventBubble {
  event: Event
  name: string
  lifespan: string
  cx: string
  cy: string
  color: string
}

type StatDay = { [key: string]: number }
type Stats = { [key: string]: StatDay }

@Component({
  components: {},
  metaInfo: {
    title: 'Events | Good Monday',
  },
})
export default class EventMonitor extends Vue {
  channel: Channel
  socket: Socket | null = null
  $baseSocketURL: string
  events: EventBubble[] = []
  stats: StatDay[] = []
  maxEventCount = 0
  $token: string
  eventList: { name: string; color: string }[] = []

  created() {
    this.setupSocketConnection()
  }

  beforeDestroy() {
    if (this.channel && this.socket) {
      this.channel.leave()
      this.socket!.disconnect()
    }
  }

  setupSocketConnection() {
    const jwt = this.$token
    this.socket = new Socket(this.$baseSocketURL, { params: { token: jwt } })
    this.socket.connect()

    this.channel = this.socket.channel('events', { token: jwt })
    this.channel.join()

    this.channel.on('event:new', (event: Event) => {
      this.handleEventLog(event)
    })

    this.channel.on('stats:new', (stats: Stats) => {
      this.stats = Object.values(stats).reverse()

      const dayCounts = Object.values(stats).map((day) => {
        const count = Object.values(day).reduce((a, b) => a + b, 0)

        return count
      })

      this.maxEventCount = Math.max(...dayCounts)

      // Generate uinque list of events with color
      this.eventList = Object.values(stats)
        .reduce((acc, cur) => {
          const eventTypes = Object.keys(cur)
          return [...acc, ...eventTypes]
        }, [] as string[])
        .filter((v, i, self) => {
          return self.indexOf(v) === i
        })
        .map((v) => {
          const name = this.name(v)

          return {
            name: name,
            color: this.barColor(v),
          }
        })
    })
  }

  handleEventLog(event: Event) {
    const name = this.name(event.metadata.event_type)

    this.events.push({
      event: event,
      name: name,
      lifespan: event.metadata.lifespan + 's',
      cx: this.generatePosition(),
      cy: this.generatePosition(),
      color: this.barColor(event.metadata.event_type),
    })
  }

  generatePosition() {
    return Math.random() * 100 + '%'
  }

  barHeight(stat: StatDay, i: number) {
    const stackCount = Object.values(stat).reduce((a, b) => a + b, 0)
    const stackHeight = (100 / this.maxEventCount) * stackCount

    return (stackHeight / (stackCount / Object.values(stat)[i])) * 0.4
  }

  name(namespace: string) {
    const nameParts = namespace.split('.')
    const name = nameParts[nameParts.length - 2] + '.' + nameParts[nameParts.length - 1]
    return name
  }

  barColor(type: string) {
    return StringToColor(type)
  }

  barY(stat: StatDay, i: number) {
    const heightOfPrevious = Array.from({ length: i }, (_v, i) => i)
      .map((i) => this.barHeight(stat, i))
      .reduce((a, b) => a + b, 0)
    return 100 - this.barHeight(stat, i) - heightOfPrevious
  }
}
