





















































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DocumentInputGroup from './DocumentInputGroup.vue'
import ElementUnit from '../../agreement/element/ElementUnit.vue'
import ElementTotalPrice from '../../agreement/element/ElementTotalPrice.vue'
import DocumentElementVAT from './DocumentElementVAT.vue'
import { IAgreementElementV2 } from '@/shared/components/interfaces/agreements.interface'
import DocumentUnitPrice from './DocumentUnitPrice.vue'
import DocumentFrequency from './DocumentFrequency.vue'
import NumberInput from '../../agreement/schedule/inputs/NumberInput.vue'
import NumberHelper from '@/shared/helpers/numberHelper'

@Component({
  components: {
    DocumentInputGroup,
    ElementUnit,
    DocumentElementVAT,
    ElementTotalPrice,
    DocumentUnitPrice,
    DocumentFrequency,
    NumberInput,
  },
})
export default class DocumentProductElement extends Vue {
  @Prop() element: IAgreementElementV2
  @Prop() currency: string
  @Prop() language: string
  @Prop() existingElement: boolean
  @Prop({ default: false }) showHeader: boolean

  get vatPercentage() {
    return this.element.vatPercentage
  }

  get isSubscriptionProduct() {
    const pricing = this.element.pricing || (this.element as any).pricingType
    return ['per_delivery', 'per_month'].includes(pricing)
  }

  get clientTotalPrice() {
    return NumberHelper.makeFloat(this.clientPrice! * this.quantity!)
  }

  get supplierTotalPrice() {
    return NumberHelper.makeFloat(this.supplierPrice! * this.quantity!)
  }

  get supplierPrice() {
    return this.element.supplierPrice
  }

  get clientPrice() {
    return this.element.clientPrice
  }

  get description() {
    return this.element.description
  }

  get quantity() {
    return this.element.quantity
  }

  get existingVat() {
    return this.element.vatPercentage
  }

  parse(value: string, field: keyof IAgreementElementV2) {
    if (this.isNumeric(value) || !value) this.updateVal(value, field)
  }
  isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  updateVal(value: string | number, field: keyof IAgreementElementV2) {
    this.$emit('updateElement', value, field)
  }
}
