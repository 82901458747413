























































































import Vue from 'vue'
import Component from 'vue-class-component'
import Ticket from '@/components/ticket/Ticket.vue'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import Country from '@/shared_legacy/storeModules/country'
import Tickets from '@/shared_legacy/storeModules/tickets'
import { ITicket } from '@/shared/components/interfaces/tickets.interface'

const countryState = getModule(Country)
const ticketsState = getModule(Tickets)
@Component({
  components: { Ticket, Multiselect },
  metaInfo: {
    title: 'Tickets | Good Monday'
  }
})
export default class TicketsSocketPage extends Vue {
  countryState = countryState
  selectedCareUsers: ICareUser[] = []
  selectedDeadline: { text: string; value: number } | null = null
  filterBy = ''
  selectedSortBy = ''
  selectedByType: { type: string; text: string }[] = []
  deadlines: { text: string; value: number }[] = [
    { text: 'Today', value: 86400 },
    { text: 'Within an hour', value: 3600 },
    { text: 'Witin 3 hours', value: 10800 },
    { text: 'This week', value: 604800 },
    { text: 'Any time ', value: 0 }
  ]
  countries: { text: string; value: string }[] = [
    { text: 'UK', value: 'UK' },
    { text: 'DK', value: 'DK' }
  ]

  usersGroupedByCountry: { country: string; users: ICareUser[] }[] = [
    { country: 'DK', users: [] },
    { country: 'UK', users: [] },
    { country: '-', users: [{ id: '', name: 'Unassigned', countryCode: '', email: '' }] }
  ]
  filterbyTypes: { type: string; text: string }[] = [
    { type: 'Any', text: 'Any' },
    { type: 'ProcessAcceptedProposal', text: 'Accepted proposals' },
    { type: 'ProcessRejectedProposal', text: 'Rejected proposals' },
    { type: 'ProcessServiceRequest', text: 'New requests' },
    { type: 'AnswerCustomerChat', text: 'New messages' },
    { type: 'ProcessClosedRequest', text: 'Closed requests' },
    { type: 'ExpectCustomerResponse', text: 'Awaiting responses' },
    { type: 'ProcessUpdatedServiceRequest', text: 'Updated requests' }
  ]
  sortBy: string[] = ['Deadline', 'Created']
  ticketsState = ticketsState
  careUsers: ICareUser[] = []
  selectedPersons: string

  created() {
    this.fetchCustomerCareUsers()
    this.preserveSearch()
  }

  get tickets() {
    return this.ticketsState.tickets
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }
  get groupedCareUsers() {
    this.usersGroupedByCountry[0].users = []
    this.usersGroupedByCountry[1].users = []
    for (let user of this.careUsers) {
      if (user.countryCode === 'DK') {
        this.usersGroupedByCountry[0].users.push(user)
      } else if (user.countryCode === 'UK') {
        this.usersGroupedByCountry[1].users.push(user as ICareUser)
      }
    }
    return this.usersGroupedByCountry
  }

  preserveSearch() {
    const userFilter = window.localStorage.getItem('filterTicketsUser')
    const deadlineFilter = window.localStorage.getItem('filterTicketsDeadline')
    const typeByFilter = window.localStorage.getItem('filterTicketsType')
    const sortTicketsOrder = window.localStorage.getItem('sortTicketsOrder')
    if (userFilter) {
      this.selectedCareUsers = JSON.parse(userFilter)
    }
    if (typeByFilter) {
      this.selectedByType = JSON.parse(typeByFilter as string)
    }
    if (deadlineFilter) {
      this.selectedDeadline = JSON.parse(deadlineFilter)
    }
    if (sortTicketsOrder) {
      this.selectedSortBy = sortTicketsOrder
    }
  }

  setUsersStore(users: ICareUser[]) {
    window.localStorage.setItem('filterTicketsUser', JSON.stringify(users))
  }

  setDeadlineStore(deadline: { text: string; value: number }) {
    window.localStorage.setItem('filterTicketsDeadline', JSON.stringify(deadline))
  }

  setTicketTypeStore(type: { text: string; type: number }) {
    window.localStorage.setItem('filterTicketsType', JSON.stringify(type))
  }

  setTicketSortStore(type: string) {
    window.localStorage.setItem('sortTicketsOrder', type)
  }

  fetchCustomerCareUsers() {
    TasksApi.getCareUsers().then(data => {
      this.careUsers = data
    })
  }

  filteredTicketsbyName(tickets: ITicket[]) {
    let filteredByName = []
    if (this.filterBy === '') {
      return tickets
    }
    for (const ticket of tickets) {
      if (ticket.title.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(ticket)
      }
    }
    return filteredByName
  }

  filteredTicketsbyAssignee(tickets: ITicket[]) {
    let filteredByAssignee = []
    if (this.selectedCareUsers.length === 0) {
      return tickets
    }
    for (const ticket of tickets) {
      for (const assignee of this.selectedCareUsers) {
        if (ticket.assignee?.name === assignee.name || (!ticket.assignee && assignee.name === 'Unassigned')) {
          filteredByAssignee.push(ticket)
        }
      }
    }
    return filteredByAssignee
  }
  filteredTicketsbyType(tickets: ITicket[]) {
    let filteredByType = []
    const typeAny = (element: { type: string; text: string }) => element.type === 'Any'
    if (!this.selectedByType || this.selectedByType.length === 0 || this.selectedByType.some(typeAny) === true) {
      return tickets
    }
    for (const ticket of tickets) {
      for (const type of this.selectedByType) {
        if (ticket.type === type.type) {
          filteredByType.push(ticket)
        }
      }
    }
    return filteredByType
  }

  filteredDeadline(tickets: ITicket[]) {
    let filteredByDeadline = []
    if (this.selectedDeadline === null || this.selectedDeadline?.value === 0) {
      return tickets
    }
    for (const ticket of tickets) {
      const deadlineTime = moment().add(this.selectedDeadline!.value, 'seconds')
      const expiresAtMoment = moment(ticket.expiresAt)
      if (expiresAtMoment.isBefore(deadlineTime)) {
        filteredByDeadline.push(ticket)
      }
    }
    return filteredByDeadline
  }

  filteredTicketsbyCountry(tickets: ITicket[]) {
    let filteredByCountry = []
    if (this.selectedCountry === null) {
      return tickets
    }
    for (const ticket of tickets) {
      if (ticket?.client?.countryCode === this.selectedCountry) {
        filteredByCountry.push(ticket)
      }
    }
    return filteredByCountry
  }

  get filteredTickets() {
    const filteredByName = this.filteredTicketsbyName(this.tickets)
    const filteredByAssignee = this.filteredTicketsbyAssignee(filteredByName)
    const filteredDeadline = this.filteredDeadline(filteredByAssignee)
    const filteredCountry = this.filteredTicketsbyCountry(filteredDeadline)
    let filteredByType = this.filteredTicketsbyType(filteredCountry)
    if (this.selectedSortBy === 'Created') {
      filteredByType.sort(function (a, b) {
        return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
      })
    } else {
      filteredByType.sort(function (a, b) {
        return moment(a.expiresAt).valueOf() - moment(b.expiresAt).valueOf()
      })
    }
    return filteredByType
  }
  async assignTicket(ticketId: string, userId: string) {
    await TasksApi.assignTicket(ticketId, userId)
  }

  async postponeTicket(ticketId: string, seconds: number) {
    await TasksApi.snoozeTicket(ticketId, seconds)
  }
}
