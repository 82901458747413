




























import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IClientData } from '@/shared/components/interfaces/clients.interface'

@Component({
  components: {},
})
export default class ClientCompanyUsers extends Vue {
  @Prop() loading: boolean
  @Prop() users: IClientData
  mounted() {
    console.log('this.users', this.users)
  }
}
