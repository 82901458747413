class SortByHelper {
  public static sortByText(sortData: any[], key: string, ascending: boolean, isDate?: boolean) {
    const ascendingArray = sortData.sort((a: any, b: any) => {
      if (isDate && ascending) {
        return new Date(a[key]).getTime() - new Date(b[key]).getTime()
      }
      if (isDate && !ascending) {
        return new Date(b[key]).getTime() - new Date(a[key]).getTime()
      }

      if (a[key] === b[key]) {
        return 0
      } else if (a[key] === undefined || a[key] === null) {
        return 1
      } else if ((a[key] !== undefined || a[key] === null) && (b[key] === undefined || b[key] === null)) {
        return -1
      } else if (ascending) {
        return a[key] < b[key] ? -1 : 1
      } else {
        return a[key] < b[key] ? 1 : -1
      }
    })
    return ascendingArray
  }

  public static sortByNumber(sortData: any[], key: string, ascending: boolean) {
    const ascendingArray = sortData.sort((a: any, b: any) => {
      if (ascending) {
        return parseFloat(a[key]) - parseFloat(b[key])
      } else {
        return parseFloat(b[key]) - parseFloat(a[key])
      }
    })
    return ascendingArray
  }
}

// Export a singleton instance in the global namespace
export default SortByHelper
