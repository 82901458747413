































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
//@ts-ignore
import ClickOutside from 'vue-click-outside'
import { getModule } from 'vuex-module-decorators'
import datePickerModalStore from '@/shared_legacy/storeModules/datePickerModalStore'
import SelectDateModal from '@/shared_legacy/components/atoms/SelectDateModal.vue'
const datePickerState = getModule(datePickerModalStore)

@Component({
  directives: { ClickOutside },
  components: { SelectDateModal }
})
export default class RemindMeMenu extends Vue {
  @Prop() setDropdownValue: (timeout: number) => void
  @Prop({ default: () => ({}) }) sendMessage: (
    ignoreMessage: boolean,
    parentNode: HTMLDivElement,
    file: File | null
  ) => void
  @Prop({ default: null }) file: File
  @Prop({ default: false }) toggleMenu: boolean
  @Prop({ default: false }) disableCloseTicket: boolean
  @Prop({ default: false }) ignoreMessage: boolean
  @Prop({}) ticketId: string
  @Prop({ default: 'Send and set reminder' }) setReminderTitle: string

  datePickerState = datePickerState
  remindMeToggle = false
  activeIndex = 1
  menuItems: { title: string; actionValue: number }[] = [
    {
      title: 'Send & Close',
      actionValue: 0
    },
    {
      title: '30 minutes',
      actionValue: 30
    },
    {
      title: '2 hours',
      actionValue: 60 * 2
    },
    {
      title: '6 hours',
      actionValue: 60 * 6
    },
    {
      title: '1 day',
      actionValue: 60 * 24
    },
    {
      title: '3 days',
      actionValue: 60 * 24 * 3
    },
    {
      title: '1 week',
      actionValue: 60 * 24 * 7
    },
    {
      title: '1 month',
      actionValue: 60 * 24 * 7 * 4
    },
    {
      title: '3 months',
      actionValue: 60 * 24 * 7 * 4 * 3
    },
    {
      title: 'Custom time',
      actionValue: NaN
    }
  ]

  prev: any

  @Watch('toggleMenu')
  onToggleMenu(value: boolean) {
    this.remindMeToggle = value
  }

  mounted() {
    setTimeout(() => {
      if (this.toggleMenu) this.remindMeToggle = this.toggleMenu
    }, 1)
    this.prev = document.onkeydown
    document.onkeydown = this.onKeyDown
  }

  beforeDestroy() {
    document.onkeydown = this.prev
  }

  onKeyDown(e: KeyboardEvent) {
    e.preventDefault()
    e.stopPropagation()
    if (this.remindMeToggle) {
      switch (e.key) {
        case 'ArrowDown':
          if (this.activeIndex === this.menuItems.length - 1) {
            this.activeIndex = 1
            return
          }
          this.activeIndex++
          return
        case 'ArrowRight':
          if (this.activeIndex === this.menuItems.length - 1) {
            this.activeIndex = 1
            return
          }
          this.activeIndex++
          return
        case 'ArrowUp':
          if (this.activeIndex === 1) {
            this.activeIndex = this.menuItems.length - 1
            return
          }
          this.activeIndex--
          return
        case 'ArrowLeft':
          if (this.activeIndex === 1) {
            this.activeIndex = this.menuItems.length - 1
            return
          }
          this.activeIndex--
          return
        case 'Enter': {
          const item = this.menuItems[this.activeIndex]
          if (item) {
            this.remindMeAction(item.actionValue)
          } else {
            this.activeIndex = 0
          }
          return
        }
        case 'Escape':
          this.closeRemindMeMenu()
          return
      }
    }
  }

  closeTicket() {
    this.$emit('close')
  }

  remindMeAction(timer: number) {
    if (timer === 0) {
      this.closeTicket()
      this.sendMessage(this.ignoreMessage, document.getElementById('messageBox') as HTMLDivElement, this.file)
      this.closeRemindMeMenu()
    } else if (isNaN(timer)) {
      if (!this.ticketId || this.ticketId.indexOf(':') === -1) {
        this.setDropdownValue(300)
        this.sendMessage(this.ignoreMessage, document.getElementById('messageBox') as HTMLDivElement, this.file)
        setTimeout(() => {
          this.datePickerState.setTaskId('expect_customer_response:' + this.ticketId)
          this.datePickerState.setModal(true)
        }, 100)
      } else {
        this.datePickerState.setModal(true)
        this.datePickerState.setTaskId(this.ticketId)
      }
    } else {
      this.setDropdownValue(timer)
      this.sendMessage(this.ignoreMessage, document.getElementById('messageBox') as HTMLDivElement, this.file)
      this.closeRemindMeMenu()
    }
  }

  closeRemindMeMenu() {
    if (this.remindMeToggle) {
      this.remindMeToggle = false
      this.$emit('toggleMenu', false)
    }
  }
}
