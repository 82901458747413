import Vue from 'vue'
import VueRouter from 'vue-router'
import globals from './globals'

import Layout from './pages/layouts/Layout.vue'
import LayoutBlank from './pages/layouts/LayoutBlank.vue'

import Login from './pages/LoginPage.vue'
import TicketsSocketPage from './pages/TicketsSocketPage.vue'
import Clients from './pages/ClientsPage.vue'
import ClientDetails from './pages/ClientDetailsPage.vue'
import Invoices from './pages/InvoicesPage.vue'
import AgreementDetails from './pages/AgreementDetailsPage.vue'
import AgreementCreate from './pages/AgreementCreatePage.vue'
import Proposals from './pages/ProposalsPage.vue'
import RequestDetails from './pages/RequestDetailsPage.vue'
import Orders from './pages/OrdersPage.vue'
import Subscriptions from './pages/SubscriptionsPage.vue'
import FlagsPanel from './pages/flags/FlagsPanelPage.vue'
import EventMonitor from './pages/events/EventMonitorPage.vue'

import { getModule } from 'vuex-module-decorators'
import ShortcutStore from '@/store/modules/shortcutStore'
import TopUpPage from './pages/TopUpPage.vue'
import Payments from './pages/PaymentsPage.vue'
const shortcutState = getModule(ShortcutStore)

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'TicketsSocketPage',
        component: TicketsSocketPage,
      },
      {
        path: '/orders',
        name: 'Orders',
        component: Orders,
      },
      {
        path: '/subscriptions',
        name: 'Subscriptions',
        component: Subscriptions,
      },
      {
        path: '/proposals',
        name: 'Proposals',
        component: Proposals,
      },
      {
        path: '/topup',
        name: 'TopUp',
        component: TopUpPage,
      },
      {
        path: '/clients/:id/request/:requestId/:activateProposal?',
        name: 'RequestDetails',
        component: RequestDetails,
      },
      {
        path: '/invoices',
        name: 'Invoices',
        component: Invoices,
      },
      {
        path: '/clients',
        name: 'Clients',
        component: Clients,
      },
      {
        path: '/payments',
        name: 'Payments',
        component: Payments,
      },
      {
        path: '/clients/:id',
        name: 'ClientDetails',
        component: ClientDetails,
        //@ts-ignore
        beforeEnter: (to, from, next) => {
          if (to.params?.id?.length === 36) {
            next()
          } else {
            next('/')
          }
        },
      },
      {
        path: '/clients/:id/agreements/new/:type',
        name: 'AgreementCreate',
        component: AgreementCreate,
      },
      {
        path: '/clients/:id/agreements/:agreementId',
        name: 'AgreementDetails',
        component: AgreementDetails,
      },
      {
        path: '/flags',
        name: 'FlagsPanel',
        component: FlagsPanel,
      },
    ],
  },
  {
    path: '/',
    component: LayoutBlank,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/events',
        name: 'Events',
        component: EventMonitor,
      },
    ],
  },
  {
    path: '/',
    name: 'LayoutBlank',
    component: LayoutBlank,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    (window as any).layoutHelpers &&
    (window as any).layoutHelpers.isSmallScreen() &&
    !(window as any).layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => (window as any).layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, _from, next) => {
  for (const el of [...shortcutState.elements]) {
    if (!el.preserve) {
      shortcutState.unregisterElement(el.id)
    }
  }

  // Set loading state
  document.body.classList.add('app-loading')
  const jwt = localStorage.getItem(globals().accessTokenKey)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth) {
    if (!jwt) {
      return next(`/login/?redirect=${to.fullPath}`)
    }
  } else {
    if (jwt) {
      return next('/')
    }
  }
  return next()
})

export default router
