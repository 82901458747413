










































import { Vue, Component, Prop } from 'vue-property-decorator'
import AgreementProductItemV2 from './AgreementProductItemV2.vue'
import { IAgreementElementV2, IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import RevisionMixin from '@/mixins/RevisionMixin'

@Component({
  components: { AgreementProductItemV2 },
  mixins: [RevisionMixin]
})
export default class AgreementRevisionBody extends Vue {
  @Prop({ required: true }) revision: IAgreementRevisionV2
  @Prop({ required: true }) currency: string
  @Prop({ required: false }) isAdhoc: boolean
  prepaymentPatterns: {
    adhoc: IAgreementElementV2[]
    per_delivery: IAgreementElementV2[]
    per_month: IAgreementElementV2[]
  }

  get prepaymentPatternsArray() {
    const patterns = []
    if (this.prepaymentPatterns) {
      patterns.push(this.prepaymentPatterns.adhoc)
      patterns.push(this.prepaymentPatterns.per_delivery)
      patterns.push(this.prepaymentPatterns.per_month)
    }
    return patterns
  }

  canAddBottomMargin(index: number) {
    if (this.prepaymentPatternsArray.length) {
      let sum = this.prepaymentPatternsArray.reduce((acc, value) => {
        if (value.length) acc += 1
        return acc
      }, 0)
      return sum > 1 && index === 1
    }
    return false
  }

  elementLabel(index: number) {
    switch (index) {
      case 1:
        return 'Per delivery'
      case 2:
        return 'Per month'
      default:
        return 'One time purchase'
    }
  }
}
