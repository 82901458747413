





































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { IOfferingCategory, ISupplier } from '../../shared/components/interfaces/agreements.interface'
import Multiselect from 'vue-multiselect'
import { getModule } from 'vuex-module-decorators'
import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'

const agreementState = getModule(AgreementsV2)

@Component({ components: { Multiselect } })
export default class DocumentWhoSection extends Vue {
  @Prop() filteredSuppliers: ISupplier[]
  @Prop() errorLoadingSuppliers: boolean
  @Prop() loadingSuppliers: boolean
  @Prop() supplierId: string
  @Prop() categoryId: string
  @Prop() invoiceType: string
  @Prop() country: string

  loadingCategories = true
  errorLoadingCategories = false
  agreementState = agreementState

  get supplier() {
    return this.filteredSuppliers.filter((supplier: ISupplier) => supplier.id === this.supplierId)
  }

  get allCategories() {
    return this.agreementState.allCategories
  }

  get filteredCategories() {
    return this.agreementState.allCategories.filter((category: IOfferingCategory) =>
      this.isSubscription ? category.enableSubscriptions : category.enableAdhocs
    )
  }

  get isSubscription() {
    return this.invoiceType === 'subscription'
  }

  get serviceArea() {
    return this.country === 'DK' ? 'CPH' : 'LON'
  }

  get category() {
    return this.allCategories.filter((category: IOfferingCategory) => category.slug === this.categoryId)
  }
  updateSupplierId(supplier: ISupplier) {
    this.$emit('changeSupplier', supplier.id)
  }

  updateCategory(category: IOfferingCategory) {
    this.$emit('changeCategory', category.slug)
  }

  async mounted() {
    try {
      await this.agreementState.getCategories(this.serviceArea)
      this.loadingCategories = false
    } catch (e) {
      this.loadingCategories = false
      this.errorLoadingCategories = true
    }
  }
}
