import moment from 'moment'
import {
  ICalendarEventItemV2,
  IFullCalendarEvent,
  ICalendarEventItem,
} from '../components/interfaces/calendar.interface'

/**
 * This is a model working over the ICalendarEventItemV2 interface
 *
 * Functions should be pure, static and accept the ICalendarEventItemV2
 * interface as the first parameter.
 *
 * The only exception to accepting ICalendarEventItemV2 as the first
 * parameter is when using the class as a static factory to build
 * the interface from another data structure.
 *
 * Example:
 * public static from_form(form: ICalendarEventItemV2): ICalendarEventItemV2
 */

export default abstract class CalendarEventItemV2Model {
  public static isAllDay(event: ICalendarEventItemV2): boolean {
    return (
      CalendarEventItemV2Model.startTime(event).format('HH:mm:ss') === '00:00:00' &&
      CalendarEventItemV2Model.endTime(event).format('HH:mm:ss') === '00:00:00'
    )
  }

  public static getEventTime(event: ICalendarEventItemV2): string {
    return `${CalendarEventItemV2Model.startTime(event).format('HH:mm')}${
      event.type === 'arrival' ? ' - ' + CalendarEventItemV2Model.endTime(event).format('HH:mm') : ''
    }`
  }

  public static startTime(event: ICalendarEventItemV2): moment.Moment {
    return moment(event.startTime)
  }

  public static endTime(event: ICalendarEventItemV2): moment.Moment {
    return moment(event.endTime)
  }

  public static isMultiDayEvent(event: ICalendarEventItemV2): boolean {
    return CalendarEventItemV2Model.isAllDay(event)
      ? !CalendarEventItemV2Model.startTime(event).isSame(
          CalendarEventItemV2Model.endTime(event).subtract(1, 'day'),
          'day'
        )
      : !CalendarEventItemV2Model.startTime(event).isSame(CalendarEventItemV2Model.endTime(event), 'day')
  }

  public static toFullCalendarEvent(event: ICalendarEventItemV2): IFullCalendarEvent {
    return {
      id: event.id,
      title: event.title,
      description: event.description,
      start: moment(event.startTime)
        .local()
        .format('YYYY-MM-DD[T]HH:mm'),
      end: moment(event.endTime)
        .local()
        .format('YYYY-MM-DD[T]HH:mm'),
      allDay: CalendarEventItemV2Model.isAllDay(event),
      classNames: [event.isRecurring ? '' : 'fc-event-ad-hoc', event.startTime === event.endTime ? '' : 'event-range'],
      extendedProps: {
        type: event.type,
        isRecurring: event.isRecurring,
        link: event.link,
      },
    }
  }

  public static toICalendarEventItem(event: ICalendarEventItemV2): ICalendarEventItem {
    return {
      agreementId: event.link ? event.link!.split(':')[1] : '',
      date: moment(event.startTime)
        .local()
        .format('YYYY-MM-DD[T]HH:mm'),
      description: event.description!,
      title: event.title,
      icon: null,
    }
  }
}
