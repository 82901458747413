












































































import { IProposalOverview } from '@/shared/services/api.interface'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'
import ClientAgreementTicket from '@/components/clients/ClientAgreementTicket.vue'
import ClientTicketHelper from '@/shared_legacy/helpers/clientTicketHelper'
import Tickets from '@/shared_legacy/storeModules/tickets'
import { getModule } from 'vuex-module-decorators'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'
import Auth from '@/shared/storeModules/auth'
import Country from '@/shared_legacy/storeModules/country'
import Multiselect from 'vue-multiselect'

const ticketsState = getModule(Tickets)
const userState = getModule(Auth)
const countryState = getModule(Country)

@Component({ components: { ClientAgreementTicket, Multiselect } })
export default class Proposals extends Vue {
  careUsers: ICareUser[] = []
  filterBy = ''
  allProposals: IProposalOverview[] = []
  loading = true
  errorLoading = false
  sortBy = 'date_created'
  sortDesc = false
  isExpanded = true
  tableItems: any[] = []
  ticketsState = ticketsState
  userState = userState
  countryState = countryState

  proposalTypes = ['All', 'Ad Hoc', 'Subscription']
  selectedProposalType = 'All'

  fields = [
    { key: 'service', sortable: true },
    { key: 'client', sortable: true },
    { key: 'supplier', sortable: true },
    { key: 'category', sortable: true },
    { key: 'requested', sortable: true },
    { key: 'type', sortable: false },
    { key: 'ticket', sortable: false }
  ]

  get user() {
    return this.userState.user
  }

  get tasks() {
    return this.ticketsState.tickets
  }

  requested(timestamp: string) {
    return moment(timestamp).format('ll')
  }

  type(invoiceSchedule: string) {
    if (invoiceSchedule === 'monthly') return 'Subscription'
    if (invoiceSchedule === 'once') return 'Ad Hoc'
    return 'N/A'
  }

  category(data: string) {
    return data.split('_').join(' ')
  }

  createProposal(proposal: IProposalOverview, _rowNumber: number, event: any) {
    if (!ClientTicketHelper.shouldAvoidNavigation(event)) {
      this.$router.push(`/clients/${proposal.clientId}/request/${proposal.requestId}`)
    }
  }

  get tableHeight() {
    return this.isExpanded ? '750px' : '295px'
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }

  filterByType(proposals: IProposalOverview[]) {
    return proposals.filter(proposal => {
      switch (this.selectedProposalType) {
        case 'All':
          return true
        case 'Ad Hoc':
          return proposal.invoiceSchedule === 'once'
        case 'Subscription':
          return proposal.invoiceSchedule === 'monthly'
      }
    })
  }

  filterByCountry(proposals: IProposalOverview[]) {
    let filteredByCountry = []
    if (!this.selectedCountry) return proposals
    for (const proposal of proposals) {
      if (proposal.currency === 'DKK' && this.selectedCountry === 'DK') filteredByCountry.push(proposal)
      if (proposal.currency === 'GBP' && this.selectedCountry === 'UK') filteredByCountry.push(proposal)
    }
    return filteredByCountry
  }

  filterBySearch(proposals: IProposalOverview[]) {
    return proposals.filter(agreement => {
      return (
        (agreement.service === null && this.filterBy === '') ||
        (agreement.service !== null && agreement.service.toLowerCase().includes(this.filterBy.toLowerCase())) ||
        agreement.supplier?.toLowerCase().includes(this.filterBy.toLowerCase()) ||
        agreement.client?.toLowerCase().includes(this.filterBy.toLowerCase()) ||
        agreement.category?.toLowerCase().includes(this.filterBy.toLowerCase())
      )
    })
  }

  rowAttribute(item: IProposalOverview, _type: string) {
    return {
      'data-cy': `proposal-row-item--${item.id}`
    }
  }

  badgeColorFromType(data: string) {
    if (data === 'monthly') return 'success'
    if (data === 'once') return 'info'
  }

  sort() {
    this.tableItems = this.allProposals
    this.tableItems.sort((a, b) => b.status - a.status)
    this.loading = false
  }

  get filterAgreements() {
    let filteredByCountry = this.filterByCountry(this.tableItems)
    let filteredByType = this.filterByType(filteredByCountry)
    let filteredBySearch = this.filterBySearch(filteredByType)
    return filteredBySearch
  }

  get itemsWithCustomIds() {
    for (let i = 0; i < this.filterAgreements.length; i++) {
      this.filterAgreements[i].key = ClientTicketHelper.ticketUniqueId(this.tasks, this.filterAgreements[i].id)
    }
    if (this.$refs.table) {
      //@ts-ignore
      this.$refs.table.refresh()
    }

    return this.filterAgreements
  }

  onFilterChange() {
    this.$forceUpdate()
  }

  async mounted() {
    try {
      this.allProposals = await AgreementsApi.getProposalsV2()
      this.careUsers = await TasksApi.getCareUsers()
      this.sort()
      this.loading = false
    } catch (e) {
      this.loading = false
      this.errorLoading = true
    }
  }
}
