//This is layout helpers from AppWork library https://uxpowered.com/products/appwork/v152/html-demo/layouts_helpers.html
export default class LayoutHelper {
  public static _layoutHelpers = (window as any).layoutHelpers

  public static _exec(fn: () => any) {
    return this._layoutHelpers && fn()
  }

  public static getLayoutSidenav() {
    return this._exec(() => this._layoutHelpers.getLayoutSidenav()) || null
  }

  public static getSidenav() {
    return this._exec(() => this._layoutHelpers.getSidenav()) || null
  }

  public static getLayoutNavbar() {
    return this._exec(() => this._layoutHelpers.getLayoutNavbar()) || null
  }

  public static getLayoutFooter() {
    return this._exec(() => this._layoutHelpers.getLayoutFooter()) || null
  }

  public static getLayoutContainer() {
    return this._exec(() => this._layoutHelpers.getLayoutContainer()) || null
  }

  //Returns true if the window width is less than 992px (lg breakpoint).
  public static isSmallScreen() {
    return this._exec(() => this._layoutHelpers.isSmallScreen())
  }

  public static isLayout1() {
    return this._exec(() => this._layoutHelpers.isLayout1())
  }

  public static isCollapsed() {
    return this._exec(() => this._layoutHelpers.isCollapsed())
  }

  public static isFixed() {
    return this._exec(() => this._layoutHelpers.isFixed())
  }

  public static isOffcanvas() {
    return this._exec(() => this._layoutHelpers.isOffcanvas())
  }

  public static isNavbarFixed() {
    return this._exec(() => this._layoutHelpers.isNavbarFixed())
  }

  public static isFooterFixed() {
    return this._exec(() => this._layoutHelpers.isFooterFixed())
  }

  public static isReversed() {
    return this._exec(() => this._layoutHelpers.isReversed())
  }

  public static setCollapsed(collapsed: boolean, animate = true) {
    this._exec(() => this._layoutHelpers.setCollapsed(collapsed, animate))
  }

  public static toggleCollapsed(animate = true) {
    this._exec(() => this._layoutHelpers.toggleCollapsed(animate))
  }

  public static setPosition(fixed: any, offcanvas: any) {
    this._exec(() => this._layoutHelpers.setPosition(fixed, offcanvas))
  }

  public static setNavbarFixed(fixed: any) {
    this._exec(() => this._layoutHelpers.setNavbarFixed(fixed))
  }

  public static setFooterFixed(fixed: any) {
    this._exec(() => this._layoutHelpers.setFooterFixed(fixed))
  }

  public static setReversed(reversed: any) {
    this._exec(() => this._layoutHelpers.setReversed(reversed))
  }

  public static update() {
    this._exec(() => this._layoutHelpers.update())
  }

  public static setAutoUpdate(enable: any) {
    this._exec(() => this._layoutHelpers.setAutoUpdate(enable))
  }

  public static on(event: any, callback: any) {
    this._exec(() => this._layoutHelpers.on(event, callback))
  }

  public static off(event: any) {
    this._exec(() => this._layoutHelpers.off(event))
  }

  public static init() {
    this._exec(() => this._layoutHelpers.init())
  }

  public static destroy() {
    this._exec(() => this._layoutHelpers.destroy())
  }

  // Internal
  //

  public static _removeClass(cls: any) {
    this._exec(() => this._layoutHelpers._removeClass(cls))
  }
}
