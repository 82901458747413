

































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ClientsApi from '@/shared/services/api/ClientsApi'
import { IClientData, IClientAccount } from '@/shared/components/interfaces/clients.interface'

//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import FormHelper from '@/shared/helpers/formHelper'
import ClientDetailsModel from '@/shared/models/ClientDetailsModel'

@Component({ components: { DatePicker } })
export default class ClientDetailsModal extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) clientDetails: IClientData
  @Prop({ required: true }) addClientInformationCallback: (form: IClientData) => void
  error = false
  errorText = ''
  vatErrorText = ''
  isSaving = false
  floorsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  clientForm: IClientData
  languageOptions = ['da-DK', 'en-UK']
  formSubscriptionPaymentCondition: number | null | undefined = null
  formAdhocPaymentCondition: number | null | undefined = null

  paymentConditionType = 'Standard'
  options = [
    { text: 'Standard', value: 'Standard' },
    { text: 'Special', value: 'Special' }
  ]

  invoicesSubscriptionStrategyOptions = [
    { text: 'One monthly', value: 'one_invoice' },
    { text: 'Per Subscription monthly', value: 'separate_invoices' }
  ]
  invoicesAdhocStrategyOptions = [
    { text: 'Per AdHoc Instantly', value: 'separate_invoices' },
    { text: 'One monthly', value: 'one_invoice' }
  ]

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }

  get disabledBtn() {
    return this.error || this.isSaving
  }

  setStandardPaymentCondition() {
    if (this.paymentConditionType === 'Standard') {
      this.formSubscriptionPaymentCondition = 30
      this.formAdhocPaymentCondition = 14
    }
  }

  resetError() {
    this.error = false
  }

  setTrialDate(date: Date) {
    this.clientForm.trialExpirationDate = moment(date).format('YYYY-MM-DD')
  }

  created() {
    this.clientForm = { ...this.clientDetails }
    this.clientForm.address = { ...this.clientDetails.address }
    this.paymentConditionType = ClientDetailsModel.paymentConditionType(this.clientForm)
    this.formSubscriptionPaymentCondition = this.clientDetails.paymentConditionForSubscription
    this.formAdhocPaymentCondition = this.clientDetails.paymentConditionForAdhoc
  }

  handleCancel() {
    this.clientForm = { ...this.clientDetails }
    this.formSubscriptionPaymentCondition = this.clientDetails.paymentConditionForSubscription
    this.formAdhocPaymentCondition = this.clientDetails.paymentConditionForAdhoc
    this.paymentConditionType = ClientDetailsModel.paymentConditionType(this.clientForm)
    this.error = false
    this.errorText = ''
    this.$emit('showModal')
  }
  clientVatNoChanged(e: KeyboardEvent) {
    this.resetError()
    this.vatErrorText = FormHelper.checkVATValidity(e, this.clientDetails.address.country)
  }

  submitData() {
    this.isSaving = true

    const updatedForm: IClientAccount = { ...this.clientForm } as unknown as IClientAccount
    updatedForm.numEmployees = parseInt(updatedForm.numEmployees as string)
    updatedForm.numSquaremeters = parseInt(updatedForm.numSquaremeters as string)
    if (this.formSubscriptionPaymentCondition && this.formAdhocPaymentCondition) {
      updatedForm.paymentConditionForSubscription = parseInt(this.formSubscriptionPaymentCondition as unknown as string)
      updatedForm.paymentConditionForAdhoc = parseInt(this.formAdhocPaymentCondition as unknown as string)
    }

    ClientsApi.saveClientData(updatedForm)
      .then((res) => {
        this.addClientInformationCallback(res)
        this.showModal = false
        this.isSaving = false
        this.error = false
      })
      .catch((err) => {
        this.isSaving = false
        this.error = true
        this.errorText = err.response.data.error
      })
  }
}
