





























import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementRevisionV2 } from '@/shared/components/interfaces/agreements.interface'
import RevisionMixin from '@/mixins/RevisionMixin'
import AgreementRevisionBody from './AgreementRevisionBody.vue'

@Component({
  components: { AgreementRevisionBody },
  mixins: [RevisionMixin]
})
export default class AgreementRevision extends Vue {
  @Prop({ required: true }) revision: IAgreementRevisionV2
  @Prop({ required: true }) isCurrentRevision: boolean
  @Prop({ required: true }) prepaid: boolean
  @Prop({ required: true }) currency: string
}
