










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
//@ts-ignore
import uuid from 'uuid-random'
import { getModule } from 'vuex-module-decorators'
import { IAgreementElementV2, IAgreementV2CreatePayload } from '@/shared/components/interfaces/agreements.interface'
import Agreements from '@/shared_legacy/storeModules/agreements'
import CreateNewDocument from '../agreementAndProposal/CreateNewDocument.vue'
import AgreementsApi from '@/shared/services/api/agreementsApi'

const agreementState = getModule(Agreements)

@Component({
  components: {
    CreateNewDocument
  }
})
export default class AgreementDetailsCreateNew extends Vue {
  @Prop() language: string
  @Prop() country: string
  @Prop() proposalId: string
  agreementState = agreementState
  agreementId = uuid()
  validationErrors: string[] = []

  async save(agreement: IAgreementV2CreatePayload, _agreementId: string) {
    if (await this.areAgreementFieldsValid(agreement)) {
      let payload = this.manipulateAgreementObj(agreement)
      this.createAgreement(payload, this.agreementId)
    }
    this.validationErrors = []
  }

  manipulateAgreementObj(agreement: IAgreementV2CreatePayload) {
    let payload = JSON.parse(JSON.stringify(agreement))
    return payload
  }

  async areAgreementFieldsValid(agreement: IAgreementV2CreatePayload) {
    let isValid = false
    if (agreement.title && agreement.description && agreement.supplierId && agreement.category) {
      if (agreement.invoiceSchedule === 'once') {
        if (!agreement.prepaid) isValid = true
        else {
          if (agreement.prepaymentPatterns!.length) {
            isValid = this.validateElements(agreement.prepaymentPatterns!)
          }
        }
      } else {
        if (agreement.startDate) {
          if (!agreement.prepaid) isValid = true
          else {
            if (agreement.prepaymentPatterns!.length) {
              isValid = this.validateElements(agreement.prepaymentPatterns!)
            }
          }
        }
      }
    }
    if (!isValid) {
      this.$toasted.error('Please fill in the required fields', {
        icon: 'ion ion-ios-warning',
        className: 'data-cy-toasted'
      })
    }
    return isValid
  }

  validateElements(prepaymentPatterns: IAgreementElementV2[]) {
    var allElementsValid = true
    for (var element of prepaymentPatterns!) {
      var elementValid = false
      if (element.description && element.quantity && element.clientPrice && element.unit && element.schedule) {
        elementValid = true
      }
      if (!elementValid) allElementsValid = false
    }
    if (allElementsValid) return true
    return false
  }

  createAgreement(agreementData: IAgreementV2CreatePayload, agreementId: string) {
    AgreementsApi.createAgreementV2(agreementId, agreementData)
      .then(_data => {
        this.$router.push(`/clients/${this.$route.params.id}/agreements/${agreementId}`)
      })
      .catch(e => {
        console.log(e)
        this.$toasted.error('Error: Agreement not created')
      })
  }
}
