import API from '@/shared/billie/api'

export interface IFavorite {
  userId: string
  offeringId: string
  savedAt: Date
}

export default class Favorites extends API {
  public async list(userId: string): Promise<IFavorite[]> {
    return this._get(`/api/users/${userId}/favorites`)
  }
  public async create(userId: string, offeringId: string): Promise<IFavorite> {
    return this._post(`/api/users/${userId}/favorites/${offeringId}`)
  }
}
