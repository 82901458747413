













import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IAgreementElement } from '@/shared/components/interfaces/agreements.interface'
import AgreementUnitSelect from '../AgreementUnitSelect.vue'

@Component({ components: { AgreementUnitSelect } })
export default class ElementUnit extends Vue {
  @Prop({ required: true }) element: IAgreementElement
  @Prop({ default: 'en-UK' }) invoiceLanguage: string
  @Prop({ default: true }) showLabel: string
}
