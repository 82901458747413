















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import ClientsApi from '@/shared/services/api/ClientsApi'

@Component({
  components: {}
  // mixins: [RevisionMixin]
})
export default class ClientVatModal extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) clientDetails: IClientData
  selectedVATOption = null
  error = false

  get showModal() {
    return this.modalShow
  }

  set showModal(modalShow: boolean) {
    this.$emit('showModal', modalShow)
    return
  }

  //?  Create new VAT for handling company changing VAT e.g company mergers
  async handleNewVat() {
    await ClientsApi.transferToNewCompany(this.clientDetails.id!, this.clientDetails.identification)
    console.log('New VAT')
    this.$emit('showModal')
  }

  //?  Edit existing VAT for handling VAT typo or mistake
  async handleEditVat() {
    await ClientsApi.editCompanyId(this.clientDetails.id!, this.clientDetails.identification)
    console.log('Edit VAT')
    this.$emit('showModal')
  }

  resetError() {
    this.error = false
  }
}
