









import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelField from '@/shared_legacy/components/atoms/LabelField.vue'

@Component({
  components: { LabelField }
})
export default class GrayMessageDateLine extends Vue {
  @Prop() date: string
  @Prop() noBorder: boolean
}
