


































































































































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import ClientDetails from './ClientDetails.vue'
import ClientDetailsModal from './ClientDetailsModal.vue'
import moment from 'moment'
import { IUser } from '@/shared/storeModules/auth'
import SentNotificationModel from '../../shared/models/SentNotificationModel'
import ClientDetailsModel from '@/shared/models/ClientDetailsModel'

@Component({
  components: { ClientDetails, ClientDetailsModal }
})
export default class ClientDetailsHeader extends Vue {
  @Prop() loading: boolean
  @Prop() clientDetails: IClientData
  @Prop() back: { text: string; link: string }
  @Prop() badge: { text: string; color: string } | null
  @Prop({ default: 'light' }) theme: 'light' | 'dark'
  @Prop() title: string
  @Prop({ required: true }) clientDetailsHeaderCallback: (res: IClientData) => void
  @Prop({ default: false }) showModal: boolean

  // showModal = false
  clientDetailsVisible = false
  moment = moment
  SentNotificationModel = SentNotificationModel
  clients: IUser[] = []

  get sortedNotifications() {
    if (this.clientDetails.overdueNotifications && this.clientDetails.overdueNotifications.length > 0) {
      return this.clientDetails.overdueNotifications.sort((a: any, b: any) => {
        //@ts-ignore
        return moment(b.date) - moment(a.date)
      })
    } else {
      return null
    }
  }

  get clientStatusBadge() {
    return ClientDetailsModel.clientStatusBadge(this.clientDetails.status)
  }

  toggleModal() {
    this.$emit('toggleModal')
  }

  toggleClientDetails() {
    this.clientDetailsVisible = !this.clientDetailsVisible
  }

  async mounted() {
    this.clients = await this.$billie.users.listByClient(this.clientDetails.id!)
  }

  get lastLogin() {
    let lastLogin = 0
    for (const client of this.clients) {
      if (client.email.indexOf('@goodmonday.io') === -1 && client.lastActivityAt) {
        let lastActivityVal = moment(client.lastActivityAt).valueOf()
        if (lastActivityVal > lastLogin) {
          lastLogin = lastActivityVal
        }
      }
    }

    return lastLogin ? moment(lastLogin).fromNow() : '-'
  }
  clientInformationModalCallback(res: IClientData) {
    this.clientDetailsHeaderCallback(res)
  }
}
