
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import fileDrop from '@/directives/fileDrop'
import DragComponent from '@/shared_legacy/components/DragComponent.vue'
import FileHandlerApi from '@/shared/services/api/fileHandlerApi'
import { getModule } from 'vuex-module-decorators'
import store from '../../../store/store'
import Overlay from '@/shared_legacy/storeModules/overlay'
import ChatBox from './ChatBox.vue'

const overlayState = getModule(Overlay, store)

@Component({
  components: { DragComponent, ChatBox },
  directives: { fileDrop }
})
export default class SendMessageV2 extends Vue {
  @Prop({ required: true }) channel: any
  @Prop({ required: true }) disableTextArea: boolean
  @Prop({ default: false }) isClosingTicket: boolean
  @Prop() messageCallback: () => void
  @Prop() remindMe: (time: number) => void
  @Prop() updateContainerOffset: () => void
  @Prop() dropText: string
  @Prop() placeholderText: string
  @Prop() prefilledText: string
  @Prop() chatId: string

  taskTimeout: null | number = null
  overlayState = overlayState
  message = ''
  closed = false
  uploadInProgress = false
  isSendingMessage = false
  isImage = false
  enterClicked = false
  $logger: { error: (e: Error) => void }

  get dragging() {
    return this.overlayState.showOverlay
  }

  @Watch('prefilledText')
  onPrefilledTextChange(newValue: string, prevValue: string) {
    this.message = this.message.replace(prevValue, newValue)
  }

  @Watch('isClosingTicket')
  onTicketClose(value: boolean) {
    if (value) {
      this.closeTicket()
    }
  }

  mounted() {
    this.message = this.prefilledText || ''
  }

  componentCallback() {
    this.updateContainerOffset()
  }

  selectedDropdownValue(value: number) {
    this.taskTimeout = value
    this.closed = false
  }

  closeTicket(ignoreMessage = true) {
    this.closed = true
    this.taskTimeout = null
    if (ignoreMessage) this.sendMessageTrigger(ignoreMessage)
  }

  setOverlayStoreValue(payload: boolean) {
    this.overlayState.setShowOverlay(payload)
  }

  async sendMessageTrigger(ignoreMessage = false, parent?: HTMLDivElement, file?: File | null) {
    if (!this.isSendingMessage && (this.message !== '' || this.closed || file)) {
      this.isSendingMessage = true

      try {
        let information: { text: string; timeout?: number; closed?: boolean; attachment?: string } = {
          text: ignoreMessage ? '' : this.message
        }
        if (file) {
          this.uploadInProgress = true
          let formData = new FormData()
          formData.append('file', file)
          let response = await FileHandlerApi.uploadFile(formData)
          information = { ...information, attachment: response.id }
          this.uploadInProgress = false
        }
        information = { ...information, timeout: this.taskTimeout as number, closed: this.closed }

        this.channel.push('message:post', information)
        if (this.messageCallback) {
          this.messageCallback()
        }
      } catch (e: any) {
        this.$logger.error(e)
      } finally {
        this.uploadInProgress = false
        this.isSendingMessage = false
        if (!ignoreMessage) this.message = ''
        this.isImage = false
        if (parent) parent.dataset.value = ''
        this.updateContainerOffset()
      }
    }
  }
}
