
































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DocumentInputGroup from './DocumentInputGroup.vue'
import ElementUnit from '../../agreement/element/ElementUnit.vue'
import ElementTotalPrice from '../../agreement/element/ElementTotalPrice.vue'
import DocumentElementVAT from './DocumentElementVAT.vue'
import { IAgreementElementV2 } from '@/shared/components/interfaces/agreements.interface'
import DocumentUnitPrice from './DocumentUnitPrice.vue'
import DocumentFrequency from './DocumentFrequency.vue'
@Component({
  components: {
    DocumentInputGroup,
    ElementUnit,
    DocumentElementVAT,
    ElementTotalPrice,
    DocumentUnitPrice,
    DocumentFrequency,
  },
})
export default class DocumentSecondRow extends Vue {
  @Prop() element: IAgreementElementV2
  @Prop() currency: string

  get supplierPrice() {
    return this.element.supplierPrice
  }

  get supplierTotalPrice() {
    return this.element.supplierPrice! * this.element.quantity!
  }

  get oneTimePurchase() {
    const pricing = this.element.pricing || (this.element as any).pricingType
    if (pricing) return pricing === null
    return true
  }

  updateElementRecurrence(dataObj: any) {
    this.$emit('updateElementRecurrence', dataObj)
  }

  parse(value: string, field: keyof IAgreementElementV2) {
    if (this.isNumeric(value) || !value) this.updateVal(value, field)
  }
  isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  updateVal(value: string | number, field: string) {
    this.$emit('updateElement', value, field)
  }
}
