
























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import agreementSchedule from '@/shared_legacy/storeModules/agreementSchedule'
import { IAgreementInvoiceDataV2 } from '@/shared/components/interfaces/agreements.interface'
// @ts-ignore
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import uuid from 'uuid-random'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import AgreementUnitSelect from '../AgreementUnitSelect.vue'
import AgreementHelper from '@/helpers/AgreementHelper'
import NumberInput from './inputs/NumberInput.vue'
import NumberHelper from '@/shared/helpers/numberHelper'

const agreementScheduleState = getModule(agreementSchedule)

@Component({
  components: { Datepicker, AgreementUnitSelect, NumberInput }
})
export default class AgreementScheduleModal extends Vue {
  @Prop() selectedMonth: string
  @Prop({ default: '' }) selectedDate: string
  @Prop() agreementEnd: string
  @Prop({ default: '' }) agreementStart: string
  @Prop() currency: string
  @Prop() invoiceSchedule: string
  @Prop({ default: 'en-UK' }) language: string
  products: IAgreementInvoiceDataV2[] = []
  date = ''
  disabledDates: { to?: string | Date; from?: string | Date } = {
    to: ''
  }
  agreementScheduleState = agreementScheduleState
  supplierTotalPrice: { [key: string]: number } = {}

  get productsData() {
    return this.products
  }

  get canRemoveItem() {
    return this.products.length > 1
  }

  get hasMoreThanOneProduct() {
    return Object.keys(this.supplierTotalPrice).length > 1
  }

  get overallSupplierTotal() {
    const items = Object.keys(this.supplierTotalPrice)
    if (!items.length) return '0.00'
    return items.reduce((acc, key) => {
      acc += this.supplierTotalPrice[key] || 0
      return acc
    }, 0)
  }

  clientTotalPrice(product: IAgreementInvoiceDataV2) {
    return NumberHelper.makeFloat(product.quantity * product.clientPrice)
  }

  onQuantityChange(product: IAgreementInvoiceDataV2, _quantity: number) {
    if (product.supplierPrice > 0) {
      this.supplierTotalPrice[product.productId] = NumberHelper.makeFloat(product.quantity * product.supplierPrice)
    } else {
      product.supplierPrice = product.quantity
        ? NumberHelper.makeFloat(this.supplierTotalPrice[product.productId] / product.quantity)
        : 0
    }
  }

  onSupplierPriceChange(product: IAgreementInvoiceDataV2, _supplierPrice: number) {
    this.supplierTotalPrice[product.productId] = NumberHelper.makeFloat(product.quantity * product.supplierPrice)
  }

  onSupplierTotalPriceChange(product: IAgreementInvoiceDataV2, _supplierTotalPrice: number) {
    product.supplierPrice = product.quantity
      ? NumberHelper.makeFloat(this.supplierTotalPrice[product.productId] / product.quantity)
      : 0
  }

  mounted() {
    this.date = this.selectedDate
    this.disabledDates = {
      from: this.agreementEnd && new Date(this.agreementEnd),
      to: this.agreementStart && new Date(this.agreementStart)
    }
    this.addNewItem()
  }

  addNewItem() {
    let id = uuid()
    const defaultVat = AgreementHelper.defaultVatPercentageFromCurrency(this.currency)
    if (!this.date) {
      this.date = moment().format('YYYY-MM-DD')
    }
    this.$set(this.supplierTotalPrice, id, 0)
    this.products.push({
      date: this.date,
      productId: id,
      description: '',
      quantity: 1,
      clientPrice: 1,
      supplierPrice: 1,
      vatPercentage: defaultVat,
      unit: 'Pcs'
    })
  }

  removeItem(index: number) {
    if (this.canRemoveItem) {
      this.products.splice(index, 1)
    }
  }

  marginPercentage(clientUnitPrice: number, supplierUnitPrice: number) {
    const margin = ((clientUnitPrice - supplierUnitPrice) / clientUnitPrice) * 100
    return margin ? Number(margin).toFixed(2) : 0
  }

  formatProductPrices(product: IAgreementInvoiceDataV2) {
    return {
      ...product,
      clientPrice: product.clientPrice,
      quantity: product.quantity,
      supplierPrice: product.supplierPrice,
      vatPercentage: parseFloat(product.vatPercentage as unknown as string)
    }
  }

  addProducts() {
    const date = moment(this.date).format('YYYY-MM-DD')
    const products = this.products.map(product => {
      return {
        ...this.formatProductPrices(product),
        date
      }
    })

    AgreementsApi.createAmendmentV2(this.$route.params.agreementId, products).then(_res => {
      this.agreementScheduleState.getAgreementSchedule({
        agreementId: this.$route.params.agreementId,
        month: this.selectedMonth,
        type: this.invoiceSchedule
      })
      this.resetProducts()
    })
  }

  resetProducts() {
    this.$emit('closeModal')
    this.date = ''
    this.products = []
    this.addNewItem()
  }
}
