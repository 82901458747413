import API, { Payload } from '@/shared/billie/api'
import { IElement, IProposalV2 } from '@/shared/services/api.interface'

export interface ProposalPayload extends Payload {
    id: string
    title?: string
    description: string
    currency: string
    clientId: string
    supplierId: string
    creatorId: string
    category?: string
    prepaid?: boolean
    invoiceSchedule?: string
    requestId: string | null
    requestedBy: string | null
    offeringId: string | null
    elements: IElement[]
    startDate?: any
    deleted?: boolean
}

export default class Proposals extends API {
    public async get(id: string): Promise<IProposalV2> {
        return this._get(`api/v2/proposals/${id}`)
    }

    public async listByClient(clientId: string): Promise<IProposalV2[]> {
        return this._get(`api/clients/${clientId}/proposalsv2`)
    }

    public async create(payload: ProposalPayload) {
        return this._post(`/api/v2/proposals`, payload)
    }

    public async accept(id: string): Promise<any> {
        return this._post(`api/v2/proposals/${id}/accepts`)
    }

    public async reject(id: string) {
        return this._post(`api/v2/proposals/${id}/rejects`)
    }
}