import axios from './interceptor'
import { AxiosResponse } from 'axios'
import { ISuppliersService } from '../../components/interfaces/supplierData.interface'
import { IServiceResponse } from '../api.interface'
import { ISupplier } from '../../components/interfaces/agreements.interface'
import Auth from '@/shared/storeModules/auth'
import { getModule } from 'vuex-module-decorators'

const userState = getModule(Auth)

class SupplierApi {
  static async getSupplierData(id: string) {
    return axios.get('/api/suppliers/' + id).then((resp: AxiosResponse) => resp.data)
  }

  static async getSupplierServiceData(id: string): Promise<ISuppliersService | IServiceResponse> {
    return axios.get('/api/offerings/' + id).then((resp: AxiosResponse) => resp.data)
  }

  static async getOfferings() {
    return axios.get(`/api/area/${userState.user.serviceArea}/offerings`).then((resp: AxiosResponse) => resp.data)
  }

  static async getSuppliers(): Promise<ISupplier[]> {
    return axios.get(`/api/area/${userState.user.serviceArea}/suppliers`).then((resp: AxiosResponse) => resp.data)
  }

  static async getAllSuppliers(): Promise<ISupplier[]> {
    return axios.get(`/api/suppliers`).then((resp: AxiosResponse) => resp.data)
  }
}

export default SupplierApi
