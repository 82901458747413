import API, { Payload } from '@/shared/billie/api'
import { IRequest, IPeople } from '@/shared/components/interfaces/request.interface'

export interface RequestPayload extends Payload {
  id: string
  title: string
  description: string
  location: string
  attachments: string[]
  people: IPeople | null
  timeframe: ITimeframe
  budget: IBudget
  frequency: IFrequency | null
}

export interface IRequestEvent {
  eventType: string
  from: {
    type: string
    value: number
  }
  id: string
  modelId: string
  property: string
  timestamp: string
  to: {
    type: string
    value: number
  }
  user: {
    firstName: string
    id: string
    lastName: string
  }
}

export interface IBudget {
  type: 'total' | 'perPerson' | 'perHour' | 'perWeek' | 'perMonth'
  priceFrom: number | undefined
  priceTo: number | undefined
}

export interface ITimeframe {
  type: 'between' | 'asap' | 'before' | 'after' | 'date'
  from?: string
  to?: string
  date?: string
  time?: string
}

export interface IFrequency {
  type: 'once' | 'everyDay' | 'outOfStock' | 'other' | 'perWeek' | 'perMonth' | 'perQuarter' | 'perYear' | null
  value?: string | number | null
  days?: ('mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun')[] | null
}

export default class ServiceRequests extends API {
  public async get(clientId: string, requestId: string): Promise<IRequest> {
    return this._get(`/api/clients/${clientId}/service-requests/${requestId}`)
  }

  public async listByClient(clientId: string): Promise<IRequest[]> {
    return this._get(`/api/clients/${clientId}/service-requests`)
  }

  public async create(clientId: string, payload: any): Promise<IRequest> {
    if (payload.location === '') payload.location = null
    return this._post(`/api/clients/${clientId}/service-requests`, payload)
  }

  public async update(clientId: string, requestId: string, payload: any): Promise<IRequest> {
    if (payload.location === '') payload.location = null
    return this._put(`/api/clients/${clientId}/service-requests/${requestId}`, payload)
  }

  public async close(clientId: string, requestId: string): Promise<any> {
    return this._post(`/api/clients/${clientId}/service-requests/${requestId}/close`)
  }

  public async events(clientId: string, requestId: string): Promise<IRequestEvent[]> {
    return this._get(`/api/clients/${clientId}/service-requests/${requestId}/events`)
  }
}
