













































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IAgreementElement } from '@/shared/components/interfaces/agreements.interface'
import { RRule } from 'rrule'
import NumberHelper from '@/shared/helpers/numberHelper'

@Component({
  components: {},
})
export default class AgreementProductItemV2 extends Vue {
  @Prop({ required: true }) revisionElement: IAgreementElement
  @Prop({ required: true }) currency: string
  @Prop({ default: false }) isAdhoc: boolean
  @Prop({ default: false }) showHeader: boolean

  get clientPrice() {
    return Number(this.revisionElement.clientPrice)
  }

  get supplierPrice() {
    return Number(this.revisionElement.supplierPrice)
  }

  get clientTotalPrice() {
    const num = this.clientPrice * this.quantity
    return NumberHelper.makeFloat(num)
  }

  get supplierTotalPrice() {
    const num = this.supplierPrice * this.quantity
    return NumberHelper.makeFloat(num)
  }

  get frequency() {
    if (!this.revisionElement.schedule) return ''
    let rule = RRule.fromString(this.revisionElement.schedule)
    const ruleText = rule.toText()
    return ruleText ? (ruleText[0]?.toUpperCase() ?? '') + ruleText.slice(1) : 'N/A'
  }

  getPricing(pricing: string | undefined) {
    switch (pricing) {
      case 'per_delivery':
        return 'Per delivery'
      case 'per_month':
        return 'Per month'
      default:
        return 'N/A'
    }
  }

  get quantity() {
    return this.revisionElement.quantity!
  }
}
