import Vue from 'vue'
import App from './App.vue'
import './filters/dateFilter'
import './filters/capitalizeAndClearFilter'
import axios from './shared/services/api/interceptor'
import BootstrapVue from 'bootstrap-vue'
import Popper from 'popper.js'
import VueMeta from 'vue-meta'
import globals from './globals'
import router from './router'
import store from './store/store'
import VueI18n from 'vue-i18n'
import messages from './i18n/i18n'
import Cookies from 'js-cookie'
// @ts-ignore
import Clipboard from 'v-clipboard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue2Filters from 'vue2-filters'
//@ts-ignore
import browserDetect from 'vue-browser-detect-plugin'
import Toasted from 'vue-toasted'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import jwt from 'jsonwebtoken'
import runtime from 'serviceworker-webpack-plugin/lib/runtime'
import Billie from '@/shared/billie'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers!.computeStyle!.gpuAcceleration = false
Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
import './validation.js'

Vue.use(BootstrapVue)
Vue.use(Vue2Filters, {
  currency: {
    symbol: '',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
  },
})
Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.use(Clipboard)
Vue.use(browserDetect)
Vue.use(Toasted, {
  iconPack: 'custom-class',
  theme: 'outline',
  duration: 5000,
})

if (process.env.NODE_ENV === 'development') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    release: process.env.GIT_COMMIT_ID,
    integrations: [new Integrations.BrowserTracing()],
    attachProps: true,
    logErrors: true,
    tracesSampleRate: 1,
  })
}
Vue.prototype.$baseSocketURL = process.env.VUE_APP_SOCKET_BASE_URL
Vue.prototype.$platformURL = process.env.VUE_APP_PLATFORM_URL

const locale = Cookies.get('language')
if (locale === undefined) {
  //locale = languages.supported.includes(navigator.language) ? navigator.language : languages.default;
  Cookies.set('language', 'en')
}

const i18n = new VueI18n({
  locale: Vue.prototype.$locale,
  fallbackLocale: 'en',
  messages,
})
Vue.prototype.$locale = 'en'
Vue.prototype.$appName = 'back-office'
Vue.prototype.$token = localStorage.getItem(globals().accessTokenKey)
Vue.prototype.$logger = window.console

Vue.prototype.$axios = axios
Vue.prototype.$axios.defaults.baseURL = Vue.prototype.$baseURL = process.env.VUE_APP_BASE_URL
Vue.prototype.$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const token = Vue.prototype.$token
if (token) {
  Vue.prototype.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  // Cookies.set("feathers-jwt", window.localStorage.getItem("feathers-jwt"), {domain: '.goodmonday.io'});
  const decodedToken = jwt.decode(token) as { email: string; sub: string }
  Sentry.configureScope(function (scope) {
    scope.setUser({ email: decodedToken!.email, id: decodedToken!.sub })
  })
}

Vue.prototype.$billie = new Billie(Vue.prototype.$axios)

if ('serviceWorker' in navigator) {
  runtime.register()
}

Vue.config.errorHandler = function (err) {
  Vue.toasted.error(err.message, { icon: 'ion ion-ios-warning' })
}

Vue.mixin({
  data: globals,
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')