import Vue from 'vue'
export default Vue.directive('file-drop', {
  inserted: function(el, binding) {
    window.addEventListener('dragenter', el.onDragEnter)
    window.addEventListener('dragleave', el.onDragLeave)
    window.addEventListener('dragover', el.onDragOver)
    window.addEventListener('drop', e => {
      binding.value(false)
      e.preventDefault()
    })
  },
  bind: function(el, binding) {
    el.onDragEnter = e => {
      el.lastTarget = e.target
      binding.value(true)
    }
    el.onDragLeave = e => {
      if (e.target === el.lastTarget) {
        binding.value(false)
      }
    }
    el.onDragOver = e => {
      e.preventDefault()
    }
  }
})
