import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import jwt from 'jsonwebtoken'
import Cookies from 'js-cookie'
import usersApi from './../services/api/usersApi'
import axios from 'axios'
import globals from '../../globals'
import authApi from './../services/api/authApi'
import store from './../../store/store'
import { IClientAccount } from '../../shared/components/interfaces/clients.interface'
import { IClient } from '../components/interfaces/agreements.interface'

export interface IUser {
  id: string
  name?: string
  position: string | null
  email: string
  roles?: any[]
  googleId?: string
  client?: IClient
  clientId?: string
  clientName?: string
  firstName: string
  lastName: string
  mobile: string
  language: string
  avatar?: string
  termsAcceptedAt?: string
  authenticatedAt?: string
  createdAt?: string
  updatedAt?: string
  invitedAt?: string
  lastEmailSent?: string | null
  serviceArea?: string
  shouldConsent?: string
  lastActivityAt?: string
}

@Module({
  name: 'Auth',
  dynamic: true,
  store: store,
})
export default class Auth extends VuexModule {
  emptyUser = {
    id: '',
    name: '',
    position: '',
    email: '',
    roles: [],
    googleId: '',
    clientId: '',
    clientName: '',
    firstName: '',
    lastName: '',
    mobile: '',
    language: '',
    avatar: '',
    termsAcceptedAt: '',
    authenticatedAt: '',
    createdAt: '',
    updatedAt: '',
    serviceArea: '',
    shouldConsent: '',
  }
  token = window.localStorage.getItem(globals().accessTokenKey) || ''
  userData: IUser = { ...this.emptyUser }

  //   constructor(prop: any) {
  //     super(prop)
  //     const isInTest = typeof global.it === 'function'
  //     if (!isInTest) {
  //       this.token = localStorage.getItem(globals().accessTokenKey) || ''
  //     }
  //   }

  get user() {
    return this.userData
  }

  get id() {
    return this.userData.id
  }

  get name() {
    return this.userData.firstName
  }

  get accessToken() {
    return this.token
  }

  set accessToken(val: string) {
    this.token = val
  }

  get hasToken() {
    return !!this.token.length
  }

  get isSupplier() {
    if (!this.token) return false
    const decodedToken = (jwt as any).decode(this.token)
    return decodedToken && decodedToken.roles && decodedToken.roles.includes('supplier')
  }
  get isSuperadmin() {
    if (!this.token) return false
    const decodedToken = (jwt as any).decode(this.token)
    return decodedToken && decodedToken.roles && decodedToken.roles.includes('superadmin')
  }

  get clientId() {
    if (!this.token) return false
    const decodedToken = (jwt as any).decode(this.token)
    return decodedToken && decodedToken.gm_client
  }

  get isCustomer() {
    return !!this.clientId
  }
  get hasUser() {
    return !!this.userData.id
  }

  @Mutation
  public setToken(payload: string) {
    this.token = payload
  }

  @Mutation
  public setTermsAccepted() {
    this.userData.shouldConsent = 'false'
  }

  @Mutation
  public setArea() {
    this.userData.serviceArea = (jwt as any).decode(this.token).service_area
  }

  @Mutation
  public unsetToken() {
    this.token = ''
  }

  @Mutation
  public setUser(payload: IUser) {
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        // @ts-ignore

        this.userData[key] = (payload as any)[key]
      }
      if (payload.language) {
        Cookies.set('language', payload.language)
      }
    }
    this.userData.serviceArea = (jwt as any).decode(this.token).service_area
  }

  @Mutation
  public unsetUser() {
    this.userData = { ...this.emptyUser }
  }

  @Action
  public async logout() {
    this.context.commit('unsetToken')
    this.context.commit('unsetUser')
    localStorage.clear()
    axios.defaults.headers.common = {}
  }

  @Action({ commit: 'setToken' })
  public async authenticate({ email, password }: { email: string; password: string; strategy: string }) {
    return authApi
      .authenticate(email, password)
      .then(async (data: { token: string; file_token: string }) => {
        localStorage.setItem(globals().accessTokenKey, data.token)
        localStorage.setItem(globals().fileTokenKey, data.file_token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token

        // Start of Platform V4 bridge authentication
        const tokenContent = (jwt as any).decode(data.token)
        const user = await usersApi.getUser(tokenContent.sub)

        const v4Playload = {
          tokens: {
            token: data.token,
            fileToken: data.file_token,
            refreshToken: ''
          },
          user: {
            id: user.id,
            clientId: user.clientId
          }
        }

        localStorage.setItem('authentication', JSON.stringify(v4Playload))
        // End of V4 bridge
      
        return data.token
      })
      .catch((_err) => '')
  }

  @Action({ commit: 'setToken' })
  public async tokenAuthenticate(data: { token: string; fileToken: string }) {
    localStorage.setItem(globals().accessTokenKey, data.token)
    localStorage.setItem(globals().fileTokenKey, data.fileToken)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token

    // Start of Platform V4 bridge authentication
    const tokenContent = (jwt as any).decode(data.token)
    const user = await usersApi.getUser(tokenContent.sub)
    
    const v4Playload = {
      tokens: {
        token: data.token,
        fileToken: data.fileToken,
        refreshToken: ''
      },
      user: {
        id: user.id,
        clientId: user.clientId
      }
    }

    localStorage.setItem('authentication', JSON.stringify(v4Playload))
    // End of V4 bridge

    return data.token
  }

  @Action({ commit: 'setUser' })
  public async getUser(): Promise<IUser | IClientAccount> {
    const token = (jwt as any).decode(this.context.getters['accessToken'])
    return usersApi
      .getUser(token.sub)
      .then((data: IUser | IClientAccount) => {
        return Object.assign({}, data, { roles: [token.gm_role], clientId: token.gm_client })
      })
      .catch((_err) => ({}))
  }
}
