












































































import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'
import moment from 'moment'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import TasksApi, { ICareUser } from '@/shared/services/api/tasksApi'
import Country from '@/shared_legacy/storeModules/country'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import AgreementsApi from '@/shared/services/api/agreementsApi'
import StatusBadgeHelper from '@/shared_legacy/helpers/statusBadgeHelper'
import NotReadyForInvoiceModal from '../components/agreement/schedule/NotReadyModal.vue'
import NumberHelper from '@/shared/helpers/numberHelper'
const agreementState = getModule(AgreementsV2)
const countryState = getModule(Country)

interface IOrder {
  createdAt: string
  createdBy: string
  service: {
    title: string
    category: string
  }
  client: string
  supplier: string
  status?: string
  id: string
  clientId: string
  currency: string
  clientTotalPrice: number
  supplierTotalPrice: number
}
@Component({ components: { NotReadyForInvoiceModal } })
export default class Orders extends Vue {
  agreementState = agreementState
  countryState = countryState
  careUsers: ICareUser[] = []
  filterBy = ''
  fetchingOrders = true
  errorFetchingOrders = false
  modalClientId = ''
  errorMessage = ''
  showModal = false
  agreements: IAgreementV2[] | null = null
  defaultFields = [
    { key: 'client', sortable: true },
    { key: 'supplier', sortable: true },
    { key: 'service', sortable: true },
    { key: 'createdBy', sortable: true },
    { key: 'createdAt', sortable: true }
  ]

  usersGroupedByCountry: { country: string; users: ICareUser[] }[] = [
    { country: 'DK', users: [] },
    { country: 'UK', users: [] }
  ]
  agreementsWithInvoiceStatus: { [key: string]: boolean } | null = null

  get fields() {
    this.defaultFields.push({ key: 'clientTotalPrice', sortable: true })
    this.defaultFields.push({ key: 'supplierTotalPrice', sortable: true })
    this.defaultFields.push({ key: 'invoices', sortable: true })
    this.defaultFields.splice(this.defaultFields.length - 1, 0, { key: 'status', sortable: true })
    this.defaultFields.push({ key: 'readyForInvoicing', sortable: true })
    return this.defaultFields
  }

  get selectedCountry() {
    return this.countryState.selectedCountry
  }

  get allCategories() {
    return this.agreementState.allCategories
  }

  get allCategoriesById() {
    let categoriesMap: { [key: string]: string } = {}
    for (let category of this.allCategories) {
      categoriesMap[category.slug] = category.title
    }
    return categoriesMap
  }

  get careUsersById() {
    let usersById: { [key: string]: ICareUser } = {}
    for (let user of this.careUsers) {
      usersById[user.id] = user
    }
    return usersById
  }

  get orders() {
    return this.agreements!.map(order => {
      let date = new Date(order.revisions[0].createdAt)
      let orderRow: IOrder = {
        createdAt: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
        createdBy: this.careUsersById[order.revisions[0].insertedBy]
          ? this.careUsersById[order.revisions[0].insertedBy].name
          : 'N/A',
        service: {
          title: order.revisions[0].title,
          category: this.allCategoriesById[order.revisions[0].category]
        },
        client: order.client.name!,
        supplier: order.supplier.name!,
        id: order.id,
        clientId: order.client.id,
        currency: order.currency,
        clientTotalPrice: this.getInvoiceAmount(order, 'clientPrice'),
        supplierTotalPrice: this.getInvoiceAmount(order, 'supplierPrice')
      }
      orderRow.status = order.status
      return orderRow
    })
  }

  getBadgeColor(status: string) {
    return StatusBadgeHelper.getStatusBadgeColor(status)
  }

  getInvoiceAmount(order: IAgreementV2, key: 'clientPrice' | 'supplierPrice') {
    let ppSum =
      order.revisions[0].prepaymentPatterns!.length !== 0
        ? order.revisions[0].prepaymentPatterns!.reduce((acc, value) => acc + value[key]! * value.quantity!, 0)
        : 0
    let invoiceSum =
      order.amendments!.length !== 0
        ? order.amendments!.reduce((acc, value) => acc + value[key]! * value.quantity!, 0)
        : 0
    return NumberHelper.makeFloat(ppSum + invoiceSum)
  }

  get filteredOrders() {
    const filteredBySupplierServiceOrClient = this.filterByName(this.orders)
    const filteredByCountry = this.filterByCountry(filteredBySupplierServiceOrClient)
    const filteredByDate = this.filteredOrdersByDate(filteredByCountry)
    return filteredByDate
  }

  getInvoiceStatus(isMarked: boolean) {
    return isMarked ? 'Ready for invoicing' : 'Not ready'
  }

  async markOrUnmarkForInvoicing(rowItem: IAgreementV2, markedReady: boolean) {
    try {
      if (markedReady) {
        await AgreementsApi.unmarkAdhocAsReady(rowItem.id)
        this.agreementsWithInvoiceStatus![rowItem.id] = !markedReady
        this.$toasted.success('Unmarked as ready for Invoicing')
      } else {
        await AgreementsApi.markAdhocAsReady(rowItem.id)
        this.agreementsWithInvoiceStatus![rowItem.id] = !markedReady
        this.$toasted.success('Marked as ready for Invoicing')
      }
    } catch (e) {
      this.modalClientId = rowItem.client.id
      this.errorMessage = (e as Error).message
      this.showModal = true
    }
  }

  filterByName(orders: IOrder[]) {
    let filteredByName = []
    window.localStorage.setItem('filterByOrders', this.filterBy)
    if (this.filterBy === '') {
      return this.filteredOrdersByDate(orders)
    }
    for (const order of orders) {
      if (order.client.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(order)
      } else if (order.service.title.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(order)
      } else if (order.supplier.toLowerCase().includes(this.filterBy.toLowerCase())) {
        filteredByName.push(order)
      }
    }
    return filteredByName
  }

  filterByCountry(orders: IOrder[]) {
    let filteredByCountry = []
    if (!this.selectedCountry) return orders
    for (const order of orders) {
      if (order.currency === 'DKK' && this.selectedCountry === 'DK') filteredByCountry.push(order)
      if (order.currency === 'GBP' && this.selectedCountry === 'UK') filteredByCountry.push(order)
    }
    return filteredByCountry
  }

  filteredOrdersByDate(orders: IOrder[]) {
    orders.sort((a, b) => {
      return moment(b.createdAt, 'DD/MM/YYYY').unix() - moment(a.createdAt, 'DD/MM/YYYY').unix()
    })
    return orders
  }
  compareDate(itemA: IOrder, itemB: IOrder, key: string) {
    if (key !== 'createdAt') {
      return false
    } else {
      let a = moment(itemA[key], 'DD/MM/YYYY').unix()
      let b = moment(itemB[key], 'DD/MM/YYYY').unix()
      return a - b
    }
  }

  goToOrder(order: IOrder) {
    this.$router.push(`clients/${order.clientId}/agreements/${order.id}`)
  }

  async mounted() {
    try {
      await this.agreementState.getCategories(this.countryState.selectedCountry === 'UK' ? 'LON' : 'CPH')
      this.fetchingOrders = false
    } catch (e) {
      this.fetchingOrders = false
      this.errorFetchingOrders = true
    }
    await AgreementsApi.getAgreementsV2NotInvoiced().then(agreements => {
      this.agreements = StatusBadgeHelper.mapAgreementsWithStatus(agreements)
    })
    if (this.agreements) {
      const newObj: { [key: string]: boolean } = {}
      for (const agreement of this.agreements) {
        newObj[agreement.id] = await AgreementsApi.getAgreementDeliveryStatus(agreement.id).then(a => {
          return a.markedReady
        })
      }
      this.agreementsWithInvoiceStatus = newObj
    }
  }

  async created() {
    if (window.localStorage.getItem('filterByOrders') !== null) {
      //@ts-ignore
      this.filterBy = window.localStorage.getItem('filterByOrders')
    }
    this.careUsers = await TasksApi.getCareUsers()
  }
}
