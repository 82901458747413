import { AxiosInstance, AxiosResponse } from 'axios'

export type Payload = Record<string, unknown>

export default abstract class API {
  protected http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  protected _delete(url: string): Promise<any> {
    return this.http.delete(url).then((res: AxiosResponse) => res.data)
  }

  protected _get(url: string): Promise<any> {
    return this.http.get(url).then((res: AxiosResponse) => res.data)
  }

  protected _patch(url: string, payload?: Payload): Promise<any> {
    return this.http.patch(url, payload).then((res: AxiosResponse) => res.data)
  }

  protected _post(url: string, payload?: Payload): Promise<any> {
    return this.http.post(url, payload).then((res: AxiosResponse) => res.data)
  }

  protected _put(url: string, payload?: Payload): Promise<any> {
    return this.http.put(url, payload).then((res: AxiosResponse) => res.data)
  }
}
