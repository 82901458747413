import ShortcutStore from '@/store/modules/shortcutStore'
import { getModule } from 'vuex-module-decorators'
const shortcutState = getModule(ShortcutStore)
class ShortcutsHelperHelper {
  public activeItem: string | null = null
  public prevKeyDown: any
  public items: any[]
  public clickHandler?: (e: KeyboardEvent) => void
  constructor(items: any[]) {
    shortcutState.setTableItems({ items: items })
    this.clickHandler = this.keydownEventListener.bind(this)
  }

  public keydownEventListener(e: KeyboardEvent) {
    if (this.prevKeyDown) {
      this.prevKeyDown(e)
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      this.activateNext()
    } else if (e.key === 'ArrowUp') {
      this.activatePrevious()
      e.preventDefault()
    }
    if (e.key === 'Escape') {
      shortcutState.setActiveTableItem({ id: null })
      window.removeEventListener('keydown', this.clickHandler!)
      shortcutState.setEventRegistered({ registered: false })
    }
  }

  public activateItem(item: string) {
    shortcutState.setActiveTableItem({ id: item })
  }

  public focusTable(items: any[]) {
    shortcutState.setTableItems({ items: items })
    this.prevKeyDown = document.onkeydown
    shortcutState.setActiveTableItem({ id: shortcutState.tableItems[0]?.id })
    if (!shortcutState.eventRegistered) {
      shortcutState.setEventRegistered({ registered: true })
      window.addEventListener('keydown', this.clickHandler!)
    }
  }

  public activateNext() {
    const activeIndex = shortcutState.tableItems.findIndex((item) => {
      return shortcutState.activeTableItem === item.id
    })
    if (activeIndex + 1 === shortcutState.tableItems.length) {
      shortcutState.setActiveTableItem({ id: shortcutState.tableItems[0]?.id })
    } else {
      shortcutState.setActiveTableItem({ id: shortcutState.tableItems[activeIndex + 1].id })
    }
  }

  public activatePrevious() {
    const activeIndex = shortcutState.tableItems.findIndex((item) => {
      return shortcutState.activeTableItem === item.id
    })
    if (activeIndex === 0) {
      if (shortcutState.tableItems[shortcutState.tableItems.length - 1]) {
        shortcutState.setActiveTableItem({ id: shortcutState.tableItems[shortcutState.tableItems.length - 1].id })
      }
    } else {
      if (shortcutState.tableItems[activeIndex - 1]) {
        shortcutState.setActiveTableItem({ id: shortcutState.tableItems[activeIndex - 1].id })
      }
    }
  }
}

// Export a singleton instance in the global namespace
export default ShortcutsHelperHelper
