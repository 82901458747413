import API from '@/shared/billie/api'

export interface ICalendarEvent {
  description?: string
  duration: number
  editable: boolean
  ical: string
  id: string
  isRecurring: boolean
  link: string
  parentId?: any
  startTime: string
  title: string
  type: 'arrival' | 'schedule'
  untilTime: string
}

export default class Events extends API {
  public async list(clientId: string, link: string): Promise<any> {
    return this._get(`/api/v2/clients/${clientId}/calendar/events?link=${link}`)
  }
}
