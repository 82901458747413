














































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import heic2any from 'heic2any'
import RemindMeMenu from './RemindMeMenu.vue'
import PreviewImage from './PreviewImage.vue'
import ChatStore from '@/store/modules/chatStore'
import { getModule } from 'vuex-module-decorators'
const chatState = getModule(ChatStore)

@Component({
  components: { PreviewImage, RemindMeMenu }
})
export default class ChatBox extends Vue {
  @Prop() isSendingMessage: boolean
  @Prop() uploadInProgress: boolean
  @Prop() message: string
  @Prop() chatId: string

  @Prop() selectedDropdownValue: (timeout: number) => void
  @Prop() updateContainerOffset: () => void
  @Prop() sendMessage: (ignoreMessage: boolean, parentDiv: HTMLDivElement | null, file: File | null) => void
  enterClicked = false
  isImage = false
  previewUrl = ''
  focus = true
  file: File | null = null
  showRemindMe = false
  $refs: { file: HTMLInputElement; messageBox: HTMLTextAreaElement; remindme: Vue }

  mounted() {
    this.$refs.messageBox.focus()
  }

  @Watch('isSendingMessage')
  doneSendingMessage(value: boolean) {
    if (!value) {
      this.file = null
      this.$refs.file.value = ''
    }
  }

  onKeydown(e: KeyboardEvent) {
    switch (e.key) {
      case 'Enter':
        this.handleEnterClicked(e)
        break
      case 'Escape':
      default:
        if (!['ArrowDown', 'ArrowUp'].includes(e.key)) {
          this.clearToggleMenu(false)
        }
        break
    }
  }

  handleBackofficeSend() {
    if (this.message || this.file) {
      this.showRemindMe = true
    }
  }

  handleEnterClicked(e: KeyboardEvent) {
    if (e.shiftKey) {
      this.enterClicked = true
      if (this.message || this.file) {
        this.showRemindMe = true
        e.preventDefault()
      } else {
        e.preventDefault()
        this.selectedDropdownValue(30)
        this.handleMessageSend(false, (e.target as HTMLTextAreaElement).parentNode as HTMLDivElement)
      }
    }
  }

  handleMessageSend(ignoreMessage: boolean, parentNode: HTMLDivElement) {
    this.sendMessage(ignoreMessage, parentNode, this.file)
  }

  updateMessageboxSize(e: InputEvent) {
    const target = e.target as HTMLTextAreaElement
    const parent = target.parentNode as HTMLDivElement
    if (parent) {
      if (this.enterClicked) {
        e.preventDefault()
        return
      }
      parent.dataset.value = target.value
      this.$emit('onMessage', target.value)
      this.updateContainerOffset()
    }
  }

  clearToggleMenu(value: boolean) {
    this.showRemindMe = value
    this.enterClicked = false
  }

  createImgPreview() {
    if (!this.file) {
      return
    }
    const extension = this.file.name.split('.')[this.file.name.split('.').length - 1]
    this.isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic'].includes(extension.toLowerCase())
    if (this.isImage) {
      if (extension.toLowerCase() === 'heic') {
        heic2any({ blob: this.file as Blob }).then(conversionResult => {
          this.previewUrl = URL.createObjectURL(conversionResult)
        })
      } else this.previewUrl = URL.createObjectURL(this.file)
    }
  }

  handleFileUpload() {
    this.file = this.$refs.file.files![0]
    if (!this.file) return
    this.createImgPreview()
    this.$refs.messageBox.focus()
    this.updateContainerOffset()
  }

  addFiles() {
    if (!this.file) {
      this.$refs.file.click()
    }
  }

  removeFile() {
    this.file = null
    this.$refs.file.value = ''
    this.previewUrl = ''
    this.updateContainerOffset()
  }
  keydownListener(e: any) {
    if (e.key === 'Escape') {
      this.removeListenerForBlur()
      this.$refs?.messageBox?.blur?.()
    }
  }
  removeListenerForBlur() {
    chatState.setInputFocused(false)
    window.removeEventListener('keydown', this.keydownListener)
  }
  addEventListenerForFocus() {
    chatState.setInputFocused(true)
    this.$forceUpdate()
    window.addEventListener('keydown', this.keydownListener)
  }
}
