import Vue from 'vue'

Vue.filter('capitalizeAndClear', function (value) {
  if (!value) return ''
  return value
    .replace(/_|-/g, ' ')
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase()
    })
})
