




















import LayoutNavbar from '@/components/layout/LayoutNavbar.vue'
import LayoutSidenav from '@/components/layout/LayoutSidenav.vue'
import LayoutFooter from '@/components/layout/LayoutFooter.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import LayoutHelper from '@/components/layout/helpers'

@Component({
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter,
  },
})
export default class AppLayout2 extends Vue {
  layoutHelpers = LayoutHelper
  mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  }

  beforeDestroy() {
    this.layoutHelpers.destroy()
  }

  closeSidenav() {
    this.layoutHelpers.setCollapsed(true)
  }
}
