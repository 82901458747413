
















import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Country from '@/shared_legacy/storeModules/country'
const countryState = getModule(Country)
@Component
export default class NumberInput extends Vue {
  @Prop() value: string
  @Prop({ default: 100 }) multiplier: number
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: '' }) placeholder: string
  @Prop({ default: '^[-0-9]+([.])?([0-9]{1,2})?$' }) pattern: string
  @Prop({ default: 'sm' }) size: string
  // @Prop() callback: (val: number) => unknown
  valueToPreview = ''

  created() {
    this.valueToPreview = this.value + ''
  }

  @Watch('value')
  onValueChange(newValue: string, _prev: string) {
    this.valueToPreview = newValue + ''
  }

  get displayValue() {
    return this.valueToPreview
  }

  set displayValue(value: string) {
    if (this.pattern) {
      value = this.replaceNonPatternCharacters(value) + ''
      const re = new RegExp(this.pattern)
      if (value !== '' && !re.test(value)) return
    }
    this.valueToPreview = value
  }

  setVal(value: string) {
    const converted = Math.round(parseFloat(value) * 100) / 100
    this.$emit('input', converted)
    this.$emit('callback', converted)
  }

  shouldProcessAsDK(value: string) {
    return value.includes(',') && countryState.countrySelected === 'DK'
  }

  replaceNonPatternCharacters(value: string) {
    let newValue = value.replace(/[^0-9.-]/gi, '')
    let dupRegex = new RegExp('[.-]', 'g')
    let decimalSep = '.'
    if (this.shouldProcessAsDK(value)) {
      newValue = value.replace(/[^0-9,-]/gi, '')
      dupRegex = new RegExp('[,-]', 'g')
      decimalSep = ','
    }
    newValue = newValue.replace(dupRegex, (char: string, index: number, text: string) => {
      if (index === 0 && char === '-') return '-'
      const pos = text.indexOf(char)
      if (pos === index && char === decimalSep) return decimalSep
      return ''
    })
    const pos = newValue.indexOf(decimalSep)
    const textLength = pos === -1 ? 0 : newValue.slice(pos + 1).length
    if (newValue && textLength >= 2) {
      if (textLength > 2) {
        return this.valueToPreview
      }
      newValue = newValue.replace(',', '.')
      return parseFloat(newValue).toFixed(2)
    }
    return newValue
  }
}
