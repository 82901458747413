















import ImageModal from '@/components/ImagePreviewModal.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: { ImageModal }
})
export default class ImageThumbnail extends Vue {
  @Prop({ required: true }) src: string
  showModal = false
  $appName: string

  get fileToken() {
    if (this.$appName === 'back-office') return localStorage.getItem('backoffice_file_token')
    return localStorage.getItem('file_token')
  }
  openModal() {
    this.showModal = true
  }

  closeModal() {
    this.showModal = false
  }
}
