import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../../store/store'

@Module({
  name: 'AppKey',
  dynamic: true,
  store: store,
})
export default class AppKey extends VuexModule {
  initialKey = {
    value: 'app',
  }

  loginENV = ''

  key = { ...this.initialKey }

  get appKeyValue() {
    return this.key
  }

  get loginEnv() {
    return this.loginENV
  }

  @Mutation
  public setLoginENV(payload: string) {
    this.loginENV = payload
  }

  @Mutation
  public setNewKey(payload: string) {
    this.key.value = payload
  }

  @Action({ commit: 'setNewKey' })
  public updateKey() {
    return Math.random().toString()
  }

  @Action({ commit: 'setLoginENV' })
  public saveLoginEnv(url: string) {
    return url
  }
}
