





































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Suppliers from '@/shared_legacy/storeModules/suppliers'
import {
  IAgreementElementV2,
  IAgreementV2CreatePayload,
  ISupplier
} from '@/shared/components/interfaces/agreements.interface'
import DocumentWhoSection from './DocumentWhoSection.vue'
import DocumentWhatSection from './DocumentWhatSection.vue'
import DocumentWhenSection from './DocumentWhenSection.vue'
import RevisionElementMixin from '@/mixins/RevisionElementMixin'

const suppliersState = getModule(Suppliers)

@Component({
  components: { DocumentWhoSection, DocumentWhatSection, DocumentWhenSection },
  mixins: [RevisionElementMixin]
})
export default class CreateNewDocument extends Vue {
  @Prop({ required: true }) docType: 'subscription' | 'adhoc'
  @Prop() language: string
  @Prop() country: string
  @Prop() proposalId : string
  @Prop({ default: false }) isProposal: boolean

  suppliersState = suppliersState
  loadingSuppliers = true
  errorLoadingSuppliers = false
  invoiceable = true
  prepaid = true
  docTitle = ''
  supplierId = ''
  categoryId = ''
  description = ''
  attachments: string[] | null = null
  currency = ''
  startDate = ''
  prepaymentPatterns: IAgreementElementV2[] = []
  validateRevisionElementFrequency: () => { valid: boolean; errors: string[] }[]

  get suppliers() {
    return this.suppliersState.allSuppliers
  }

  get clientId() {
    return this.$route.params.id
  }

  get filteredSuppliers() {
    return this.suppliers
      .filter((supplier: ISupplier) => supplier!.address!.countryCode === this.country)
      .sort((a, b) => {
        const nameA = a.name?.toUpperCase() ?? ''
        const nameB = b.name?.toUpperCase() ?? ''
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
  }

  async created() {
    this.currency = this.getCurrencyFromCountry()
    if (!this.suppliers.length) {
      try {
        await this.suppliersState.getAllSuppliers()
        this.loadingSuppliers = false
      } catch (e) {
        this.loadingSuppliers = false
        this.errorLoadingSuppliers = true
      }
    } else {
      this.loadingSuppliers = false
    }
  }

  getCurrencyFromCountry() {
    if (this.country === 'UK') return 'GBP'
    else return 'DKK'
  }

  get invoiceSchedule() {
    return this.docType === 'subscription' ? 'monthly' : 'once'
  }

  selectSupplier(id: string) {
    this.supplierId = id
  }
  selectCategory(categoryId: string) {
    this.categoryId = categoryId
  }
  changeDescription(description: string) {
    this.description = description
  }

  updateStartDate(date: string) {
    this.startDate = date
  }

  updatePrepaymentPatterns(pattern: IAgreementElementV2[]) {
    this.prepaymentPatterns = pattern
  }

  addFile(file: string) {
    this.attachments ? this.attachments.push(file) : (this.attachments = [file])
  }

  removeFile(index: number) {
    if (this.attachments) this.attachments.splice(index, 1)
  }

  async save() {
    const validatedResults = await this.validateRevisionElementFrequency()
    if (validatedResults.every(value => value.valid)) {
      const agreementPayload = this.generatePayload()
      this.$emit('save', agreementPayload)
    } else {
      this.$toasted.error('Please fill in the required fields', { icon: 'ion ion-ios-warning' })
    }
  }

  generatePayload() {
    let payload: IAgreementV2CreatePayload = {
      id: this.proposalId,
      title: this.docTitle,
      clientId: this.clientId,
      supplierId: this.supplierId,
      description: this.description,
      attachments: this.attachments,
      currency: this.currency,
      invoiceable: this.invoiceable,
      prepaid: this.prepaid,
      invoiceSchedule: this.invoiceSchedule,
      category: this.categoryId,
      startDate: null,
      prepaymentPatterns: null
    }
    if (this.docType === 'subscription') {
      payload.startDate = this.startDate
    }
    if (this.prepaid) {
      payload.prepaymentPatterns = this.prepaymentPatterns
    }
    return payload
  }
}
