



















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ICalendarEventItemV2, ICalendarForm } from '../../../shared/components/interfaces/calendar.interface'
import CalendarEventItemV2Model from '../../../shared/models/CalendarEventItemV2Model'
import DocumentFrequency from '../../agreementAndProposal/element/DocumentFrequency.vue'

@Component({ components: { DatePicker, DocumentFrequency } })
export default class AddOrEditCalendarEvent extends Vue {
  @Prop({ required: true }) event: ICalendarEventItemV2 | null
  @Prop({ required: false, default: '' }) defaultTitle: string
  @Prop({ required: true, default: 'all' }) editType: 'all' | 'one' | 'following'

  formData: ICalendarForm | null = null
  validation = {
    isEndDateValid: true,
    isTitleValid: true
  }
  moment = moment
  allDayevent = false
  arrivalTime = false
  isEditEvent = false
  currentEditStartDate: Date | null = null
  datepickerConfig = {
    type: 'Date'
  }
  patterns = {
    schedule: `DTSTART;`
  }

  get mode() {
    return this.allDayevent ? 'date' : 'dateTime'
  }

  get isAllValid() {
    return Object.values(this.validation).every(item => item === true)
  }

  get dateWithoutSeconds(): Date {
    return new Date(new Date().setHours(9, 0, 0, 0))
  }

  @Watch('allDayevent')
  onAllDayeventChange(_new: boolean, oldVal: boolean) {
    if (this.isEditEvent && oldVal) {
      this.formData!.range.start = moment(this.formData!.range.start).hours(9).toDate()
      this.formData!.range.end = moment(this.formData!.range.end).hours(9).toDate()
      this.currentEditStartDate = this.formData!.range.start
    }
  }

  mounted() {
    if (this.event) {
      console.log('this.event', this.event)
      this.formData = {
        id: this.event.id,
        title: this.event.title,
        description: this.event.description!,
        type: this.event.type,
        range: {
          start: moment(this.event.startTime).toDate(),
          end: CalendarEventItemV2Model.isAllDay(this.event)
            ? CalendarEventItemV2Model.endTime(this.event).subtract(1, 'day').toDate()
            : CalendarEventItemV2Model.endTime(this.event).toDate()
        },
        repeatPattern: '' // TODO: fill
      }
      if (this.event.isRecurring) {
        if (this.editType !== 'one') {
          this.patterns.schedule = this.event.ical.split('RRULE')[1]
            ? 'RRULE' + this.event.ical.split('RRULE')[1].split('DTEND')[0]
            : 'DTSTART;'
          this.formData.repeatPattern = this.patterns.schedule
        }
      }
      this.currentEditStartDate = moment(this.event.startTime).toDate()
      if (CalendarEventItemV2Model.isAllDay(this.event)) {
        this.allDayevent = true
      }
      if (this.event.id) {
        this.isEditEvent = true
      }
    } else {
      this.formData = {
        id: null,
        title: this.defaultTitle,
        type: 'arrival',
        description: '',
        range: {
          start: this.dateWithoutSeconds,
          end: this.dateWithoutSeconds
        },
        repeatPattern: ''
      }
    }
    this.arrivalTime = this.formData.type === 'arrival'
    setTimeout(() => {
      //@ts-ignore
      this.$refs.titleInput.focus()
    }, 0)
  }

  setPattern(pattern: { rule: string; repeatPattern: string }) {
    this.patterns.schedule = pattern.rule
    this.formData!.repeatPattern = pattern.repeatPattern
  }

  clearForm() {
    this.formData = {
      id: null,
      title: this.defaultTitle,
      type: 'schedule',
      description: '',
      range: {
        start: this.dateWithoutSeconds,
        end: this.dateWithoutSeconds
      },
      repeatPattern: ''
    }
  }

  changeArrival() {
    this.formData!.type = this.arrivalTime ? 'arrival' : 'schedule'
  }

  deleteCalendarEvent() {
    this.$emit('deleteCalendarEvent', this.event)
  }

  addNewCalendarEvent() {
    if (this.allDayevent) {
      this.formData!.range.start = moment(this.formData!.range.start).startOf('day').toDate()
      this.formData!.range.end = moment(this.formData!.range.end).add(1, 'day').startOf('day').toDate()
    }
    if (this.allDayevent) {
      this.formData!.type = 'schedule'
    }
    this.$emit('addNewCalendarEvent', this.formData)
  }

  validateStartDateInput(date: Date) {
    if (moment(date).isAfter(this.formData!.range.end) && !this.isEditEvent) {
      this.formData!.range.end = this.formData!.range.start
    } else if (this.isEditEvent) {
      let differenceBetweenOldNew = moment(this.formData!.range.start).diff(this.currentEditStartDate, 'minutes')
      this.formData!.range.end = moment(this.formData!.range.end).add(differenceBetweenOldNew, 'm').toDate()
      this.currentEditStartDate = this.formData!.range.start
    }
  }

  validateEndDateInput(date: Date) {
    this.validation.isEndDateValid = moment(date).isSameOrAfter(this.formData!.range.start)
  }

  validateTitle() {
    this.validation.isTitleValid = this.formData!.title !== '' ? true : false
  }

  cancel() {
    this.clearForm()
    this.$emit('cancel')
  }
}
