import API from '@/shared/billie/api'
import { IAgreementRequest } from '@/shared/services/api.interface'

export default class Requests extends API {
  public async get(id: string): Promise<IAgreementRequest> {
    return this._get(`/api/requests/${id}`)
  }

  public async listByClient(clientId: string): Promise<IAgreementRequest[]> {
    return this._get(`/api/requests/client/${clientId}`)
  }

  public async delete(id: string): Promise<any> {
    return this._delete(`/api/requests/${id}`)
  }
}
