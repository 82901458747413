import Vue from 'vue'
import ShortcutStore from '@/store/modules/shortcutStore'
import { getModule } from 'vuex-module-decorators'
import uuid from 'uuid-random'

const shortcutState = getModule(ShortcutStore)
export default Vue.directive('shortcut', {
  bind: function (el, binding) {
    //@ts-ignore - adding custom property to keep track is not TS friendly
    el.shortcutId = uuid()
    const obj = {
      element: el,
      ...binding.value,
      originalKey: binding.value.trigger,
      //@ts-ignore - adding custom property to keep track is not TS friendly
      id: el.shortcutId,
      preserve: binding.value.preserve,
    }
    shortcutState.registerElement(obj)
  },

  unbind: function (el, _binding) {
    //@ts-ignore - adding custom property to keep track is not TS friendly
    shortcutState.unregisterElement(el.shortcutId)
  },
})
