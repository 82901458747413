









import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ErrorOnLoading extends Vue {
  @Prop({ default: 'An error occurred.' }) message: string
  @Prop({ default: true }) isAlert: boolean
}
