

































import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { IAgreementElement } from '@/shared/components/interfaces/agreements.interface'
import NumberInput from '../../agreement/schedule/inputs/NumberInput.vue'

@Component({ components: { NumberInput } })
export default class DocumentUnitPrice extends Vue {
  @Prop({ required: true }) value: number
  @Prop({ required: true }) model: keyof IAgreementElement
  @Prop({ required: true }) label: string
  @Prop() validationRef: string
  @Prop() rules: string
  @Prop() vid: string
  @Prop({ default: null }) dataTestId: string
  @Prop() inputRef: string
  @Prop() currency: string
  @Prop({ default: true }) showLabel: string
  showVal = 0
  created() {
    this.showVal = this.value
  }

  updateValue(value: any) {
    this.$emit('update', value, this.model)
  }
}
