




































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'
import ImageThumbnail from '@/shared_legacy/components/ImageThumbnail.vue'
import { IConversationMessage } from '@/shared/components/interfaces/messages.interface'
import LabelSmall from '@/shared_legacy/components/atoms/LabelSmall.vue'

@Component({
  components: { ImageThumbnail, LabelSmall }
})
export default class Message extends Vue {
  @Prop({ required: true }) message: IConversationMessage
  @Prop({ required: true }) messages: IConversationMessage[]
  $appName: string

  get fileToken() {
    if (this.$appName === 'back-office') return localStorage.getItem('backoffice_file_token')
    return localStorage.getItem('file_token')
  }

  get isBackOffice() {
    return this.$appName === 'back-office'
  }

  isAugmentedText(text: string | null) {
    if (text) {
      const urlRegex =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g

      let messageText = text
      const augmented = messageText.replace(urlRegex, url => {
        return `<a target='_blank' href="${url}">${url}</a>`
      })
      return augmented !== text ? augmented : ''
    } else return text
  }

  get msgPosition() {
    const key = 'clientId'

    if (this.isBackOffice && this.message.user[key] === null) {
      return 'right'
    } else if (!this.isBackOffice && this.message.user[key] !== null) {
      return 'right'
    } else {
      return 'left'
    }
  }

  time(time: string) {
    return moment(time).format('HH:mm')
  }
}
