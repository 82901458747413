


















































































import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Component from 'vue-class-component'
import client from '@/shared_legacy/storeModules/client'
import { IClientData } from '@/shared/components/interfaces/clients.interface'
import moment from 'moment'
import ClientsCompanyLocations from '../components/clients/ClientsCompanyLocations.vue'
import ClientsCompanyDetails from '../components/clients/ClientsCompanyDetails.vue'
import ClientsCompanyUsers from '../components/clients/ClientsCompanyUsers.vue'
import ClientsSubscriptions from '../components/clients/ClientsSubscriptions.vue'
import AgreementsApi from '../shared/services/api/agreementsApi'
import { IAgreementV2 } from '../shared/components/interfaces/agreements.interface'
import TasksApi, { ICareUser } from '../shared/services/api/tasksApi'
import ClientsUsers from '../components/clients/ClientsUsers.vue'
import { IUser } from '../shared/storeModules/auth'
import { IInvitee } from '../shared/components/interfaces/invitees.interface'
import ClientsInvoices from '../components/clients/ClientsInvoices.vue'
import InvoiceApi from '../shared/services/api/invoiceApi'
import { IInvoice } from '../shared/components/interfaces/invoices.interface'
import uuid from 'uuid-random'
import Auth from '@/shared/storeModules/auth'
import ChatWithDeadlineV2 from '../components/chat/ChatWithDeadlineV2.vue'
import Tickets from '@/shared_legacy/storeModules/tickets'
import ClientDetailsHeader from '../components/clients/ClientDetailsHeader.vue'
import ClientRequests from '../components/clients/ClientRequests.vue'

const clientState = getModule(client)
const userState = getModule(Auth)
const ticketsState = getModule(Tickets)

@Component({
  components: {
    ChatWithDeadlineV2,
    ClientsCompanyLocations,
    ClientsCompanyDetails,
    ClientsSubscriptions,
    ClientsCompanyUsers,
    ClientsUsers,
    ClientsInvoices,
    ClientDetailsHeader,
    ClientRequests
  }
})
export default class ClientDetailsPage extends Vue {
  clientState = clientState
  metaInfo = {
    title: 'Client | Good Monday'
  }
  clientInvitees: IInvitee[] = []
  clientDetails: IClientData = {} as IClientData
  loadingClientDetails = true
  loadingUsers = true
  sortBy = 'date_created'
  sortDesc = false
  perPage = 10
  currentPage = 1
  agreements: IAgreementV2[] = []
  users: IUser[] = []
  loadingAgreements = true
  displayInfo = false
  isImpersonating = false
  invoices: IInvoice[] = []
  loadingInvoices = true
  chatKey = uuid()
  proposalsKey = uuid()
  errorLoadingAgreement = false
  showClientDetailsModal = false
  ticketsState = ticketsState
  userState = userState
  proposalKey = '1'
  language = 'en-UK'
  careUsers: ICareUser[] = []

  @Watch('$route.params.id')
  onRouteParamChange() {
    this.agreements = []
    this.loadingAgreements = false
    this.loadingInvoices = false
    this.loadingUsers = false
    this.loadingClientDetails = false

    this.loadPage().then(() => {
      this.chatKey = uuid()
      this.proposalsKey = uuid()
    })
  }

  get tasks() {
    return this.ticketsState.ticketsById(this.$route.params.id)
  }

  get subscriptions() {
    return this.agreements.filter(a => a.invoiceSchedule === 'monthly')
  }

  get oneTimePurchases() {
    return this.agreements.filter(a => a.invoiceSchedule === 'once')
  }
  updateClientDetails(res: IClientData) {
    this.clientDetails = res
  }
  changeDisplayInfo(val: boolean) {
    this.displayInfo = val
  }

  toggleClientDetailsModal() {
    this.showClientDetailsModal = !this.showClientDetailsModal
  }

  created() {
    this.loadPage()
    this.fetchCustomerCareUsers()
    this.$root.$on('reloadClient', this.reloadClient)
  }

  reloadClient() {
    this.clientState.getClient(this.$route.params.id).then(data => {
      this.language = data.invoiceLanguage
      this.clientDetails = data
      this.loadingClientDetails = false
    })
  }

  async fetchCustomerCareUsers() {
    this.careUsers = await TasksApi.getCareUsers()
  }

  getTimeSince(date: string) {
    return moment(date).fromNow()
  }

  async loadPage() {
    if (this.$route.query && this.$route.query.clientDetails) {
      this.showClientDetailsModal = true
      setTimeout(() => {
        this.$router.push(this.$route.path)
      }, 1)
    }
    return Promise.all([
      this.clientState.getClient(this.$route.params.id).then(data => {
        this.language = data.invoiceLanguage
        this.clientDetails = data
        this.loadingClientDetails = false
      }),
      AgreementsApi.getAgreementsV2ByClient(this.$route.params.id).then((data: IAgreementV2[] | any) => {
        if (data.error) this.errorLoadingAgreement = true
        this.agreements = data
        this.loadingAgreements = false
      }),

      InvoiceApi.getInvoices(this.$route.params.id, true).then(data => {
        this.invoices = data
        this.loadingInvoices = false
      }),

      this.$billie.users.listByClient(this.$route.params.id).then(data => {
        this.users = data
        this.loadingUsers = false
      }),
      this.$billie.invitees.list().then(data => {
        this.clientInvitees = data.filter(invitee => {
          return invitee.clientId === this.$route.params.id
        })
      })
    ])
  }
}
