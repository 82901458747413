import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store/store'
import {
  IAgreement,
  IAgreementRevision,
  ISupplier,
  IAgreementV2,
} from '@/shared/components/interfaces/agreements.interface'
import AgreementsApi from '@/shared/services/api/agreementsApi'

@Module({
  name: 'Agreements',
  dynamic: true,
  store: store,
})
export default class Agreements extends VuexModule {
  initialAgreement: IAgreement = {
    id: '',
    type: '',
    title: '',
    agreementEnd: '',
    agreementStart: '',
    currency: '',
    client: {
      id: '',
      name: '',
      phone: null,
      status: 'active',
    },
    supplier: {
      id: '',
      name: '',
      phone: null,
    } as ISupplier,
    revisions: [
      {
        id: '',
        startDate: '',
        createdAt: '',
        description: '',
        timelinePlacement: '',
        type: '',
        title: '',
        elements: [
          {
            description: '',
            productId: '',
            pricing: '',
            clientPrice: 0,
            supplierPrice: 0,
            recurrence: '',
          },
        ],
      },
    ],
  }
  agreement: IAgreement = { ...this.initialAgreement }
  agreementV2: IAgreementV2 | null = null

  get agreementData() {
    return this.agreement
  }

  get agreementV2Data() {
    return this.agreementV2
  }

  get firstRevision() {
    return this.agreement.revisions[this.agreement.revisions.length - 1]
  }
  get currentRevision() {
    return this.agreement.revisions.find((revision) => {
      return revision.timelinePlacement === 'current' && revision.type !== 'termination'
    })
  }
  get nextRevision() {
    return this.agreement.revisions.find((revision) => {
      return revision.timelinePlacement === 'future' && revision.type !== 'termination'
    })
  }
  get nextRevisions() {
    return this.agreement.revisions.filter((revision) => {
      return revision.timelinePlacement === 'future' && revision.type !== 'termination'
    })
  }
  get lastRevision() {
    return this.agreement.revisions[0]
  }
  get pastRevisions() {
    return this.agreement.revisions.filter((revision) => {
      return revision.timelinePlacement === 'past' && revision.type !== 'termination'
    })
  }

  @Mutation
  public setAgreement(payload: IAgreement) {
    this.agreement = { ...this.agreement, ...JSON.parse(JSON.stringify(payload)) }
  }

  @Mutation
  public setAgreementV2(payload: IAgreementV2) {
    this.agreementV2 = JSON.parse(JSON.stringify(payload))
  }
  @Mutation
  public addRevision(payload: IAgreement) {
    this.agreement.revisions = [...this.agreement.revisions, payload.revisions] as IAgreementRevision[]
  }
  @Mutation
  public resetAgreement() {
    this.agreement = { ...this.initialAgreement }
  }

  @Action({ commit: 'setAgreement' })
  public async getAgreement(payload: { agreementId: string }) {
    return AgreementsApi.getAgreementById(payload.agreementId).then((res) => res)
  }

  @Action({ commit: 'setAgreementV2' })
  public async getAgreementV2(payload: { agreementId: string }) {
    return AgreementsApi.getAgreementByIdV2(payload.agreementId).then((res) => res)
  }

  @Action({ commit: 'setAgreement' })
  public async updateAgreementTitle(payload: { id: string; title: string }) {
    return AgreementsApi.updateAgreementTitle(payload.id, payload.title).then((data) => data)
  }

  @Action({ commit: 'addRevision' })
  public async createRevision(payload: { agreementId: string; revision: IAgreementRevision }) {
    return AgreementsApi.createRevision(payload.agreementId, payload.revision).then((res) => res)
  }

  @Action({ commit: 'setAgreement' })
  public async createAgreement(payload: { agreementId: string; agreement: IAgreement }) {
    return AgreementsApi.createAgreement(payload.agreementId, payload.agreement).then((res) => res)
  }

  @Action({ commit: 'setAgreement' })
  public async assignUser(payload: { userId: string; userName: string; userLastName: string }) {
    return AgreementsApi.setAgreementOwnership(this.agreementData.id, payload.userId).then(() => {
      if (payload.userId) {
        return { owner: { id: payload.userId, name: `${payload.userName} ${payload.userLastName}` } }
      } else {
        return { owner: { id: null, name: '' } }
      }
    })
  }
}
