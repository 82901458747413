import API from '@/shared/billie/api'

export interface LoginResponse {
    token: string
    file_token: string
    refresh_token: string
}

export default class Auth extends API {
    public async login(email: string, password: string): Promise<LoginResponse> {
        return this._post(`/api/login`, { email, password })
    }
}