




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class CircularProgressBar extends Vue {
  @Prop({ default: true }) active: boolean
  @Prop({ default: false }) indeterminate: boolean
  @Prop({ default: '25' }) radius: string
  @Prop({ default: '#fff' }) color: string
  @Prop({ default: '2' }) width: string

  get formattedRadius() {
    return `${parseInt(this.radius) * 2}px`
  }

  get viewBox() {
    const minXY = `${parseInt(this.radius)} ${parseInt(this.radius)}`
    const widthHeight = `${parseInt(this.radius) * 2} ${parseInt(this.radius) * 2}`
    return `${minXY} ${widthHeight}`
  }
}
