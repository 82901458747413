




















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import AgreementRevision from './AgreementRevision.vue'
import { IAgreementRevisionV2, IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import AgreementAddNewRevision from './schedule/AgreementAddNewRevision.vue'
import ShowMoreRevision from './ShowMoreRevision.vue'
import moment from 'moment'
import { IProposalV2 } from '@/shared/services/api.interface'
import uuid from 'uuid-random'
import { getModule } from 'vuex-module-decorators'
import AgreementsV2 from '@/shared_legacy/storeModules/agreementsV2'
import AgreementAttachments from './AgreementAttachments.vue'
import AgreementRevisionBody from './AgreementRevisionBody.vue'
import AgreementsApi from '../../shared/services/api/agreementsApi'
import RevisionEditsModal from './RevisionEditsModal.vue'

const agreementState = getModule(AgreementsV2)

@Component({
  components: {
    AgreementRevision,
    AgreementAddNewRevision,
    ShowMoreRevision,
    AgreementAttachments,
    AgreementRevisionBody,
    RevisionEditsModal
  }
})
export default class SubscriptionAgreement extends Vue {
  moment = moment
  @Prop({ required: true }) revisions: IAgreementRevisionV2[]
  @Prop({ required: true }) currency: string
  @Prop({ required: true }) activeRevisionId: string
  @Prop({ required: true }) agreement: IAgreementV2 | IProposalV2
  @Prop({ default: false }) isProposal: boolean
  @Prop({ required: true }) prepaid: boolean
  @Prop({ required: true }) language: string
  @Prop({ required: false }) showNewRevisionTab: boolean
  @Prop({ required: true }) setActiveRevision: (revision: IAgreementRevisionV2) => void
  revisionKey = uuid()
  agreementRevisionEdits: IAgreementRevisionV2[] = []
  currentRevisionIndex: number
  activeTab = -1
  revisionLimit = 4
  agreementState = agreementState
  errorLoading = false
  newRevisionsTab = -1
  showRevisionEditsModal = false

  get agreementRevisions() {
    return this.alteredRevisions.slice(0, this.revisionLimit)
  }

  get moreAgreementRevisions() {
    return this.alteredRevisions.slice(this.revisionLimit)
  }

  handleTabChange(currentTabs: Vue[]) {
    if (this.showNewRevisionTab) this.activeTab = currentTabs.length - 1
  }

  get revisionHasStarted() {
    if (this.isProposal) {
      return moment().isSameOrAfter(moment((this.agreement as IProposalV2).startDate))
    }
    const agreementStart = moment((this.agreement as IAgreementV2).agreementStart)
    const agreementEnd = (this.agreement as IAgreementV2).agreementEnd ? moment((this.agreement as IAgreementV2).agreementEnd!) : null
    if (agreementEnd) return moment().isBetween(agreementStart, agreementEnd, 'days')
    return moment().isSameOrAfter(agreementStart)
  }

  get allCategories() {
    return this.agreementState.allCategories
  }

  get serviceArea() {
    return this.currency === 'DKK' ? 'CPH' : 'LON'
  }

  selectedCategory(revisionCategory: string) {
    if (this.allCategories.length) {
      let category = this.allCategories.filter((category) => category.slug === revisionCategory)
      return category[0] && category[0].title
    } else return revisionCategory
  }

  async loadCategories() {
    try {
      await this.agreementState.getCategories(this.serviceArea)
      this.getAgreementRevisionEdits(this.activeRevisionId)
    } catch (e) {
      this.errorLoading = true
    }
  }

  async getAgreementRevisionEdits(revisionId: string) {
    try {
      this.agreementRevisionEdits = await AgreementsApi.getAgreementRevisionEdits(this.agreement.id, revisionId)
    } catch (e) {
      this.errorLoading = true
    }
  }

  @Watch('activeTab')
  onRevisionChange(value: number): void {
    if (value < this.revisionLimit) {
      this.setActiveRevision(this.alteredRevisions[value])
    }
    this.getAgreementRevisionEdits(this.activeRevisionId)
  }

  bringActiveRevisionForward() {
    const revisions: IAgreementRevisionV2[] = JSON.parse(JSON.stringify(this.revisions))
    const revisionIndex = revisions.findIndex((r) => r.revisionId === this.activeRevisionId && this.revisionHasStarted)
    if (revisionIndex === -1) return this.revisions
    const removed = revisions.splice(revisionIndex, 1)
    return [...removed, ...revisions]
  }

  get alteredRevisions() {
    const revisions: IAgreementRevisionV2[] = JSON.parse(JSON.stringify(this.revisions))
    revisions.sort((a, b) => {
      return moment(b.startDate).valueOf() - moment(a.startDate).valueOf()
    })
    const revisionIndex = revisions.findIndex((r) => r.revisionId === this.activeRevisionId && this.revisionHasStarted)
    this.currentRevisionIndex = revisionIndex
    return revisions
  }

  edit(index: number) {
    const alteredRevisionId = this.alteredRevisions[index].revisionId
    const actualRevisionIndex = this.revisions.findIndex((r) => r.revisionId === alteredRevisionId)
    this.$emit('edit', actualRevisionIndex)
  }

  cancelEdit() {
    this.activeTab = 0
    this.revisionKey = uuid()
    this.$emit('closeNewRevisionTab')
  }

  shouldUpdate() {
    this.$emit('shouldUpdate')
  }

  async created() {
    await this.loadCategories()
  }
}
