














import { Component, Vue } from 'vue-property-decorator'
import { flags } from '../../../flags'
import SwitchInputDynamic from '@/shared_legacy/components/molecules/SwitchInputDynamic.vue'
import { getModule } from 'vuex-module-decorators'
import AppKey from '@/shared/storeModules/appKey'
import FeatureFlags from '@/shared/FeatureFlags'

const appState = getModule(AppKey)
@Component({
  components: { SwitchInputDynamic }
})
export default class FlagsPanel extends Vue {
  flags = flags
  flagsValues = flags
  switchModelValue = false

  created() {
    for (var flagName of Object.keys(flags)) {
      const enabled = FeatureFlags.isEnabled(flagName)

      if (enabled) {
        this.flagsValues[flagName] = true
      } else {
        this.flagsValues[flagName] = false
      }
    }
  }

  enableDisableFlag(enabled: boolean, flag: string) {
    if (this.flagsValues[flag] !== enabled) {
      this.flagsValues[flag] = enabled

      if (enabled) {
        FeatureFlags.enable(flag)
      } else {
        FeatureFlags.disable(flag)
      }

      this.refreshApp()
    }
  }

  refreshApp() {
    appState.updateKey()
  }
}
