import { ITicket } from '@/shared/components/interfaces/tickets.interface'
class ClientTicketHelper {
  public static ticketUniqueId(tasks: ITicket[], requestId: string) {
    let expires = requestId
    for (const task of tasks) {
		expires += task.assignee?.name ?? ''
      if (task.subjectId === requestId) {
        expires += task.expiresAt
      }
    }
    return expires
  }

  public static shouldAvoidNavigation(event: any) {
    let found = false
    const resultsEl = document.querySelectorAll('.ticket-container-column')
    resultsEl.forEach((el) => {
      const clickInsideResultsDiv = el.contains(event.target)
      if (clickInsideResultsDiv) {
        found = true
      }
    })
    return found
  }
}

// Export a singleton instance in the global namespace
export default ClientTicketHelper
