import moment from 'moment'

class AgreementHelper {
  defaultVatPercentageFromCurrency(currency: string): number {
    return currency === 'DKK' ? 25 : 20
  }

  generateMonths(dateRange: {agreementStart: string; agreementEnd: string|null}) {
	moment.locale('en')
	const dateStart = moment(dateRange.agreementStart)
	let dateEnd
	if (dateRange.agreementEnd) {
      dateEnd = moment(dateRange.agreementEnd)
	} else {
      dateEnd = moment().add(10, 'month')
	}
	const interim = dateStart.clone()
	const timeValues = []

	// eslint-disable-next-line no-unmodified-loop-condition
	while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
      timeValues.push(interim.format('YYYY-MM'))
      interim.add(1, 'month')
	}
	return timeValues
  }

  formatMonth(month: string) {
	const newDate = moment(`${month}-01`)
	return `${newDate.startOf('month').format('MMM D')} - ${newDate.endOf('month').format('MMM D, YYYY')}`
  }

  increaseMonth(months: string[], selectedMonth: string) {
	const numberOfMonths = months.length - 1
	const index = months.indexOf(selectedMonth)
	if (index < numberOfMonths) {
      selectedMonth = months[index + 1]
	}
	return selectedMonth
  }

  decreaseMonth(months: string[], selectedMonth: string) {
	const index = months.indexOf(selectedMonth)
	if (index > 0) {
      selectedMonth = months[index - 1]
	}
	return selectedMonth
  }

}

export default new AgreementHelper()
