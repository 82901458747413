





























































































































































import { Vue, Prop, Component } from 'vue-property-decorator'
import { RRule } from 'rrule'

import { IAgreementElementV2 } from '@/shared/components/interfaces/agreements.interface'
import DocumentInputGroup from './DocumentInputGroup.vue'
import LabelField from '@/shared_legacy/components/atoms/LabelField.vue'
import RadioInput from '@/shared_legacy/components/molecules/radios/RadioInput.vue'
//@ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'

@Component({ components: { DocumentInputGroup, LabelField, RadioInput, DatePicker } })
export default class DocumentFrequency extends Vue {
  @Prop({ required: true }) element: IAgreementElementV2
  @Prop({ default: true }) showLabel: string
  @Prop({ default: false }) enableChooseEnd: string
  @Prop({ default: false }) allowNotRepeat: string
  RRule = RRule
  weekDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
  fullWeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  intervalValue = '1'
  endDateHistory: Date | null = null
  datepickerConfig = {
    type: 'Date',
  }

  get endDate() {
    let recurrence = this.element.schedule
    if ((!recurrence || !recurrence.length) && !this.allowNotRepeat) {
      return ''
    }

    const rule = RRule.fromString(recurrence + '')
    if (rule.options.until) {
      this.endDateHistory = new Date(rule.options.until)
      return new Date(rule.options.until)
    } else {
      return this.endDateHistory ?? null
    }
  }

  set endDate(val) {
    const data = { until: val }
    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  get selectedEndOption() {
    let recurrence = this.element.schedule
    if ((!recurrence || !recurrence.length) && !this.allowNotRepeat) {
      return false
    }
    const rule = RRule.fromString(recurrence + '')
    return rule.options.until ? true : false
  }

  set selectedEndOption(val) {
    const until = this.endDate ? moment(this.endDate).toDate() : new Date()
    const data = { until: val ? until : null }
    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  get frequency() {
    let recurrence = this.element.schedule
    if ((!recurrence || !recurrence.length) && !this.allowNotRepeat) {
      return null
    }
    const rule = RRule.fromString(recurrence + '')
    return rule.options.freq
  }

  set frequency(val) {
    if (val === 0) {
      this.$emit('setPattern', { rule: 'DTSTART;', repeatPattern: '' })
    } else if (val === RRule.MONTHLY) {
      const data = {
        byweekday: [RRule.MO.nth(this.position)],
        freq: val,
      }
      this.$emit('updateElementRecurrence', data)
      this.$emit('setPattern', this.generateRule(data))
    } else {
      const data = {
        byweekday: [],
        freq: val,
      }
      this.$emit('updateElementRecurrence', data)
      this.$emit('setPattern', this.generateRule(data))
    }
  }

  get checkedDay() {
    let recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return null
    }
    const rule = RRule.fromString(recurrence)
    if (!rule.options?.bynweekday?.length) {
      return 0
    }
    return rule.options.bynweekday[0][0]
  }

  set checkedDay(val: number | null) {
    const data = {
      bymonthday: null,
      byweekday: [this.getWeekDayPattern(val!, this.position)],
    }
    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  get position() {
    let recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return 1
    }
    const rule = RRule.fromString(recurrence)
    if (rule.options?.bynmonthday.length) {
      return (rule.options.bynmonthday as unknown) as number
    }
    if (rule.options?.bynweekday?.[0]) {
      return rule.options.bynweekday[0][1]
    }
    return 1
  }

  set position(val: number) {
    let recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return
    }
    const rule = RRule.fromString(recurrence)
    let weekDay = RRule.MO.nth(val)
    if (rule.options?.bynweekday?.[0]) {
      weekDay = this.getWeekDayPattern(rule.options.bynweekday[0][0], val)
    }
    const data = {
      byweekday: [weekDay],
      bymonthday: null,
    }
    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  get activePattern() {
    const recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return null
    }
    const ruleText = RRule.fromString(recurrence as string).toText()
    return ruleText ? (ruleText[0]?.toUpperCase() ?? '') + ruleText.slice(1) : 'N/A'
  }

  get checkedDates() {
    let recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return null
    }
    const rule = RRule.fromString(recurrence)
    if (rule.origOptions.byweekday === undefined) {
      return []
    }
    return rule.options.byweekday
  }

  set checkedDates(val) {
    const data = {
      bymonthday: null,
      byweekday: val,
    }
    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  get interval() {
    let recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return null
    }
    const rule = RRule.fromString(recurrence)
    return rule.options.interval
  }

  set interval(val: number | null) {
    this.intervalValue = (this.$refs.interval as HTMLInputElement).value
    if (val! < 1) {
      val = 1
    }

    const data = {
      interval: val,
    }

    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  get dateStart() {
    let recurrence = this.element.schedule
    if (!recurrence || !recurrence.length) {
      return null
    }
    const rule = RRule.fromString(recurrence)
    return rule.options.dtstart
  }

  set dateStart(val: Date | null) {
    if (!val) {
      this.element.schedule = ''
      return
    }

    let data: { dtstart: Date; count?: number } = {
      dtstart: new Date(Date.UTC(val.getUTCFullYear(), val.getUTCMonth(), val.getUTCDate())),
    }

    this.$emit('updateElementRecurrence', data)
    this.$emit('setPattern', this.generateRule(data))
  }

  getWeekDayPattern(val: number, pos: number) {
    const weekDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
    let weekDay = (RRule as any)[weekDays[val]].nth(pos)
    return weekDay
  }

  generateRule(dataObj: any) {
    let ruleReset: any = {
      bymonthday: null,
      count: null,
      bymonth: null,
      wkst: null,
      byhour: null,
      byminute: null,
      bysecond: null,
    }

    let recurrence = this.element.schedule
    let ruleInitialReset = {}
    if (!recurrence || !recurrence.length) {
      recurrence = ''
      ruleInitialReset = {
        freq: RRule.WEEKLY,
        byweekday: [],
        interval: 1,
      }
    }

    let rule = RRule.fromString(recurrence)
    if (rule.origOptions.byweekday === undefined) {
      ruleReset = { ...ruleReset, byweekday: [] }
    }

    let fullDataObj = {
      ...rule.origOptions,
      ...ruleReset,
      ...ruleInitialReset,
      ...dataObj,
    }

    rule = new RRule(fullDataObj)

    const ruleString = rule.toString()
    return { rule: ruleString, repeatPattern: 'RRULE' + ruleString.split('RRULE')[1] }
  }
}
