
















import { IInvoiceItem } from '@/shared/components/interfaces/invoices.interface'
import NumberHelper from '@/shared/helpers/numberHelper'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
//@ts-ignore

@Component({ components: {} })
export default class InvoiceItem extends Vue {
  @Prop({ required: true }) item!: IInvoiceItem
  @Prop({ required: false, default: false }) dangerText: boolean

  get quantity() {
    return this.item.quantity
  }

  get price() {
    return NumberHelper.makeFloat(this.quantity * this.item.unitPrice)
  }

  formatAmount(currency: string, amount: string) {
    let formattedAmount = ''
    if (currency === 'GBP') {
      formattedAmount = `£ ${parseFloat(amount).toFixed(2)}`
    } else {
      formattedAmount = `${parseFloat(amount).toFixed(2)} DKK`
    }
    return formattedAmount
  }
}
